import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { DataStreamSurveyStepQuestion, useDataStreamCreateWithJson, DataStreamSurvey, useUpdateStudy, DataStream, useDataStreamUpdateSurvey, SurveyType } from '../../../api'
import { Typography, Space, List, Popover, Popconfirm, Tooltip, Upload, Button, Alert, App, Switch } from 'antd'
import { PageHeader } from "@ant-design/pro-components"
import { useParams } from "react-router-dom";
import { MinusCircleOutlined, InfoCircleOutlined, EditOutlined } from '@ant-design/icons'
import styles from './styles.module.css'
import { ColumnsType } from 'antd/lib/table';
import SortableTable, { DragHandle } from "../../DragDropTable"
import MarkdownPreview from '@uiw/react-markdown-preview';
import { SurveyPreview } from '../Preview';
import { AddQuestionForm } from "../../AddQuestionForm";
import { color } from '../../../theme';

const { Text } = Typography

export type EditQuestion = {
  editIndex: number,
  question: DataStreamSurveyStepQuestion
}

export type SurveyBuilderActions = 'create' | 'edit' | 'clone'

type SurveyBuilderProps = {
  setIsVisible: Dispatch<SetStateAction<boolean>>
  action?: SurveyBuilderActions
  surveyType?: SurveyType
  dataStream?: Pick<DataStream, 'id' | 'name' | 'meta'>
  surveyQuestions?: DataStreamSurveyStepQuestion[]
}

export const SurveyBuilder: React.FC<SurveyBuilderProps> = ({ surveyType = SurveyType.Session, dataStream = undefined, setIsVisible, action = "create", surveyQuestions }) => {
  const { organizationId, studyId } = useParams<{ organizationId: string, studyId: string }>();
  const [surveyName, setSurveyName] = useState<string>("New Survey");
  const [editQuestion, setEditQuestion] = useState<EditQuestion | undefined>();
  const { message: messageApi } = App.useApp();
  const [questionsRequired, setQuestionsRequired] = useState<boolean>(false);

  const [updateStudy, { isLoading: updateStudyLoading }] = useUpdateStudy({
    onError: (e) => {
      void messageApi.error(`Failed to save ${surveyType}: ${e?.message || ''}`)
    },
    onSuccess: () => {
      void messageApi.success(`Successfully updated ${surveyType} settings`)
      setIsVisible(false)
    }
  })

  const [uploadSurvey, { isLoading: uploadSurveyLoading }] = useDataStreamCreateWithJson({
    onError: () => {
      void messageApi.error('Failed to upload survey')
    },
    onSuccess: () => {
      void messageApi.success('Successfully uploaded survey')
      setIsVisible(false)
    }
  })

  const [updateSurvey] = useDataStreamUpdateSurvey({
    onError: () => {
      void messageApi.error('Failed to update survey')
    },
    onSuccess: () => {
      void messageApi.success('Successfully updated survey')
      setIsVisible(false)
    }
  })

  const [questions, setQuestions] = useState<DataStreamSurveyStepQuestion[]>([])

  const removeQuestion = (questionId: string) => {
    setQuestions(questions.filter(question => question.id !== questionId))
  }

  useEffect(() => {
    if (dataStream) {
      setSurveyName(dataStream.name)
      setQuestions(dataStream.meta?.steps?.length ? dataStream.meta.steps[0].questions : [])
      setQuestionsRequired(!!dataStream.meta?.questionsRequired)
    } else if (!dataStream && surveyType !== SurveyType.Session) {
      setQuestions(surveyQuestions ?? [])
      setSurveyName(`${surveyType[0].toUpperCase() + surveyType.substring(1)} Survey`)
      setQuestionsRequired(false)
    }
  }, [dataStream, surveyQuestions])

  const uploadFile = async (file: File) => {
    try {
      const text = await file.text();
      const obj: DataStreamSurvey = JSON.parse(text) as DataStreamSurvey;
      const newObject = { meta: obj };
      setQuestions(newObject.meta.steps[0].questions);
      setQuestionsRequired(!!newObject.meta.questionsRequired);
    } catch {
      void messageApi.warning('Error parsing JSON file. Please verify that your JSON format is valid.', 5);
    }
  }

  // Add enrollment and consent surveys
  const saveAndUploadStudySurveys = () => {
    const surveyKey = `${surveyType}Survey`;
    void updateStudy({
      organizationId,
      studyId,
      [surveyKey]: {
        steps: [
          {
            id: "0_step",
            questions
          }
        ]
      }
    })
  }

  // aggregate all and call upload
  const saveAndUploadSessionSurvey = ({ isPublished }: { isPublished?: boolean }) => {
    const json = JSON.stringify({
      name: surveyName,
      steps: [
        {
          id: "0_step",
          questions
        }
      ]
    })
    if (action === 'clone' || action === 'create') {
      void uploadSurvey({
        organizationId,
        studyId,
        json,
        is_hidden: !isPublished
      })
    } else if (dataStream) {
      void updateSurvey({
        organizationId,
        studyId,
        dataStream: {
          id: dataStream.id,
          name: surveyName,
          meta: {
            questionsRequired,
            steps: [
              {
                id: "0_step",
                questions
              }
            ]
          },
          is_hidden: !isPublished
        }
      })
    }
  }

  const columns: ColumnsType<DataStreamSurveyStepQuestion> = [
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      width: "10%",
      render: function type(type) {
        return (
          <div style={{ width: "100%" }}>
            <Space direction='horizontal'>
              <DragHandle />
              <Text>{type}</Text>
            </Space>
          </div>
        )
      }
    },
    {
      title: "Question",
      dataIndex: "title",
      key: "question",
      width: "50%",
      render: function description(description: string, row) {
        return (
          <div style={{ width: "100%" }}>
            <Text>{row.label}<MarkdownPreview source={description} /></Text>
          </div>
        )
      }
    },
    {
      title: "Answers",
      dataIndex: "answers",
      key: "answers",
      width: "20%",
      render: function details(_, row) {
        if (row.range) {
          return (
            <Popover placement='bottom' content={
              <List>
                <List.Item>
                  <Text>Minimum Label: {row.range.minimumLabel}</Text>
                </List.Item>
                <List.Item>
                  <Text>Minimum Value: {row.range.minimumValue}</Text>
                </List.Item>
                <List.Item>
                  <Text>Maximum Label: {row.range.maximumLabel}</Text>
                </List.Item>
                <List.Item>
                  <Text>Maximum Value: {row.range.maximumValue}</Text>
                </List.Item>
                <List.Item>
                  <Text>Step: {row.range.step}</Text>
                </List.Item>
                <List.Item>
                  <Text>Ticks: {(row.range.ticks || []).join(",")}</Text>
                </List.Item>
              </List>
            }>
              <div style={{ width: "100%" }}>
                <Text>
                  <InfoCircleOutlined style={{ marginRight: "5px" }} />Range Details
                </Text>
              </div>
            </Popover>
          )
        } else if (row.type.includes("MultipleChoice")) {
          return (
            <Popover placement='bottom' content={
              <List dataSource={row.choices}
                renderItem={choice => {
                  return (
                    <List.Item>
                      <Space direction='vertical'>
                        <Text>Label: {choice.label}</Text>
                        <Text>Value: {choice.value}</Text>
                        {row.ineligible_answers?.includes(choice.value) && <Alert type="error" showIcon message="Ineligible Answer" />}
                      </Space>
                    </List.Item>
                  )
                }} />
            }>
              <div style={{ width: "100%" }}>
                <Text>
                  <InfoCircleOutlined style={{ marginRight: "5px" }} />Choice Details
                </Text>
              </div>
            </Popover>
          )
        }
        return (
          <div style={{ width: "100%" }}></div>
        )
      }
    },
    {
      title: "Actions",
      dataIndex: "id",
      key: "id",
      width: "15%",
      align: "right",
      render: function actions(_, row, index) {
        return (
          <div style={{ width: "100%" }}>
            <Space style={{ gap: "20px" }}>
              <Popconfirm arrow={{ pointAtCenter: true }} title={() => <Text>Are you sure you want to edit this question? You will lose any unsubmitted data in the Add Question Form.</Text>}
                onConfirm={() => setEditQuestion({ question: row, editIndex: index })}>
                <EditOutlined style={{ color: color.blue }} />
              </Popconfirm>
              <Popconfirm arrow={{ pointAtCenter: true }} title={() => <Text>Are you sure you want to remove this question?</Text>}
                onConfirm={() => removeQuestion(row.id)}>
                <Tooltip title='Remove Question' placement="top">
                  <MinusCircleOutlined style={{ color: color.red }} />
                </Tooltip>
              </Popconfirm>
            </Space>
          </div>
        )
      }
    }
  ];

  return (
    <>
      <PageHeader
        breadcrumbRender={() => {
          return (
            <Space direction="horizontal" align='start' style={{ width: "100%", justifyContent: "space-between" }}>
              <Space direction='vertical'>
                <Text editable={surveyType === SurveyType.Session ? { onChange: setSurveyName, icon: <EditOutlined style={{ fontSize: "24px", color: color.blue }} /> } : false} style={{ fontWeight: 600, fontSize: "24px" }}>
                  {surveyName}
                </Text>
                {
                  (action === 'clone' && dataStream?.name === surveyName) && <Text>Please rename survey before saving/publishing.</Text>
                }
              </Space>
              <Space size={16}>
                <Button onClick={() => setIsVisible(false)} style={{ width: "111px" }}>Cancel</Button>
                {
                  questions.length > 0 && <SurveyPreview name={surveyName} survey={{
                    questionsRequired,
                    steps: [
                      {
                        id: "0_step",
                        questions
                      }
                    ]
                  }} />
                }
                <Upload accept=".json" showUploadList={false}
                  beforeUpload={file => {
                    if (file) {
                      void uploadFile(
                        file
                      )
                    }

                    return false
                  }}>
                  <Tooltip title="Upload JSON">
                    <Button>Upload JSON</Button>
                  </Tooltip>
                </Upload>
                {
                  surveyType !== SurveyType.Session && <Button type="primary" disabled={questions.length === 0 || updateStudyLoading} onClick={() => saveAndUploadStudySurveys()} style={{ textTransform: 'capitalize' }}>{`Save ${surveyType} Survey`}</Button>
                }
                {
                  surveyType === SurveyType.Session &&
                  <>
                    <Button style={{ width: "161px" }}
                      disabled={(action === 'clone' && dataStream?.name === surveyName) || questions.length === 0 || uploadSurveyLoading}
                      onClick={() => saveAndUploadSessionSurvey({ isPublished: false })}>Save as Draft</Button>
                    <Button type="primary" disabled={(action === 'clone' && dataStream?.name === surveyName) || questions.length === 0 || uploadSurveyLoading} style={{ width: "111px" }}
                      onClick={() => saveAndUploadSessionSurvey({ isPublished: true })}>Publish</Button>
                  </>
                }
              </Space>
            </Space>
          )
        }}
      >
        <Space direction="horizontal" size="large" align="start" className={styles.SurveyBuilderContent}>
          <Space direction="vertical">
            <AddQuestionForm surveyType={surveyType} questions={questions} setQuestions={setQuestions} editQuestion={editQuestion} setEditQuestion={setEditQuestion} />
            <Switch checked={questionsRequired} onChange={checked => setQuestionsRequired(checked)} checkedChildren="Questions Required" unCheckedChildren="Questions Optional" />
          </Space>
          <SortableTable data={questions} setData={setQuestions} columns={columns} getSortKey={i => i.id} rowKey="id" />
        </Space>
      </PageHeader>
    </>
  )
}