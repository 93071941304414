import React, { useEffect } from 'react'
import { organizations as useOrganizations } from '../../api'
import { Link, useHistory } from "react-router-dom";
import { Space, Typography, Alert } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import { Loader } from '../Loader'
import { generatePath } from 'react-router-dom'
import styles from './styles.module.css'
import { color } from '../../theme';

const { Title, Paragraph } = Typography;

const OrganizationsEmpty: React.FC = () => {
  return (
    <Space
      className={styles.OrganizationsEmpty}
      direction="vertical"
    >
      <Title level={4}>
        Contact an administrator from your organization to be added to a organization. 
      </Title>

      <Space>
        <SearchOutlined
          style={{
            fontSize: 64,
            color: color.gray,
          }}
        />
        <Space
          direction="vertical"
          style={{
            marginRight: '1.5rem',
            marginLeft: '1rem'
          }}
        >
          <Paragraph>
            Researchers must be added to a organization in order to add participants, create sessions, view and analyze datasets, and perform other actions.
          </Paragraph>

          <Paragraph>
            If you&apos;re still not seeing any organizations, use the Support Form below to contact Kernel Support.
          </Paragraph>
        </Space>
      </Space>

      <Paragraph>
        <Link to="/register">Received a Flow headset?</Link>
      </Paragraph>
    </Space>
  )
}

const OrganizationsTitle = () => <Title level={3} style={{ margin: 0 }}>Organizations</Title>

const OrganizationsLoader = () => <Loader className={styles.OrganizationsLoader} />

const ORGANIZATIONS_ERROR_TEXT = "Error Fetching Organizations"

const OrganizationsError = () => <Paragraph className={styles.OrganizationsError} style={{ color: color.red }}>{ORGANIZATIONS_ERROR_TEXT}</Paragraph>

// if the signed in researcher account is not part of any organization, we show them <EmptyOrganizations>. otherwise,
// redirect them to the first organization (detail--studies list) they are a part of
export const Organizations: React.FC = () => {
  const { isLoading: organizationsLoading, isError: organizationsError, data: organizations } = useOrganizations();

  const history = useHistory()

  // redirect
  useEffect(() => {
    if (organizations?.length ?? 0 > 0) {
      history.replace(generatePath('/organizations/:organizationId/studies', { organizationId: organizations?.[0]?.id || 0 }))
    }
  }, [organizations])

  return (
    <Space direction="vertical" style={{ width: '100%', marginTop: 24 }}>
      <Space style={{ justifyContent: 'space-between', width: '100%', marginBottom: 24 }}>
        <Space direction="vertical">
          <OrganizationsTitle />
          {organizationsError && <Alert type="error" showIcon message="Error loading organizations" />}
        </Space>
      </Space>

      {
        organizationsLoading ? <OrganizationsLoader /> :
        organizationsError ? <OrganizationsError /> :
        (organizations && organizations.length === 0) && <OrganizationsEmpty />
      }
    </Space>
  )
}

