import React from "react"
import { Chart } from "react-google-charts";
import { Space, Spin, Divider, Typography } from "antd";
import { useParams } from "react-router-dom";
import { StudySessionReportsChart, StudySessionReportsPoint, useStudySessionReports } from "../api";
import ParticipantReports from "./ParticipantReports";
import ParticipantFunnelReports from "./ParticipantFunnelReports";
import { useSelectedStudyContext } from "../contexts/SelectedStudy";

const { Title } = Typography

const StudyReports: React.FC = () => {
  const { organizationId, studyId } = useParams<{ organizationId: string, studyId: string }>();
  const { data, isLoading } = useStudySessionReports(organizationId, studyId);
  const foreground = '#ffffff';
  
  const { study } = useSelectedStudyContext()

  if (isLoading || !data) {
    return <Spin />
  }

  const charts: Array<{
    title: string,
    data: StudySessionReportsChart,
    formatLabel: (item: string) => string,
    formatValue: (point?: StudySessionReportsPoint) => number
  }> = [
    {
      title: "Session Count by Task",
      data: data.chart_experiment,
      formatLabel: item => item,
      formatValue: point => point?.count || 0,
    },
    {
      title: "Session Duration (min) by Task",
      data: data.chart_experiment,
      formatLabel: item => item,
      formatValue: point => Math.floor((point?.session_duration || 0) / 60),
    },
    {
      title: "Session Count by Operator",
      data: data.chart_user,
      formatLabel: item => item.split("@")[0],
      formatValue: point => point?.count || 0,
    },
    {
      title: "Session Duration (min) by Operator",
      data: data.chart_user,
      formatLabel: item => item.split("@")[0],
      formatValue: point => Math.floor((point?.session_duration || 0) / 60),
    },
  ]

  const chartData = charts.map(chart => [
    ["Week", ...chart.data.items.map(chart.formatLabel)],
    ...chart.data.weeks.map(week => [
      week,
      ...chart.data.items.map(item => 
        chart.formatValue(chart.data.data[week][item]) || 0
      )
    ])
  ]);

  return (
    <>
      <Space direction="vertical" style={{ width: '100%' }}>
      <Title style={{ marginTop: 0 }} level={3}>Study Reports</Title>
        {charts.map((chart, i) => (
          chartData[i][0].length === 1 ? <></> :
            <Chart
              key={i}
              chartType="ColumnChart"
              width="100%"
              height="400px"
              data={chartData[i]}
              options={{
                title: chart.title,
                titleTextStyle: {
                  color: foreground
                },
                legend: {
                  textStyle: {
                    color: foreground
                  },
                  pagingTextStyle: {
                    color: foreground
                  },
                  scrollArrows: {
                    activeColor: foreground
                  }
                },
                hAxis: {
                  textStyle: {
                    color: foreground
                  },
                  gridlineColor: foreground
                },
                vAxis: {
                  textStyle: {
                    color: foreground
                  },
                  gridlineColor: foreground
                },
                isStacked: true,
                backgroundColor: "#303037",
                colors: ["#80BCFF", "#007AFF", "#34C759", "#99E3AC", "#FF3B30",
                  "#FF9D97", "#FF9F0A", "#FFCF84", "#FFFFCC", "#006837"]
              }}
            />
        ))}
        {study?._permissions.can_view_participant_status && (
          <>
            <Divider />
            <Title level={3}>Participants Reports</Title>
            <ParticipantFunnelReports />
          </>
        )}
          <Divider />
          <Title level={3}>Survey Reports</Title>
          <ParticipantReports />
      </Space>
    </>
  )
}

export default StudyReports