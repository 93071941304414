import React, { useMemo, useState, useEffect } from 'react'
import { useParams, generatePath, useRouteMatch, Link } from 'react-router-dom'
import { Space, Typography, Tabs, Timeline, Table, Tag, Tooltip, Row, Col, App, Input, Select, List, Form, Button, Alert, Collapse, Progress, Empty, Card, Descriptions, Statistic } from 'antd'
import { PageHeader } from "@ant-design/pro-components"
import { CopyOutlined, CloudSyncOutlined, EyeOutlined, FieldTimeOutlined, HourglassOutlined, FunctionOutlined, StopOutlined, CheckCircleOutlined, CloudUploadOutlined, CloudDownloadOutlined, PlayCircleOutlined, FilterOutlined, IdcardOutlined } from '@ant-design/icons'
import moment from 'moment'
import { ColumnsType } from 'antd/lib/table'
import FileDownload from '../../components/FileDownload'
import { useSession, DataStream, useUpdateSession, EDITABLE_META_KEYS, SessionMeta, useExperiments, useSessionTransformPipeline, useSessionTransformPipelineStatus, usePipelineAssets, PipelineAssetsMimeType, TransformPipelineStatus, useParticipantResults, useUpdateParticipantResult, useSessionUploadStats, UploadStatsResponse, useParticipantPhoto } from '../../api'
import styles from './styles.module.css'
import Routes from '../../routes'
import { downloadFileUrl } from '../../utilities'
import TooltipButton from '../../components/TooltipButton'
import createPersistedState from 'use-persisted-state'
import { Loader, LoadingIndicator } from '../../components/Loader'
import Notes from '../../components/Notes'
import MarkdownPreview from '@uiw/react-markdown-preview';
import Chart from 'react-google-charts'
import { color, token } from '../../theme'
import { EnrollmentSurveyAnswersInner } from '../Participant'
import { useSelectedStudyContext } from '../../contexts/SelectedStudy'
import { DrawPhoto } from '../../components/Flow/CapturePhoto'

const { Text, Title, Paragraph } = Typography

const { Option } = Select

const { Panel } = Collapse

const SessionInvalidate = () => {
  const { organizationId, studyId, virtualUserId, sessionId } = useParams<{ organizationId: string, studyId: string, virtualUserId: string, sessionId: string }>();
  const { data: sessionResponse } = useSession(organizationId, studyId, virtualUserId, sessionId)
  const { study } = useSelectedStudyContext()
  const { message: messageApi } = App.useApp();

  const session = sessionResponse?.session;
  const [updateSession] = useUpdateSession({
    onError: () => {
      void messageApi.error('Failed to update session')
    },
    onSuccess: () => {
      void messageApi.success('Successfully updated session')
    },
  })

  const canInvalidateSession = !study?.is_complete;

  const [invalid, setInvalid] = useState<string>()

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <Title level={3} style={{ margin: 0 }}>Invalidate Dataset</Title>

      <Space direction="vertical">
        <Paragraph>
          <Paragraph>
            You can invalidate and revalidate a dataset using the button below. Before doing so, make sure you are aware of the <em>data
            consequences</em> of invalidating a dataset.
          </Paragraph>
        </Paragraph>

        <Paragraph>
          <List>
            <List.Item>
              <Space align="start">
                {!session?.meta?.invalid ? (
                  <>
                    <StopOutlined style={{ color: color.red }} />
                    <strong>Researchers will no longer see this dataset in <em>default</em> search results (this can be toggled when searching)</strong>
                  </>
                ) : (
                  <>
                    <CheckCircleOutlined style={{ color: color.green }} />
                    <strong>Researchers will see this dataset in search results</strong>
                  </>
                )}
              </Space>
            </List.Item>
            {(session && !session?.meta?.invalid) && (
              <>
                <List.Item>
                  <Space align="start">
                    <CheckCircleOutlined style={{ color: color.green }} />
                    <strong>Researchers will continue to be able to view, download, and analyze this dataset</strong>
                  </Space>
                </List.Item>
                <List.Item>
                  <Space align="start">
                    <CheckCircleOutlined style={{ color: color.green }} />
                    <strong>Researchers will continue to be able to edit this dataset metadata including un-invalidating the dataset</strong>
                  </Space>
                </List.Item>
              </>
            )}
          </List>
        </Paragraph>

        <Space>
          {(session && !session?.meta?.invalid) && (
            <Form.Item label="Reason Invalid" name="invalid" required={true} style={{ marginBottom: 0 }}>
              <Input defaultValue={session?.meta?.invalid} onChange={({ target }) => setInvalid(target.value)} />
            </Form.Item>
          )}

          {!session?.meta?.invalid ? (
            <TooltipButton
              {...!canInvalidateSession && { tooltipProps: { title: 'Cannot Invalidate Datasets from a Closed Study' }}}
              buttonProps={{
                disabled: !canInvalidateSession || !invalid,
                type: 'primary',
                danger: true,
                icon: <StopOutlined />,
                onClick: () => {
                  void updateSession({ organizationId, studyId, virtualUserId, sessionId, update: { invalid } })
                }
              }}
            >
              Invalidate Dataset
            </TooltipButton>
          ) : (
            <TooltipButton
              {...!canInvalidateSession && { tooltipProps: { title: 'Cannot Revalidate Datasets from a Closed Study' }}}
              buttonProps={{
                disabled: !canInvalidateSession,
                type: 'primary',
                icon: <CheckCircleOutlined />,
                onClick: () => {
                  void updateSession({ organizationId, studyId, virtualUserId, sessionId, update: { invalid: "" } })
                }
              }}
            >
              Revalidate Dataset
            </TooltipButton>
          )}
        </Space>
      </Space>
    </Space>
  )
}

const SessionActionRow: React.FC = () => {
  const { organizationId, studyId, virtualUserId, sessionId } = useParams<{ organizationId: string, studyId: string, virtualUserId: string, sessionId: string }>()
  const { data: sessionResponse } = useSession(organizationId, studyId, virtualUserId, sessionId)
  const session = sessionResponse?.session;
  const sessionDownloadUrl = `${process.env.REACT_APP_PORTAL_API_CONFIG_URL}${generatePath(Routes.DOWNLOAD_PARTICIPANT_SESSION_DATA, {
    organizationId,
    studyId,
    virtualUserId,
    sessionId
  })}`

  const canDownloadFile = session?.transformed;
  
  return (
    <Space>
      <Tooltip title={canDownloadFile ? "Download dataset and metadata" : "Dataset download not yet available"}>
        <Space>{/* required for Popover to work around SVG elements */}
          <Text strong>Download Dataset</Text>
          <FileDownload url={sessionDownloadUrl} disabled={!canDownloadFile} />
        </Space>
      </Tooltip>
      <Text type="secondary" title={sessionId} copyable={{ text: sessionId, tooltips: ['Copy Dataset ID', 'Copied'], icon: <CopyOutlined style={{ fontSize: 20 }} /> }} />
    </Space>
  )
}

const SessionTimelinePendingFinalized: React.FC<{ uploadStats: UploadStatsResponse }> = ({ uploadStats }) => {
  const filesQueued = uploadStats.files_queued || 0;
  return (
    <Space direction="vertical">
      <Paragraph>
        <strong>Files are still uploading to Kernel Cloud...</strong>
      </Paragraph>
      <Paragraph>
        Please do not turn off the acquisition system
      </Paragraph>
      {filesQueued > 0 && (
        <Progress steps={filesQueued} percent={Math.floor((uploadStats.files_uploaded || 0) / filesQueued * 100)} size="default" showInfo={false} />
      )}
      <Space direction="horizontal" size="large">
        {filesQueued > 0 && (
          <Card bordered={false}>
            <Statistic title="Files Uploaded" prefix={<CloudUploadOutlined style={{ color: token.colorPrimary }} />} value={uploadStats.files_uploaded || 0} suffix={`/ ${filesQueued}`} />
          </Card>
        )}
        {(uploadStats.last_uploaded_at || 0) > 0 && (
          <Card bordered={false}>
            <Statistic title="Last File Uploaded" prefix={<FieldTimeOutlined style={{ color: token.colorPrimary }} />} value={moment.unix(uploadStats.last_uploaded_at || 0).fromNow()} />
          </Card>
        )}
        {uploadStats.time_remaining_secs && (
          <Card bordered={false}>
            <Statistic title="Estimated Time Remaining" prefix={<HourglassOutlined style={{ color: token.colorPrimary }} />} value={moment().add(uploadStats.time_remaining_secs, 'seconds').fromNow(true)} />
          </Card>
        )}
      </Space>
    </Space>
  )
}

const SessionTimelineTransformError: React.FC = () => {
  return (
    <Space>
      <Paragraph>
        <strong>There was an error processing the files. We are looking into the issue!</strong>
      </Paragraph>
    </Space>
  )
}

const SessionTimelinePendingTransformed: React.FC = () => {
  return (
    <Space>
      <Paragraph>
        <strong>Files are still being processed...</strong>
      </Paragraph>
    </Space>
  )
}

const SessionTimelineTabPane: React.FC = () => {
  const { organizationId, studyId, virtualUserId, sessionId } = useParams<{ organizationId: string, studyId: string, virtualUserId: string, sessionId: string }>();
  const { data: sessionResponse, isLoading: sessionLoading } = useSession(organizationId, studyId, virtualUserId, sessionId)
  const { data: uploadStats } = useSessionUploadStats(!!sessionResponse?.session && !sessionResponse.session.finalized, organizationId, studyId, virtualUserId, sessionId);

  const session = sessionResponse?.session;
  const sessionTimelineTimeline: { title: string, datetime?: number }[] = useMemo(() => ([
    {
      title: 'Started Dataset',
      datetime: session?.created_date
    },
    ...session?.finalized_at ? [{
      title: 'Files Uploaded',
      datetime: session?.finalized_at
    }] : [],
    ...Object.entries(session?.transformed_data_streams || []).map(([dataStreamId, transformDatetime]) => ({
      title: `The data stream ${sessionResponse?.data_streams?.[dataStreamId]?.name ?? ''} is ready for analysis`,
      datetime: transformDatetime
    }))
  ]), [session])

  const pending = useMemo(() => {
    if (sessionLoading) {
      return <Loader />;
    }

    const sessionFinalized = session?.finalized
    const sessionTransformed = session?.transformed
    const sessionTransformError = !!session?.transform_error

    // finalization happens before transformation
    if (!sessionFinalized) {
      return <SessionTimelinePendingFinalized uploadStats={{ ...uploadStats }} />
    }

    if (!sessionTransformed) {
      return sessionTransformError ?
        <SessionTimelineTransformError /> :
        <SessionTimelinePendingTransformed />
    }

    return null
  }, [session, sessionLoading, uploadStats])

  return (
    <Timeline
      style={{ marginTop: 0 }}
      className={styles.SessionTimelineTabPane}
      reverse={true}
      {...pending && { pending }}
      items={sessionTimelineTimeline.sort((a, b) => (a?.datetime ?? 0) - (b?.datetime ?? 0)).map(({ title, datetime }) => (
        { color: token.colorSuccess, key: title, children: (
          <>
            <Paragraph>
              <strong>{title}</strong>
            </Paragraph>
            <Paragraph>
              <em>{moment.unix(datetime as number).format('LLL')}</em>
            </Paragraph>
          </>
        ) }  
      ))}
    />
  )
}

const SessionDataStreamFinalized: React.FC<Pick<SessionDataStreamWithFinalizedTransformed, 'finalized'>> = ({ finalized }) => {
  return (
    <Tooltip title={finalized ? 'Files Uploaded' : 'Files Not Uploaded'} placement="bottomRight">
      <div className={styles.SessionDataStreamFinalized} style={{ borderColor: finalized ? color.green : `${color.gray}40` }}>
        <CloudSyncOutlined style={{ color: finalized ? color.green : `${color.gray}40`, fontSize: 20 }} />
      </div>
    </Tooltip>
  )
}

const SessionDataStreamTransformed: React.FC<Pick<SessionDataStreamWithFinalizedTransformed, 'transformed'>> = ({ transformed }) => {
  return (
    <Tooltip title={transformed ? 'Ready for Analysis' : 'Not Ready for Analysis'} placement="bottomRight">
      <div  className={styles.SessionDataStreamTransformed} style={{ borderColor: transformed ? color.green : `${color.gray}40` }}>
        <FunctionOutlined style={{ color: transformed ? color.green : `${color.gray}40`, fontSize: 20 }} />
      </div>
    </Tooltip>
  )
}

type SessionDataStreamWithFinalizedTransformed = DataStream & {
  id: string
  finalized: boolean
  transformed: boolean
}

const SessionDataStreams: React.FC = () => {
  const { organizationId, studyId, virtualUserId, sessionId } = useParams<{ organizationId: string, studyId: string, virtualUserId: string, sessionId: string }>();
  const { data: sessionResponse, isLoading: sessionLoading, error: sessionError } = useSession(organizationId, studyId, virtualUserId, sessionId)

  const session = sessionResponse?.session;
  const sessionDataStreamsWithFinalizedTransformed: SessionDataStreamWithFinalizedTransformed[] = useMemo(() =>
  session?.data_streams?.map?.((dataStreamId) => ({
      ...sessionResponse?.data_streams?.[dataStreamId] as DataStream,
      id: dataStreamId,
      finalized: session?.data_streams_finalized.includes?.(dataStreamId) ?? false,
      transformed: dataStreamId in (session?.transformed_data_streams || [])
    })) ?? [] as SessionDataStreamWithFinalizedTransformed[]
  , [session])


  const sessionDataStreamsWithFinalizedTransformedColumns: ColumnsType<SessionDataStreamWithFinalizedTransformed> = [
    {
      title: 'Finalized',
      dataIndex: 'finalized',
      key: 'finalized',
      render: function Finalized(_, { finalized }) {
        return <SessionDataStreamFinalized finalized={finalized} />
      }
    },
    {
      title: 'Transformed',
      dataIndex: 'transformed',
      key: 'transformed',
      render: function Transformed(_, { transformed }) {
        return <SessionDataStreamTransformed transformed={transformed} />
      }
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: '100%',
      render: function Name(item, { name }) {
        return <Text><strong>{name}</strong></Text>
      }
    },
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      render: function ID(item, { id }) {
        return <Text copyable={{ text: id, tooltips: ['Copy Data Stream ID', 'Copied'] }} />
      }
    },
  ];

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <Space style={{ justifyContent: 'space-between', width: '100%', padding: '12px 0' }}>
        <Space direction="vertical">
          <Title level={3} style={{ margin: 0 }}>Data Streams</Title>
          {sessionError && <Alert type="error" showIcon message="Error loading data streams" />}
        </Space>
      </Space>
      <Table
        pagination={false}
        {...sessionLoading && {
          loading: {
            indicator: <LoadingIndicator />
          }
        }}
        dataSource={sessionDataStreamsWithFinalizedTransformed}
        columns={sessionDataStreamsWithFinalizedTransformedColumns}
        bordered={false}
        showHeader={false}
        size='small'
        className={styles.SessionDataStreamsTable}
        rowKey="id"
      />
    </Space>
  )
}

const SessionMetadataExperiment: React.FC<{
  onEdit: (editedValue: string) => void,
  currentValue?: string | undefined,
}> = ({
  currentValue,
  onEdit
}) => {
  const { organizationId, studyId } = useParams<{ organizationId: string, studyId: string }>();
  const [editing] = useState<boolean>(false);
  const { data: experiments, isLoading: experimentsLoading } = useExperiments(organizationId, studyId);

  return (
    <>
      {editing ? (
        <Text>{`${currentValue as string}`}</Text>
      ) : (
          <Select value={currentValue} disabled={experimentsLoading} onChange={onEdit} className={styles.SessionMetadataExperimentSelect} allowClear={true}>
            {experiments?.map?.(experiment => <Option key={experiment.name} value={experiment.name}>{experiment.display_name || experiment.name}</Option>)}
          </Select>
      )}
    </> 
  )
}

const SessionMetadata: React.FC = () => {
  const { organizationId, studyId, virtualUserId, sessionId } = useParams<{ organizationId: string, studyId: string, virtualUserId: string, sessionId: string }>();
  const { data: sessionResponse, error: sessionError } = useSession(organizationId, studyId, virtualUserId, sessionId)
  const { message: messageApi } = App.useApp();

  const session = sessionResponse?.session;

  const [updateSession] = useUpdateSession({
    onError: () => {
      void messageApi.error('Failed to update dataset')
    },
    onSuccess: () => {
      void messageApi.success('Successfully updated dataset')
    },
  })
  
  return (
    <Space direction="vertical" style={{ width: '100%'}}>
      
      <Space direction="vertical">
        <Title level={3} style={{ marginTop: 0 }}>Metadata</Title>
        {sessionError && <Alert type="error" showIcon message="Error loading info" />}
      </Space>

      <Descriptions 
        column={1} 
        size="small"
        labelStyle={{fontWeight:'bold'}} 
        bordered
      >
        {Object.entries(EDITABLE_META_KEYS).map(([key, label]) => {
          const currentValue = session?.meta?.[key as keyof SessionMeta]

          if (key === "experiment") {
            return (
              <Descriptions.Item key={key} label="Task">
                <SessionMetadataExperiment key={key} currentValue={currentValue} onEdit={editedValue => {
                    if (editedValue !== currentValue) {
                      void updateSession({
                        organizationId,
                        studyId,
                        virtualUserId,
                        sessionId,
                        update: {
                          // change to '' when clearing as { experiment: undefined } serializes to empty {}
                          'experiment': editedValue === undefined ? '' : editedValue
                        }
                      })
                    }
                }} />
              </Descriptions.Item>
            )
          }

          // default meta key/value + editor (<Text editable>)
          return (
              <Descriptions.Item key={key} label={label}><Text key={key} editable={{ onChange: editedValue => {
                  if (editedValue !== currentValue) {
                    void updateSession({
                      organizationId,
                      studyId,
                      virtualUserId,
                      sessionId,
                      update: {
                        [key]: editedValue
                      }
                    })
                  }
                }}}>{`${currentValue || ''}`}</Text>
              </Descriptions.Item>
          )
        })}
      
        <Descriptions.Item label="Researcher">{session?.created_by_email.split('@')[0]}</Descriptions.Item>
        {session?.created_by_clinic && (
          <Descriptions.Item label="Clinician Initials">{session.created_by_clinic}</Descriptions.Item>
        )}
        <Descriptions.Item label="Duration">{session?.duration} seconds</Descriptions.Item>
        <Descriptions.Item label="Start Time">{session && moment.unix(session?.created_date).format('LLL')}</Descriptions.Item>
      </Descriptions>
    </Space>
  )
}

const SessionInfoTabPane: React.FC = () => {
  return (
    <Space direction="vertical" size="large" style={{ maxWidth: '66%' }}>
      <SessionMetadata />
      <SessionDataStreams />
      <SessionInvalidate />
    </Space>
  )
}

const humanReadableFileSize: (size?: number) => React.ReactNode = (size?: number) => {
  if (!size) return <></>;

  const i = Math.floor(Math.log(size) / Math.log(1024));
  const stringSize = (Number((size / Math.pow(1024, i)).toFixed(2)) * 1).toString() + ['B', 'kB', 'MB', 'GB', 'TB'][i];
  return (<span>&nbsp;({stringSize})</span>);
}

const LOG_LEVELS: Record<string, Set<string>> = {
  processing: new Set(["info", "INFO"]),
  error: new Set(["error", "ERROR"]),
  warning: new Set(["warn", "WARN", "warning", "WARNING"]),
};

export const LOG_PROGRESS = new Set(["progress", "PROGRESS"]);

export const logLevelToTagColor = (level: string): string => {
  for (const [color, logLevels] of Object.entries(LOG_LEVELS)) {
    if (logLevels.has(level)) {
      return color;
    }
  }
  
  return "default";
};

export const logProgress: (logs: TransformPipelineStatus['logs']) => number | undefined = (logs) => {
  let progress: number | undefined = undefined;
  let time = 0;
  logs.forEach(log => {
    if (LOG_PROGRESS.has(log.level) && log.log_time > time) {
      time = log.log_time;
      progress = parseFloat(log.message);
      if (isNaN(progress)) {
        progress = undefined;
      } else {
        progress = Math.min(1, Math.max(0, progress));
      }
    }
  });
  return progress;
};

const SessionPipeline: React.FC<{ pipeline: string, name: string, status?: TransformPipelineStatus }> = ({ pipeline, name, status }) => {
  const { organizationId, studyId, sessionId } = useParams<{ organizationId: string, studyId: string, sessionId: string }>();
  const { message: messageApi } = App.useApp();
  const [sessionTransformPipeline] = useSessionTransformPipeline({
    onError: (error) => {
      if (error?.status === 409) {
        void messageApi.error(error.message)
      } else {
        void messageApi.error('Failed to run pipeline')
      }
    },
  })
  
  const [localDisabled, setLocalDisabled] = useState<boolean>(false);
  const { data: pipelineAsset, isLoading: pipelineAssetLoading, refetch: refetchPipelineAsset } = usePipelineAssets(organizationId, studyId, sessionId, pipeline)

  useEffect(() => {
    void (async () => {
      if (status?.last_status == "SUCCEEDED") {
        await refetchPipelineAsset();
      }
    })();
  }, [status?.last_status])

  return (
    <Space direction="vertical" style={{ width: '50%' }}>
      <Button type="primary" disabled={
        localDisabled ||
        !status ||
        status.running
      } onClick={async () => {
        setLocalDisabled(true);
        const res = await sessionTransformPipeline({ organizationId, studyId, sessionId, pipeline });
        setLocalDisabled(!res || !res.batch_job_ids); // can retry if failed, but if running leave disabled
      }} icon={<PlayCircleOutlined />}>
        Run&nbsp;<strong>{name}</strong>
      </Button>

      {!pipelineAssetLoading && pipelineAsset ? (
        pipelineAsset.mime_type == PipelineAssetsMimeType.MINDS_EYE ? (
          <>
            <Button
              key="minds_eye"
              href={pipelineAsset.urls["minds_eye"]}
              target="_blank"
              icon={<EyeOutlined style={{ color: color.blue }} />}
            >
              View Reconstruction in&nbsp;<strong>Minds Eye</strong>
            </Button>
            {pipelineAsset.urls["glm"] && (
              <Button
                key="glm"
                href={pipelineAsset.urls["glm"]}
                target="_blank"
                icon={<EyeOutlined style={{ color: color.blue }} />}
              >
                View GLM Reconstruction in&nbsp;<strong>Minds Eye</strong>
              </Button>
            )}
            {pipelineAsset.urls["nifti_hbo"] && (
              <Button
                key="nifti_hbo"
                onClick={() => void downloadFileUrl(pipelineAsset.urls["nifti_hbo"])}
                icon={<CloudDownloadOutlined style={{ color: color.blue }} />}
              >
                Download&nbsp;<strong>NIfTI: HbO</strong> {humanReadableFileSize(pipelineAsset.sizes?.["nifti_hbo"])}
              </Button>
            )}
            {pipelineAsset.urls["nifti_hbr"] && (
              <Button
                key="nifti_hbr"
                onClick={() => void downloadFileUrl(pipelineAsset.urls["nifti_hbr"])}
                icon={<CloudDownloadOutlined style={{ color: color.blue }} />}
              >
                Download&nbsp;<strong>NIfTI: HbR</strong> {humanReadableFileSize(pipelineAsset.sizes?.["nifti_hbr"])}
              </Button>
            )}
          </>
        ) : pipelineAsset.mime_type == PipelineAssetsMimeType.VIEW ? (
          /* view */
          Object.entries(pipelineAsset.urls).map(([file, url]) => (
            <Button
              key={file}
              href={url}
              target="_blank"
              icon={<EyeOutlined style={{ color: color.blue }} />}
            >
              View&nbsp;<strong>{file}</strong> {humanReadableFileSize(pipelineAsset.sizes?.[file])}
            </Button>
          ))
        ) : pipelineAsset.mime_type == PipelineAssetsMimeType.DOWNLOAD ? (
          /* download */
          Object.entries(pipelineAsset.urls).map(([file, url]) => (
            <Button
              key={file}
              onClick={() => void downloadFileUrl(url)}
              icon={<CloudDownloadOutlined style={{ color: color.blue }} />}
            >
              Download&nbsp;<strong>{file}</strong> {humanReadableFileSize(pipelineAsset.sizes?.[file])}
            </Button>
          ))
        ) : (
          <Alert message="Pipeline contains unknown assets!" type="warning" showIcon />
        )
      ) : pipelineAssetLoading ? <Loader /> : undefined}
      {status?.logs && (
        <List
          bordered
          dataSource={status.logs.filter(log => !LOG_PROGRESS.has(log.level))}
          renderItem={item => (
            <List.Item key={item.log_time}>
              <List.Item.Meta
                avatar={<Tag color={logLevelToTagColor(item.level)}>{item.level}</Tag>}
                title={moment.unix(item.log_time).format('MMM DD LT')}
                description={item.message}
              />
            </List.Item>
          )}
        />
      )}
    </Space>
  )
}

const SessionPipelines: React.FC = () => {
  const { organizationId, studyId, virtualUserId, sessionId } = useParams<{ organizationId: string, studyId: string, virtualUserId: string, sessionId: string }>();
  const { data: sessionResponse, isLoading: sessionLoading } = useSession(organizationId, studyId, virtualUserId, sessionId)
  const session = sessionResponse?.session
  const { data: transformPipelineStatus } = useSessionTransformPipelineStatus(organizationId, studyId, sessionId, (session?.pipelines_supported || []).map(pipeline => pipeline.job_name))

  if (sessionLoading) {
    return <Loader />
  }

  return (
    <Collapse>
      {session?.pipelines_supported.map(({ job_name: pipeline, job_display_name: name }) => (
        <Panel key={pipeline} header={(() => {
          const status = transformPipelineStatus?.[pipeline];
          return (
            <Row>
              <Col span={12}><Text strong>{name || pipeline}</Text></Col>
              <Col span={6}>
                {(() => {
                  if (status?.logs) {
                    const progress = logProgress(status.logs);
                    if (progress !== undefined) {
                      return <Progress percent={Math.round(progress * 100.0)} size="small" trailColor={color.blue} />;
                    }
                  }
                })()}
              </Col>
              <Col span={6} style={{ textAlign: "right" }}>
                {status?.last_status && status?.last_status_at && (
                  <Text>
                    {status.last_status}
                    &nbsp;at&nbsp;
                    {moment.unix(status.last_status_at).format('MMM DD LT')}
                  </Text>
                )}
              </Col>
            </Row>
          )
        })()}>
          <SessionPipeline
            key={pipeline}
            pipeline={pipeline}
            name={name}
            status={transformPipelineStatus?.[pipeline]}
          />
        </Panel>
      ))}
    </Collapse>
  )
}

const ParticipantResultsPreview: React.FC<{ source: string }> = ({ source }) => {
  {/* eslint-disable-next-line @typescript-eslint/no-unused-vars */}
  return <MarkdownPreview components={{ img: function img({node, ...props}) { 
    if (props.src?.startsWith("kernel://chart/")) {
      const [chartType, ...chartData] = props.src.replace("kernel://chart/", "").split("/")

      if (chartType === "gauge") {
        return <Chart
            chartType="Gauge"
            width="100%"
            height="400px"
            data={[
              ["Label", "Value"],
              ["Value", parseFloat(chartData[0]) * 100],
            ]}
        />
      }

      if (chartType === "bar") {
        return <Chart
          chartType="Bar"
          width="100%"
          height="400px"
          data={[["Time", "Value"], ...chartData.map((v, idx) => [idx, parseFloat(v)])]}
        />
      }

      return <div style={{ width: '100%', padding: '20px 20px', background: '#333', fontWeight: 'bold' }}>{chartType} chart unsupported</div>
    }

    return <img style={{width: '100%'}} {...props} />
  }}} source={source} />
}

const ParticipantResults: React.FC = () => {
  const { organizationId, studyId, virtualUserId, sessionId } = useParams<{ organizationId: string, studyId: string, virtualUserId: string, sessionId: string }>();
  const { isLoading: participantResultsLoading, isFetching: participantResultsFetching, data: participantResults } = useParticipantResults(organizationId, studyId, virtualUserId, sessionId);
  const { message: messageApi } = App.useApp();
  const [updateParticipantResult, { isLoading: updateParticipantResultLoading }] = useUpdateParticipantResult({
    onError: () => {
      void messageApi.error('Failed to deliver results to participant')
    },
    onSuccess: () => {
      void messageApi.success('Successfully delivered results to participant')
    },
  })

  if (participantResultsLoading) {
    return <Loader />
  }

  if (!participantResults || (!participantResults.available && !participantResults.current)) {
    return (
      <Space direction='vertical' size="large" style={{ width: '100%' }}>
        {participantResults?.available_errors && (
          <Alert type="warning" showIcon message="Results Not Available" description={participantResults.available_errors} />
        )}
        <Empty />
      </Space>
    )
  }

  return <Space direction='vertical' style={{ width: '100%' }}>
    {participantResults.available_errors && (
      <Alert type="warning" showIcon message="Results Not Available" description={participantResults.available_errors} />
    )}
    {participantResults.available && (
      <Card title="Available Results" extra={
        participantResults.available === participantResults.current ?
        (
          <TooltipButton buttonProps={{ type: "primary", disabled: true }} tooltipProps={{ title: "Already delivered results" }}>
            Deliver results to participant
          </TooltipButton>
        ) : (
          <Space direction="horizontal">
            <Button type="primary" disabled={participantResultsLoading || participantResultsFetching || updateParticipantResultLoading} onClick={() => {
              void updateParticipantResult({ organizationId, studyId, virtualUserId, sessionId })
            }}>
              Deliver results to participant
            </Button>
            {updateParticipantResultLoading && <Loader size="small" />}
          </Space>
        )
      }>
        <ParticipantResultsPreview source={participantResults.available} />
      </Card>
    )}
    {participantResults.current && (
      <Card title="Delivered Results">
        <ParticipantResultsPreview source={participantResults.current} />
      </Card>
    )}
  </Space>
}

const usePersistedSessionTabs = createPersistedState<string>('session-tabs')

const SessionTabs: React.FC = () => {
  const { organizationId, studyId, virtualUserId, sessionId } = useParams<{ organizationId: string, studyId: string, virtualUserId: string, sessionId: string }>();
  const [tab, setTab] = usePersistedSessionTabs()

  const { data: sessionResponse } = useSession(organizationId, studyId, virtualUserId, sessionId)

  return (
    <Tabs onChange={setTab} activeKey={tab} tabPosition="left" style={{ paddingTop: 10 }} size="large"
      items={[
        { key: "Timeline", label: "Timeline", children: <SessionTimelineTabPane /> },
        { key: "Info", label: "Info", children: <SessionInfoTabPane /> },
        ...(sessionResponse?._permissions.can_view_participant_photo ? [{ key: "Photo", label: "Photo", children: <SessionParticipantPhoto /> }] : []),
        { key: "Notes", label: "Notes", children: <Notes type="session" args={{ organizationId, studyId, virtualUserId, sessionId }} /> },
        { key: "Pipelines", label: "Pipelines", children: <SessionPipelines /> },
        ...(sessionResponse?._permissions.can_create_participant_results ? [{ key: "Participant Results", label: "Participant Results", children: <ParticipantResults /> }] : []),
        ...(sessionResponse?.survey && sessionResponse?.survey_answers ? [{ key: "Survey Response", label: "Survey Response", children: <EnrollmentSurveyAnswersInner title="Survey Response" survey={sessionResponse.survey} answers={sessionResponse.survey_answers} /> }] : []),
      ]}
    />
  )
}

const SessionParticipantPhoto: React.FC = () => {
  const { organizationId, studyId, virtualUserId, sessionId } = useParams<{ organizationId: string, studyId: string, virtualUserId: string, sessionId: string }>();
  const { data: participantPhotoResponse } = useParticipantPhoto(organizationId, studyId, virtualUserId, sessionId)
  return participantPhotoResponse ? (
    participantPhotoResponse?.url ?
    <DrawPhoto photo={participantPhotoResponse.url} landmarks={participantPhotoResponse.landmarks} /> :
    <Empty description="No Participant Photo Available" />
  ) : <Loader />
}

const SessionPageHeaderTitle: React.FC = () => {
  const match = useRouteMatch<{ organizationId?: string, studyId?: string }>('/organizations/:organizationId/studies/:studyId/datasets')

    const { organizationId, studyId, virtualUserId, sessionId } = useParams<{ organizationId: string, studyId: string, virtualUserId: string, sessionId: string }>();
    const { data: sessionResponse, isLoading: sessionLoading } = useSession(organizationId, studyId, virtualUserId, sessionId)

    const session = sessionResponse?.session;
    const sessionInvalid = session?.meta?.invalid;

    return (
    <Space direction="vertical" size="small">
      {sessionInvalid && <Alert type="error" showIcon icon={<StopOutlined />} message="Invalid Dataset" description={sessionInvalid} />}

      {
        sessionLoading ? <Loader /> :
        session && (
          <div>
            {session.meta?.name && <Title level={3} style={{ marginTop: 0 }}>{session.meta.name}</Title>}
            {session.meta?.description && <Title level={5} style={{ marginTop: 0 }}>{session.meta.description}</Title>}
            {typeof(session.duration) !== 'undefined' && session.duration > 0 && (
              <Tooltip title={moment.utc(session.duration * 1000).format("HH:mm:ss")}>
                <Text type="secondary">({moment.duration({ seconds: session.duration }).humanize()})</Text>
              </Tooltip>
            )}
          </div>
        )
      }

      <Descriptions column={2} bordered={true}>
        <Descriptions.Item label="Participant">
          <Space>
            {sessionResponse?.session.participant?.participant_id}
            {sessionResponse && (
              <>
                <Tooltip title="Filter Datasets">
                  <Link to={generatePath('/organizations/:organizationId/studies/:studyId/datasets/filter/participant/:virtualUserId', { organizationId: match?.params.organizationId || 0, studyId: match?.params.studyId || 0, virtualUserId: sessionResponse?.session.participant?.id })}>
                    <FilterOutlined style={{ color: color.blue }} />
                  </Link>
                </Tooltip>
                <Tooltip title="View Details">
                  <Link to={generatePath('/organizations/:organizationId/studies/:studyId/participants/:virtualUserId', { organizationId: match?.params.organizationId || 0, studyId: match?.params.studyId || 0, virtualUserId: sessionResponse?.session.participant?.id })}>
                    <IdcardOutlined style={{ color: color.blue }} />
                  </Link>
                </Tooltip>
              </>
            )}
          </Space>
        </Descriptions.Item>
        {sessionResponse?.session.created_by && (
          <Descriptions.Item label="Researcher">
              <Space>
                <Tooltip title={sessionResponse?.session.created_by_email}>
                  {sessionResponse?.session.created_by_email.split('@')[0]}
                </Tooltip>
                <Tooltip title="Filter Datasets">
                  <Link to={generatePath('/organizations/:organizationId/studies/:studyId/datasets/filter/created_by/:userId', { organizationId: match?.params.organizationId || 0, studyId: match?.params.studyId || 0, userId: sessionResponse.session.created_by })}>
                    <FilterOutlined style={{ color: color.blue }} />
                  </Link>
                </Tooltip>
              </Space>
          </Descriptions.Item>
        )}
      </Descriptions>
    </Space>
    )
}

const Session: React.FC = () => {
  return (
    <PageHeader
      title={<SessionPageHeaderTitle />}
      extra={[
        <SessionActionRow key="Session Action Row" />
      ]}
      style={{ paddingTop: 0 }}
    >
      <SessionTabs />
    </PageHeader>
  )
}

export default Session 
