import React, { useState } from 'react'
import { Typography, Space, Tag, List, Select, Spin, Collapse, Row, Col, Progress, Empty, Alert } from 'antd'
import { useParams } from "react-router-dom";
import { AnalysisPipelineStatuses, AnalysisPipelineStatusType, useAnalysisPipelineStatus } from 'src/api';
import { logLevelToTagColor, logProgress, LOG_PROGRESS } from 'src/pages/Session';
import moment from 'moment';
import { color } from '../theme';

const { Text, Title } = Typography
const { Panel } = Collapse


const AnalysisPipelineStatus: React.FC = () => {
  const { organizationId, studyId } = useParams<{ organizationId: string, studyId: string }>();

  const [status, setStatus] = useState<AnalysisPipelineStatusType>("my_running")
  const { isLoading: analysisPipelinesLoading, isError: analysisPipelinesError, data: analysisPipelines } = useAnalysisPipelineStatus(organizationId, studyId, status);

  return (
    <Space direction="vertical" size="large" style={{ width: '100%' }}>
      <Space style={{ justifyContent: 'space-between', width: '100%', padding: '12px 0' }}>
        <Space direction="vertical">
          <Space direction="horizontal" align='center' size="large">
            <Title level={3} style={{ margin: 0 }}>Analysis</Title>
            <Select value={status} onChange={v => setStatus(v)}>
              {Object.entries(AnalysisPipelineStatuses).map(([value, label]) => (
                <Select.Option key={value} value={value}>{label}</Select.Option>
              ))}
            </Select>
          </Space>
          {analysisPipelinesError && <Alert type="error" showIcon message="Error loading tasks!" />}
        </Space>
      </Space>
      
      {analysisPipelinesLoading || !analysisPipelines ? <Spin /> :
      Object.entries(analysisPipelines.jobs).length === 0 ? <Empty /> :
        <Collapse>
          {(() => {
            const components: React.ReactNode[] = [];
            Object.entries(analysisPipelines.jobs).forEach(([sessionId, executions]) => (
              Object.entries(executions).forEach(([executionId, status]) => (
                components.push(
                  <Panel key={executionId} header={(() => {
                    return (
                      <Row>
                        <Col span={12}><Text strong>{sessionId} / {executionId}</Text></Col>
                        <Col span={6}>
                          {(() => {
                            if (status?.logs) {
                              const progress = logProgress(status.logs);
                              if (progress !== undefined) {
                                return <Progress percent={Math.round(progress * 100.0)} size="small" trailColor={color.blue} />;
                              }
                            }
                          })()}
                        </Col>
                        <Col span={6} style={{ textAlign: "right" }}>
                          {status?.last_status && status?.last_status_at && (
                            <Text>
                              {status.last_status}
                              &nbsp;at&nbsp;
                              {moment.unix(status.last_status_at).format('MMM DD LT')}
                            </Text>
                          )}
                        </Col>
                      </Row>
                    )
                  })()}>
                    {status?.logs && (
                      <List
                        bordered
                        dataSource={status.logs.filter(log => !LOG_PROGRESS.has(log.level))}
                        renderItem={item => (
                          <List.Item key={item.log_time}>
                            <List.Item.Meta
                              avatar={<Tag color={logLevelToTagColor(item.level)}>{item.level}</Tag>}
                              title={moment.unix(item.log_time).format('MMM DD LT')}
                              description={item.message}
                            />
                          </List.Item>
                        )}
                      />
                    )}
                  </Panel>
                )
              ))
            ))

            return components
          })()}
        </Collapse>
      }
    </Space>
  )
}

export default AnalysisPipelineStatus;