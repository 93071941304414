import { InfoCircleOutlined, CheckCircleOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { Card, Col, Row, Space, Statistic, Tag } from "antd";
import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from 'uuid';
import { SdkClient } from "kernel-talk-web";
import { DEVICE_FLOW, FlowState } from "../../pages/Flow";
import { token } from "../../theme";
import { isEmptyValue } from "../../utilities";
import FlowLayout, { getColor, StatusValue, StatusValues } from "./FlowLayout";
import styles from '../../pages/Flow/styles.module.css';
import PlacementGood from "../../assets/placement-good.png";
import PlacementHigh from "../../assets/placement-high.png";
import PlacementTilt from "../../assets/placement-tilt.png";
import PlacementRotate from "../../assets/placement-rotate.png";

const EEG_CHANNELS = new Set([0, 1, 2, 3, 4, 5,6, 7])

const aggregateTextAndStyle = (value: number): { text: string, color?: string } => {
  if (value < 0.15) return { text: 'LOWEST SIGNAL', color: token.colorError }
  if (value < 0.40) return { text: 'LOW SIGNAL', color: token.colorWarning }
  if (value < 0.80) return { text: 'HIGH SIGNAL', color: token.colorInfo }
  return { text: 'HIGHEST SIGNAL', color: token.colorSuccess }
}

const RealTimeLayout: React.FC<{
  kernelTalk?: typeof import("kernel-talk-web")
  sdkClient?: SdkClient,
  flowState: FlowState,
  availableModules: Set<number>,
  lastSubscribeIds: React.MutableRefObject<Record<string, number | NodeJS.Timeout | null>>,
  subscribe: <T>(device_name: string, field_urns: string[], rate_us: number, pre_subscribe: boolean, callback: (data: T) => void, no_virtual?: boolean) => Promise<number | null>,
  tryCancelSubscribe: (name: string) => void,
  layout: React.ReactNode,
  onDataReceived: (seenAllModules: boolean) => void,
  onAggregate: (good: boolean) => void,
  highPriorityModules: Set<number>,
}> = ({ kernelTalk, sdkClient, flowState, availableModules, lastSubscribeIds, subscribe, tryCancelSubscribe, layout, onDataReceived, onAggregate, highPriorityModules }) => {
  const [moduleStatus, setModuleStatus] = useState<StatusValues>(new Map())
  const [eegStatus, setEegStatus] = useState<StatusValues>(new Map())
  const [aggregate, setAggregate] = useState<{ highPriority: number, all: number }>()
  const [highlightModules, setHighlightModules] = useState<Set<number>>(new Set());

  useEffect(() => {
    if (!kernelTalk || !sdkClient) return;

    const subscribeUrnPath = "flow/retained_channels";

    const undoCouplingLast = () => {
      const lastPluginTimer = lastSubscribeIds.current["coupling_last"]
      if (lastPluginTimer) {
        clearTimeout(lastPluginTimer as NodeJS.Timeout)
        lastSubscribeIds.current["coupling_last"] = null
      }
    }

    let unmounted = false
    const uniqueSubscribeId = uuidv4()
    const undo = () => {
      tryCancelSubscribe(`coupling_${uniqueSubscribeId}`)
      tryCancelSubscribe(`eeg_${uniqueSubscribeId}`)
      undoCouplingLast()
    }

    void (async () => {
      undo()

      lastSubscribeIds.current[`coupling_${uniqueSubscribeId}`] = await subscribe<Map<string, { data: () => number[] }>>(
        `urn:kernel.com/module/${subscribeUrnPath}`,
        [],
        0,
        true,
        (data) => {
          undoCouplingLast()

          const entry = data.get(`urn:kernel.com/field/${subscribeUrnPath}/retained_channels_percentage`)
          if (entry) {
            const values = entry.data()
            const newModuleValues = new Map<number, StatusValue>()
            let seenAllModules = true;
            const aggregateValues = { highPriority: 0, all: 0 }
            const aggregateCounts = { highPriority: 0, all: 0 }
            availableModules.forEach((availableModule) => {
              newModuleValues.set(availableModule, values[availableModule])
              if (isEmptyValue(values[availableModule]) || values[availableModule] === -1.0) {
                seenAllModules = false
                values[availableModule] = NaN;
              } else {
                aggregateValues.all += values[availableModule]
                aggregateCounts.all += 1
                if (highPriorityModules.has(availableModule)) {
                  aggregateValues.highPriority += values[availableModule]
                  aggregateCounts.highPriority += 1
                }
              }
            })
            if (aggregateCounts.all > 0) {
              const highPriority = aggregateCounts.highPriority === 0 ? 0 : aggregateValues.highPriority / aggregateCounts.highPriority
              setAggregate({
                highPriority,
                all: aggregateCounts.all === 0 ? 0 : aggregateValues.all / aggregateCounts.all,
              })
              onAggregate(highPriorityModules.size === 0 || highPriority >= 0.40); // good or better, if there are high priority modules
            }
            setModuleStatus(newModuleValues)
            onDataReceived(seenAllModules)
          }

          lastSubscribeIds.current["coupling_last"] = setTimeout(() => {
            const newModuleValues = new Map<number, StatusValue>()
            availableModules.forEach((availableModule) => {
              newModuleValues.set(availableModule, "missing")
            })
            setModuleStatus(newModuleValues)
            onDataReceived(false)
          }, 5000)
        },
        true,
      );

      lastSubscribeIds.current[`eeg_${uniqueSubscribeId}`] = await subscribe<Map<string, { data: () => number[] }>>(
        DEVICE_FLOW,
        [
          `urn:kernel.com/field/flow/eeg/impedance`,
        ],
        250000,
        true,
        (data) => {
          const values = data.get(`urn:kernel.com/field/flow/eeg/impedance`)
          if (!values) return
          const eegData = values.data()
          if (eegData.length < 8) return

          const newEegValues = new Map<number, StatusValue>()
          EEG_CHANNELS.forEach(eegChannel => {
            newEegValues.set(eegChannel, eegData[eegChannel])
          })
          setEegStatus(newEegValues)
        }
      );

      if (unmounted) undo()
    })()

    return () => {
      unmounted = true
      undo()
    }
  }, [kernelTalk, sdkClient, flowState, layout])

  const aggregateHighPriorityTextAndStyle = aggregate ? aggregateTextAndStyle(aggregate.highPriority) : aggregateTextAndStyle(0)
  const aggregateAllTextAndStyle = aggregate ? aggregateTextAndStyle(aggregate.all) : aggregateTextAndStyle(0)
  return (
    <Row>
      <Col span={4}>
        <Card title="Placement Guide" bordered={false} size="small">
          <div className={styles.placement}>
            <div>
              <img src={PlacementGood} alt="Centered &amp; Level" style={{ height: 200 }} />
              <Tag icon={<CheckCircleOutlined />} color="success">Centered &amp; Level</Tag>
            </div>
            <div>
            <img src={PlacementHigh} alt="Too High" style={{ height: 200 }} />
              <Tag icon={<ExclamationCircleOutlined />} color="warning">Too High</Tag>
            </div>
            <div>
            <img src={PlacementTilt} alt="Tilted" style={{ height: 200 }} />
              <Tag icon={<ExclamationCircleOutlined />} color="warning">Tilted</Tag>
            </div>
            <div>
              <img src={PlacementRotate} alt="Off-Center" style={{ height: 200 }} />
              <Tag icon={<ExclamationCircleOutlined />} color="warning">Off-Center</Tag>
            </div>
          </div>
        </Card>
      </Col>
      <Col span={17}>
        <FlowLayout
          layout={layout}
          moduleStatus={moduleStatus}
          eegStatus={eegStatus}
          highlightModules={highlightModules}
        />
      </Col>
      <Col span={3}>
        <Space direction="vertical" size="large" style={{ width: '100%', marginTop: 30 }}>
          { aggregate && (
            <>
              {highPriorityModules.size > 0 && (
              <Card size="small" bordered={false} title={<><InfoCircleOutlined style={{ color: token.colorInfo }} />&nbsp;High-Priority Modules</>} onMouseEnter={() => setHighlightModules(highPriorityModules)} onMouseLeave={() => setHighlightModules(new Set())}>
                <Statistic title={aggregateHighPriorityTextAndStyle.text} value={aggregate.highPriority * 100} precision={0} suffix="%" valueStyle={{ color: aggregateHighPriorityTextAndStyle.color }} />
              </Card>
              )}
              <Card size="small" bordered={false} title="All Modules">
                <Statistic title={aggregateAllTextAndStyle.text} value={aggregate.all * 100} precision={0} suffix="%" valueStyle={{ color: aggregateAllTextAndStyle.color }} />
              </Card>
            </>
          )}
          <Card size="small" bordered={false} title="Module Signal">
            <Space direction="vertical" className={styles.legend}>
              <Tag color={getColor(1.00)}>HIGHEST</Tag>
              <Tag color={getColor(0.75)}>&nbsp;</Tag>
              <Tag color={getColor(0.49)}>&nbsp;</Tag>
              <Tag color={getColor(0.25)}>&nbsp;</Tag>
              <Tag className="tagLowest" color={getColor(0.00)}>LOWEST</Tag>
              <Tag color={getColor(NaN)}>N/A</Tag>
            </Space>
          </Card>
        </Space>
      </Col>
    </Row>
  )
}

export default RealTimeLayout
