import { List, Popconfirm, Popover, Space, Typography, Card, Button, Alert, App } from 'antd'
import Title from 'antd/lib/typography/Title'
import React, { Dispatch, SetStateAction, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Checklist, useChecklists, useDeleteChecklist } from '../../api'
import { MoreOutlined } from '@ant-design/icons'
import { LoadingIndicator } from '../Loader'
import { ChecklistPreview } from '../ChecklistPreview'
import { useSelectedStudyContext } from '../../contexts/SelectedStudy'
import { ChecklistBuilder, ChecklistBuilderActions } from '../ChecklistBuilder'

const { Text } = Typography

const ChecklistActions: React.FC<{ checklist: Checklist, setBuildingChecklist: Dispatch<SetStateAction<boolean>>, setChecklist: Dispatch<SetStateAction<Checklist | undefined>>, setChecklistAction: Dispatch<SetStateAction<ChecklistBuilderActions>> }> = ({ checklist, setChecklist, setBuildingChecklist, setChecklistAction }) => {
  const { organizationId, studyId } = useParams<{ organizationId: string, studyId: string }>();
  const { study } = useSelectedStudyContext()
  const { message: messageApi } = App.useApp();

  const [deleteChecklist] = useDeleteChecklist(({
    onError: () => {
      void messageApi.error('Failed to remove checklist from study')
    },
    onSuccess: () => {
      void messageApi.success('Successfully removed checklist from study')
    }
  }))

  return (
    <Space style={{ width: "120px" }} direction='vertical'>
      {
        (study?._permissions.can_manage_checklist && checklist.can_edit) &&
        <Button type="text"
          onClick={() => {
            setChecklist(checklist)
            setChecklistAction('edit')
            setBuildingChecklist(true)
          }}>
          Edit
        </Button>
      }
      {
        study?._permissions.can_manage_checklist &&
        <Button type="text"
          onClick={() => {
            setChecklist(checklist)
            setChecklistAction('clone')
            setBuildingChecklist(true)
          }}>
          Clone and Edit
        </Button>
      }
      {
        (study?._permissions.can_manage_checklist && checklist?.can_delete) &&
        <Popconfirm title="Are you sure you want to remove this checklist?" onConfirm={() => void deleteChecklist({ organizationId, studyId, checklistId: checklist.id })}>
          <Button type="text" danger>Delete</Button>
        </Popconfirm>
      }
      <ChecklistPreview checklistId={checklist.id} />
    </Space>
  )
}

type ChecklistProps = {
  isBuildingChecklist: boolean
  setIsBuildingChecklist: Dispatch<SetStateAction<boolean>>
}

export const Checklists: React.FC<ChecklistProps> = ({ isBuildingChecklist, setIsBuildingChecklist }) => {
  const { organizationId, studyId } = useParams<{ organizationId: string, studyId: string }>();
  const { isLoading: checklistsLoading, isError: checklistsError, data: checklists } = useChecklists(organizationId, studyId);
  const [checklistBuilderAction, setChecklistBuilderAction] = useState<"create" | "clone" | "edit">("create")
  const [checklist, setChecklist] = useState<Checklist>()

  return (
    <>
      {
        isBuildingChecklist && <ChecklistBuilder setIsVisible={setIsBuildingChecklist} checklist={checklist} action={checklistBuilderAction} />
      }
      {
        !isBuildingChecklist &&
        <Space direction="vertical" style={{ width: '100%' }}>
          <Space style={{ justifyContent: 'space-between', width: '100%' }}>
            <Space direction="vertical">
              <Title level={3} style={{ margin: 0, color: 'rgba(255, 255, 255, 0.85)' }}>Checklists</Title>
              {checklistsError && <Alert type="error" showIcon message="Error loading checklists" />}
            </Space>
            <Button type="primary"
              onClick={() => {
                setChecklist(undefined)
                setChecklistBuilderAction('create')
                setIsBuildingChecklist(true)
              }}>
              Create Checklist
            </Button>
          </Space>

          <List
            dataSource={checklists}
            pagination={{ simple: true, pageSize: 9, hideOnSinglePage: true }}
            size="small"
            grid={{ gutter: 16, column: 3 }}
            renderItem={checklist => (
              <List.Item key={""} style={{ padding: 0, marginBottom: 16 }}>
                <Card style={{ height: "170px" }}
                  title={<Text>{checklist.name}</Text>}
                  extra={[
                    <Space key="checklist-actions" size="small">
                      <Popover placement='bottom' trigger="click" content={<ChecklistActions setBuildingChecklist={setIsBuildingChecklist} setChecklist={setChecklist} setChecklistAction={setChecklistBuilderAction} checklist={checklist} />}>
                        <MoreOutlined />
                      </Popover>
                    </Space>
                  ]}
                >
                  {checklist.steps?.length} Item(s)
                </Card>
              </List.Item>
            )}
            {...checklistsLoading && {
              loading: {
                indicator: <LoadingIndicator />
              }
            }}
          />
        </Space>
      }
    </>
  )
}