import React from "react"
import { Members } from '../../components/OrganizationResearchers'
import { DataStreams as OrganizationDataStreams } from '../../components/OrganizationRegisteredDevices';
import { Alert, Card, Space, Spin, Tabs, Typography } from "antd"
import { useSelectedOrganizationContext } from "../../contexts/SelectedOrganization";
import { useLocation } from "react-router-dom";
import { PageHeader } from "@ant-design/pro-components";
import { Organization } from "../../api";

const { Text, Title, Paragraph } = Typography

const AcquisitionDriver: React.FC<{ organization: Organization }> = ({ organization }) => (
    <Space direction="vertical" style={{ width: '100%' }}>
      <Space style={{ justifyContent: 'space-between', width: '100%', padding: '12px 0' }}>
        <Space direction="vertical">
          <Title level={5} style={{ margin: 0 }}>Acquisition Driver</Title>
        </Space>
      </Space>
      <Card>
        <Tabs items={[
                {
                    key: 'installation',
                    label: 'Installation',
                    children: (
                        <>
                            <Alert type="info" showIcon message="Supported Operating Systems" description={(
                                <>
                                    <ul>
                                        <li>Linux Ubuntu 20.04 (x86_64)</li>
                                        <li>Linux Ubuntu 22.04 (x86_64)</li>
                                        <li>macOS (amd64)</li>
                                        <li>macOS (arm64)</li>
                                    </ul>
                                    <Text italic>Windows coming soon</Text>
                                </>
                            )} />
                            <Alert type="warning" showIcon message="Do not share the code below with people outside your organization" />
                            <ul>
                                <li>Open a terminal</li>
                                <li>Enter root via <Text code copyable>sudo su -</Text></li>
                                <li>Run <Text code copyable>{`curl --proto '=https' --tlsv1.2 -sSf https://${window.location.hostname.replace("portal.", "software.")}/install/kortex/${organization.kortex_jwt_secret} | /bin/bash`}</Text></li>
                            </ul>
                        </>
                    )
                },
                {
                    key: 'restarting',
                    label: 'Restarting',
                    children: (
                        <Space direction="vertical" size="large">
                            <Alert type="warning" showIcon message="Only to be used in the case of issues; please contact support first." />
                            <Paragraph>
                                To restart the acquisition driver:
                                <ul>
                                    <li>On linux, run <Text code copyable>sudo systemctl restart kernel_kortex</Text></li>
                                    <li>On macOS, run <Text code copyable>sudo launchctl kickstart -k system/com.kernel.kortex</Text></li>
                                </ul>
                            </Paragraph>
                        </Space>
                    )
                }
            ]} />
        </Card>
    </Space>
)

const OrganizationSettings: React.FC = () => {
    const { organization } = useSelectedOrganizationContext()
    const { hash } = useLocation();

    if (!organization) {
        return <Spin />
    }

    return (
        <PageHeader
            style={{ paddingTop: 0 }}
            title={<Title level={3} style={{ marginTop: 24 }}>{organization.name}: Settings</Title>}
        >
            <Tabs tabPosition="left" size="large" defaultActiveKey={hash === "#acquisition_driver" ? "AcquisitionDriver" : undefined} items={[
                { key: "Researchers", label: "Researchers", children: <Members /> },
                { key: "OrganizationDataStreams", label: "Devices", children: <OrganizationDataStreams /> },
                { key: "AcquisitionDriver", label: "Acquisition Driver", children: <AcquisitionDriver organization={organization} /> },
            ]} />
        </PageHeader>
    )
}

export default OrganizationSettings