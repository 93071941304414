import { App } from 'antd';
import React from 'react';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useOAuth } from '../api';

const OAuth: React.FC = () => {
  const { message } = App.useApp();
  const [callOAuth] = useOAuth({
    onError: () => {
      void message.error('Failed to authenticate!')
    },
  })

  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const name = query.get("name") as string;
  const token = query.get("token") as string;

  useEffect(() => {
    void (async () => {
      if (token) {
        const res = await callOAuth({ token });
        if (res && res.redirect_to) {
          window.location.href = res.redirect_to;
        }
      }
    })();
  }, [token]);

  return <div>Redirecting to {name} ...</div>;
};

export default OAuth;