import { Spin } from "antd";
import React, { useContext } from "react"
import { useParams } from "react-router-dom";
import { useClinicFlowUI, useClinicUpdate } from "../../api";
import { ClinicAuthTokenContext } from ".";
import Flow from "../Flow";

const ClinicFlow: React.FC = () => {
    const clinicAuthTokenContext = useContext(ClinicAuthTokenContext);
    const { studyId } = useParams<{ studyId: string }>();
    const { data: flowUIMeta } = useClinicFlowUI(clinicAuthTokenContext.token, studyId);
    const [checkUpdate] = useClinicUpdate(studyId, clinicAuthTokenContext.token);

    return (
        <div style={{ padding: 20 }}>
            {flowUIMeta ? <Flow checkUpdate={checkUpdate} isClinic {...flowUIMeta} /> : <Spin />}
        </div>
    )
}

export default ClinicFlow;