import React, { useContext, PropsWithChildren, FC, createContext, useEffect } from 'react'
import { Organization, useOrganization, organizations as useOrganizations } from '../api'
import { Menu, ConfigProvider } from 'antd'
import { ApartmentOutlined, SettingOutlined, UserOutlined, ReadOutlined } from '@ant-design/icons'
import { generatePath, useRouteMatch, Link } from 'react-router-dom'
import { Loader } from '../components/Loader'

// the selected organization context type which can consumed with `useSelectedOrganizationContext`
// and provided via `<SelectedOrganizationContextProvider>`
type SelectedOrganizationContext = {
  organization?: Organization,
}

// the globally selected organization. only one can be selected at once. if
// there is only one organization available to the researcher, it is selected
const SelectedOrganizationContext = createContext<SelectedOrganizationContext>({})

// use this hook to access the global selected organization state in any component underneath
// the `<SelectedOrganizationContextProvider>`
export const useSelectedOrganizationContext: () => SelectedOrganizationContext = () => useContext(SelectedOrganizationContext)

// the selected organization provider manages the selected organization state by listening to routing
// changes (:organizationId) and fetching organization details appropriately
export const SelectedOrganizationContextProvider: FC<PropsWithChildren<unknown>> = ({ children }) => {
  const organizationRouteMatch = useRouteMatch<{ organizationId?: string }>('/organizations/:organizationId')
  const organizationRouteMatchOrganizationId = organizationRouteMatch?.params.organizationId
  const { data: organization } = useOrganization(organizationRouteMatchOrganizationId!, { enabled: !!organizationRouteMatchOrganizationId }) // eslint-disable-line

  useEffect(() => {
    if (organization?.name) {
      if (process.env.REACT_APP_GOOGLE_ANALYTICS === '1' && window.gtag) {
        window.gtag('set', 'user_properties', { organization: organization.name })
      }
    }
  }, [organization?.name])

  return (
    <SelectedOrganizationContext.Provider
      value={{
        organization,
      }}
    >
      {children}
    </SelectedOrganizationContext.Provider>
  )
}

// a dropdown component for setting the selected organization using context
export const OrganizationsDropdown: FC<unknown> = () => {
  const { data: organizations, isLoading } = useOrganizations()
  const { organization } = useSelectedOrganizationContext()

  if (isLoading) return <><Loader size="small" style={{ marginLeft: 5, marginRight: 5, fontSize: 12 }} /> Loading ...</>

  return (
    <ConfigProvider theme={{
      components: {
        Menu: {
          itemPaddingInline: 0,
        }
      }
    }}>
      <Menu
        mode='horizontal'
        disabledOverflow={true}
        selectedKeys={organization?.id ? [ organization.id ] : []}
        items={[
          {
            label: <ApartmentOutlined style={{ background: 'rgba(255,255,255,0.1)', padding: 10, borderRadius: 4 }} />,
            key: 'Organizations',
            children: [
              {
                key: 'Documentation',
                label: <a href="https://docs.kernel.com" target="_blank" rel="noreferrer"><ReadOutlined style={{ marginRight: 10 }} />Documentation</a>,
              },
              {
                key: 'Profile',
                label: <Link to="/profile"><UserOutlined style={{ marginRight: 10 }} />Profile</Link>,
              },
              ...((organizations && organizations.length > 0) ? [{
                key: 'Settings',
                label: <Link to={organization ? generatePath('/organizations/:organizationId', { organizationId: organization.id }) : '/organizations'}><SettingOutlined style={{ marginRight: 10 }} />Settings</Link>,
              }] : []),
              { type: 'divider' },
              ...organizations?.map(organization => ({
                key: organization.id,
                label: <Link to={generatePath('/organizations/:organizationId/studies', { organizationId: organization.id })}>{organization.name}</Link>,
              })) || [],
            ]
          }
        ]}
      />
    </ConfigProvider>
  )
}