import { Card, Space, Form, Button, Divider, Spin, Tag, Switch, Tooltip, App } from "antd";
import { useForm } from "antd/lib/form/Form";
import moment from "moment";
import React, { useState } from "react"
import { useSelectedStudyContext } from "../contexts/SelectedStudy";
import { NotesConfig, useAddNote, useNotes, useRemoveNote, useUpdateNote } from "../api";
import { EditOutlined, DeleteOutlined, SaveOutlined, UndoOutlined, InfoOutlined } from '@ant-design/icons'
import MDEditor from "@uiw/react-md-editor";
import MarkdownPreview from '@uiw/react-markdown-preview';
import { color } from "../theme";

type NotesProps<T extends keyof typeof NotesConfig> = {
  type: T,
  args: Parameters<(typeof NotesConfig)[T]["queryUrl"]>[0],
}

function Notes<T extends keyof typeof NotesConfig>(props: NotesProps<T>): JSX.Element {
  const { study } = useSelectedStudyContext()
  const { data: notes, isLoading: notesLoading } = useNotes(props.type, props.args)
  
  const [form] = useForm()
  const [note, setNote] = useState<string>()
  const [adminOnly, setAdminOnly] = useState<boolean>(false)
  const [shareWithOrg, setShareWithOrg] = useState<boolean | undefined>(undefined)

  const [editingNote, setEditingNote] = useState<string>("")
  const [editNote, setEditNote] = useState<string>("")
  const { message: messageApi } = App.useApp();

  const [addNote, {
    isLoading: addNoteLoading
  }] = useAddNote(props.type, {
    onError: () => {
      void messageApi.error('Failed to create note')
    },
    onSuccess: () => {
      void messageApi.success('Successfully created note')
      setNote("")
      setAdminOnly(false)
      setShareWithOrg(undefined)
    }
  })

  const [updateNote] = useUpdateNote(props.type, {
    onError: () => {
      void messageApi.error('Failed to update note')
    },
    onSuccess: () => {
      void messageApi.success('Successfully updated note')
      setEditingNote("")
      setEditNote("")
    }
  })

  const [removeNote] = useRemoveNote(props.type, {
    onError: () => {
      void messageApi.error('Failed to remove note')
    },
    onSuccess: () => {
      void messageApi.success('Successfully removed note')
    }
  })

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      {study?._permissions.can_add_notes && (
        <Card key="add" title="Add New Note">
          <Form form={form} onFinish={() => addNote({ ...props.args, note: note ?? '', adminOnly, shareWithOrg })}>
            <Form.Item label="Note">
               <MDEditor height={400} value={note} onChange={setNote} />
            </Form.Item>
            {study._permissions.can_add_admin_only_notes && (
              <Form.Item label={(
                <Space direction="horizontal">
                  <Tooltip title="Only other Study Admins will be able to see these notes">
                    <Button shape="circle" size="small" icon={<InfoOutlined />} />
                  </Tooltip>
                  Admin only?
                </Space>
              )}>
                <Switch checked={adminOnly} onChange={(val) => setAdminOnly(val)} />
              </Form.Item>
            )}
            {props.type === "participant" && (
              <Form.Item label={(
                <Space direction="horizontal">
                  <Tooltip title="Share notes for the user behind the participant across the organization. This is useful if a user is enrolled in multiple studies within your organization and you want to keep track of notes on them.">
                    <Button shape="circle" size="small" icon={<InfoOutlined />} />
                  </Tooltip>
                  Share with org?
                </Space>
              )}>
                <Switch checked={shareWithOrg} onChange={(val) => setShareWithOrg(val)} />
              </Form.Item>
            )}
            <Form.Item>
              <Button htmlType="submit" disabled={addNoteLoading || !note}>Add Note</Button>
            </Form.Item>
          </Form>
        </Card>
      )}
      <Divider />
      {notesLoading && <Spin />}
      {!notesLoading && notes && notes.map(note => (
        <Card
          key={note.id}
          title={`${moment.unix(note.created_at).format('MMM DD LT')} by ${note.created_by_email.split('@')[0]}`}
          extra={
            <Space direction="horizontal">
              {note.admin_only && <Tag color={color.gold}>Admin Only</Tag>}
              {note.share_with_org && <Tag color="processing">Shared with Org</Tag>}
              {note._permissions.can_edit && (
                editingNote === note.id ? (
                  <>
                    <Tooltip title="Save Note">
                      <SaveOutlined onClick={() => updateNote({ ...props.args, noteId: note.id, note: editNote })} />
                    </Tooltip>
                    <Tooltip title="Cancel">
                      <UndoOutlined onClick={() => { setEditingNote(""); setEditNote("") } } />
                    </Tooltip>
                  </>
                ) : (
                  <Tooltip title="Edit Note">
                    <EditOutlined style={{ color: color.blue }} onClick={() => { setEditingNote(note.id); setEditNote(note.note) }} />
                  </Tooltip>
                )
              )}
              {note._permissions.can_remove && (
                <Tooltip title="Remove Note">
                  <DeleteOutlined style={{ color: color.red }} onClick={() => removeNote({ ...props.args, noteId: note.id })} />
                </Tooltip>
              )}
            </Space>
          }
        >
          {editingNote === note.id ? (
            <Space direction='vertical' style={{width: '100%'}}>
              <MDEditor height={400} value={editNote} onChange={(note) => setEditNote(note ?? '')} />
            </Space>
          ) : (
            <MarkdownPreview source={note.note} />
          )}
        </Card>
      ))}
    </Space>
  )
}

export default Notes