import React from 'react';
import Brain_one from '../assets/brains_pngs/bitmap.png'
import { Typography, Space , Image} from 'antd'

const { Title } = Typography

const NotFound: React.FC = () => {
  return (
      <Space direction="vertical" size='large' align="center" style={{ width: '100%', marginTop: '1em'}}>
        <Space direction="vertical" align="center">
          <Title level={2}>Page Not Found</Title>
        </Space>
        <Image src={Brain_one} preview={false} style={{maxHeight: 300 }} />
        <Space direction="vertical" style={{maxWidth: 550}}>
            The page you are looking for can not be found. It may stem from an expired link, moved page, a changed name, or a typo. Please rethink your entry and try again.
        </Space>
      </Space>
  )
}

export default NotFound