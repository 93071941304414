import React, { useCallback, useEffect, useState } from "react"
import { ReactComponent as FlowHead } from '../../assets/flow-head.svg'
import { color, token } from "../../theme"
import styles from './flow.module.css'


const STATUS_TO_COLORS = {
  faulted: token.colorError as string,
  available: color.darkGreen as string,
  missing: token.colorBgBase as string,
} as const

const PERCENT_TO_COLORS_FLOW: Array<[number, string]> = [
  [0.00, '#F0F5FF'],
  [0.25, '#85a5ff'],
  [0.49, '#2f54eb'],
  [0.75, '#10239e'],
  [1.00, '#030852'],
]

const PERCENT_TO_COLORS_EEG: Array<[number, string]> = [
  [0.20, '#F0F5FF'],
  [0.40, '#85a5ff'],
  [0.60, '#2f54eb'],
  [0.89, '#10239e'],
  [1.00, '#030852'],
]

export const getColor = (status: StatusValue, mapping: Array<[number, string]> = PERCENT_TO_COLORS_FLOW): string => {
  if (typeof(status) === 'string') {
    return STATUS_TO_COLORS[status]
  }

  if (isNaN(status)) {
    return "#666"
  }
  for (const [percent, color] of mapping) {
    if (status <= percent) {
      return color
    }
  }
  return '#666'
}


export type StatusValue = keyof typeof STATUS_TO_COLORS | number
export type StatusValues = Map<number, StatusValue>

const FlowLayout: React.FC<{
  layout:  React.ReactNode,
  moduleStatus?: StatusValues,
  eegStatus?: StatusValues,
  highlightModules?: Set<number>,
}> = ({ layout, moduleStatus, eegStatus, highlightModules }) => {

  const [flowLayoutNode, setFlowLayoutNode] = useState<HTMLDivElement>()
  const flowLayoutInnerRef = useCallback((node: HTMLDivElement) => {
    if (node !== null) setFlowLayoutNode(node)
  }, []);

  useEffect(() => {
    if (!flowLayoutNode) return
    
    if (moduleStatus) {
      for (const key of moduleStatus.keys()) {
        const modules = flowLayoutNode.getElementsByClassName(`module-${key}`)
        if (modules.length > 0) {
          modules[0].setAttribute("fill", highlightModules?.has(key) ? color.darkGreen : getColor(moduleStatus.get(key) as StatusValue))
        }
      }
    }

    if (eegStatus) {
      for (const key of eegStatus.keys()) {
        const eegs = flowLayoutNode.getElementsByClassName(`eeg-${key}`)
        if (eegs.length > 0) eegs[0].setAttribute("fill", getColor(eegStatus.get(key) as StatusValue, PERCENT_TO_COLORS_EEG))
      }
    }

  }, [flowLayoutNode, moduleStatus, eegStatus, highlightModules])

  return (
    <div className={styles['flow-layout-container']}>
      <FlowHead className={styles['flow-layout-head']} />
      <div ref={flowLayoutInnerRef} className={`${styles['flow-layout-inner']} ${styles[`flow-layout-inner-danube`]}`}>
        {layout}
      </div>
      
    </div>
  )
}

export default FlowLayout