import React, { Dispatch, useCallback, useEffect, useState } from 'react'
import { Experiment, useExperiments, useAddExperiment, useRemoveExperiment, useUpdateExperiment } from '../../api'
import { Typography, Button, Form, Input, Space, Tooltip, Popconfirm, List, Modal, Card, Alert, Descriptions, App } from 'antd'
import { useParams } from "react-router-dom";
import { PlusCircleOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons'
import { useSelectedStudyContext } from '../../contexts/SelectedStudy'
import { Loader, LoadingIndicator } from '../Loader'
import CustomMDEditor from '../CustomMarkdownEditor';
import { color } from '../../theme';

const { Text, Title } = Typography

const AddExperiment: React.FC = () => {
  const { organizationId, studyId } = useParams<{ organizationId: string, studyId: string }>();
  const [form] = Form.useForm()
  const [canAddExperiment, setCanAddExperiment] = useState<boolean>(false)
  const { message: messageApi } = App.useApp();
  const [addExperiment, { isLoading: addExperimentLoading, error: addExperimentError }] = useAddExperiment({
    onError: () => {
      void messageApi.error('Failed to add task')
    },
    onSuccess: () => {
      void messageApi.success('Successfully added task')
      form.resetFields()
      setCanAddExperiment(false)
    }
  })

  const experimentFormFinishHandler = useCallback(async ({ experiment }: Record<string, string>) => {
    await addExperiment({
      organizationId,
      studyId,
      experiment
    })
  }, [addExperiment, organizationId, studyId, form])

  return (
    <>
      <Form
        name="add-experiment"
        layout={'inline'}
        form={form}
        onFinish={experimentFormFinishHandler}
        size="small"
      >
        <Form.Item label="Add Task" name="experiment" required={true}>
          <Input onChange={({ target }) => setCanAddExperiment(!!target.value) }/>
        </Form.Item>
        <Form.Item style={{ marginRight: 0, width: 'unset' }}>
          <Space>
            {
              addExperimentLoading ? <Loader size="small" /> :
              <Tooltip title={canAddExperiment ? 'Add Task' : 'Enter Task Name'}>
                <Button style={{ border: 'none', background: 'none', padding: '0', width: 'unset' }} htmlType="submit" disabled={!canAddExperiment} icon={<PlusCircleOutlined twoToneColor={`${color.blue}${!canAddExperiment ? '40' : ''}`} />} />
              </Tooltip>
            }
            {addExperimentError && <Alert type="error" showIcon message="Error adding task" />}
          </Space>
        </Form.Item>
      </Form>
    </>
  )
}

const RemoveExperiment: React.FC<{ experiment: Experiment }> = ({ experiment }) => {
  const { organizationId, studyId } = useParams<{ organizationId: string, studyId: string }>();
  const { message: messageApi } = App.useApp();
  const [removeExperiment, { isLoading: removeExperimentLoading }] = useRemoveExperiment({
    onError: () => {
      void messageApi.error('Failed to remove task')
    },
    onSuccess: () => {
      void messageApi.success('Successfully removed task')
    }
  })

  const { study } = useSelectedStudyContext()

  return (
    <>
      <Popconfirm
        title={() => <Text>Are you sure you want to remove <strong>{experiment.name}</strong> from <strong>{study?.name}</strong>?</Text>}
        onConfirm={() => void removeExperiment({ organizationId, studyId, experimentId: experiment.id })}
        arrow={{ pointAtCenter: true }}
      >
        <Tooltip title='Remove Task' placement="left">
          {removeExperimentLoading ? <Loader size="small" /> : <DeleteOutlined style={{ color: color.red }} />}
        </Tooltip>
      </Popconfirm>
    </>
  )
}

const EditTaskModal: React.FC<{experiment?: Experiment, setExperiment: Dispatch<React.SetStateAction<Experiment | undefined>>, canManageParticipantResultsTemplate: boolean }> = ({ experiment, setExperiment, canManageParticipantResultsTemplate }) => {
  const [editExperimentForm] = Form.useForm()
  const { organizationId, studyId } = useParams<{ organizationId: string, studyId: string }>();
  const [participantResultsTemplate, setParticipantResultsTemplate] = useState<string>("")
  const [editExperimentModal, setEditExperimentModal] = useState<boolean>(false)
  const { message: messageApi } = App.useApp();
  const [updateExperiment] = useUpdateExperiment({
    onError: () => {
      void messageApi.error('Failed to update task')
    },
    onSuccess: () => {
      void messageApi.success('Successfully updated task')
      setEditExperimentModal(false)
      editExperimentForm.resetFields()
      setExperiment(undefined)
      setParticipantResultsTemplate("")
    }
  })

  const handleSubmit = () => {
    void updateExperiment({
      organizationId, 
      studyId, 
      experimentId: (experiment?.id ?? "") , 
      participant_results_template: participantResultsTemplate, 
      name: editExperimentForm.getFieldValue('name') as string,
      displayName: editExperimentForm.getFieldValue('displayName') as string,
    })
  }

  useEffect(() => {
    if (experiment) {
      setParticipantResultsTemplate(experiment.participant_results_template !== null ? experiment.participant_results_template : "")
      editExperimentForm.setFieldsValue({
        name: experiment.name,
        displayName: experiment.display_name,
      })
      setEditExperimentModal(true)
    }
  }, [experiment])

  return (
    <Modal title="Edit Task" open={editExperimentModal} width={800}
      onCancel={() => {
        setEditExperimentModal(false)
        setExperiment(undefined)
      }}
      footer={[
        <Button key='submit' htmlType='submit' onClick={() => handleSubmit()}>
          Submit
        </Button>
      ]}
    >
      <Form form={editExperimentForm}>
        <Form.Item style={{width: "400px"}} label="Name" name="name">
          <Input />
        </Form.Item>
        <Form.Item style={{width: "400px"}} label="Display Name" name="displayName">
          <Input />
        </Form.Item>
        {canManageParticipantResultsTemplate && (
          <Form.Item label="Participant Results Template" style={{display: 'block', marginBottom: '12px'}}>
            <CustomMDEditor height={400} value={participantResultsTemplate} onChange={setParticipantResultsTemplate} autofillCommands="reports" />
          </Form.Item>
        )}
      </Form>
    </Modal>
  )
}

export const Tasks: React.FC = () => {
  const { organizationId, studyId } = useParams<{ organizationId: string, studyId: string }>();
  const { isLoading: experimentsLoading, isError: experimentsError, data: experiments } = useExperiments(organizationId, studyId);
  const [editExperiment, setEditExperiment] = useState<Experiment>();
  const { study } = useSelectedStudyContext()

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <Space style={{ justifyContent: 'space-between', width: '100%', padding: '12px 0' }}>
        <Space direction="vertical">
          <Title level={3} style={{ margin: 0 }}>Tasks</Title>
          {experimentsError && <Alert type="error" showIcon message="Error loading tasks!" />}
        </Space>
        {study?._permissions.can_manage_experiments && <AddExperiment />}
      </Space>
      <EditTaskModal experiment={editExperiment} setExperiment={setEditExperiment} canManageParticipantResultsTemplate={!!study?._permissions.can_manage_participant_results_template} />
      <List
        dataSource={experiments}
        pagination={{ simple: true, pageSize: 9, hideOnSinglePage: true }}
        size="small"
        grid={{ gutter: 16, column: 3 }}
        renderItem={experiment => (
          <List.Item key={experiment.id} style={{ padding: 0, marginBottom: 16 }}>
            <Card
              title={<Text copyable>{experiment.name}</Text>}
              extra={study?._permissions.can_manage_experiments && (
                <Space key="experiment-actions" size="small">
                  <EditOutlined style={{ color: color.blue }} onClick={() => setEditExperiment(experiment)} />
                  <RemoveExperiment experiment={experiment} />
                </Space>
              )}
            >
              <Descriptions>
                <Descriptions.Item label="Display Name">{experiment.display_name || 'N/A'}</Descriptions.Item>
              </Descriptions>
            </Card>
          </List.Item>
        )}
        {...experimentsLoading && {
          loading: {
            indicator: <LoadingIndicator />
          }
        }}
      />
    </Space>
  )
}

