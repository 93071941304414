import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  AverageRatingData,
  RatingForm,
  RatingHistory,
  useRatingHistory,
  useAverageRatingHistory,
  useParticipantRate,
  ParticipantRatingLabel
} from '../../api'
import {
  App,
  Button,
  Col,
  Form,
  Input,
  Modal,
  Rate,
  Row,
  Space,
  Table,
  Tooltip,
  Typography,
} from 'antd'
import { ColumnsType } from 'antd/lib/table'
import { InfoOutlined } from '@ant-design/icons'
import { LoadingIndicator } from '../Loader'
import moment from 'moment'

const {
  Title,
  Text,
} = Typography

const { TextArea } = Input

export const Ratings: React.FC = () => {
  const { organizationId, studyId, virtualUserId } = useParams<{ organizationId: string, studyId: string, virtualUserId: string }>();
  const { data: ratings, isLoading: ratingsLoading } = useRatingHistory(organizationId, studyId, virtualUserId);
  const { data: averageRatings, isLoading: averageRatingsLoading } = useAverageRatingHistory(organizationId, studyId, virtualUserId);
  const [ ratingsFormModalOpen, setRatingsFormModalOpen ] = useState<boolean>(false)
  const [ ratingsForm ] = Form.useForm();
  const { message: messageApi } = App.useApp();

  const resetForm = () => {
    ratingsForm.resetFields();
    setRatingsFormModalOpen(false);
  }

  const [ rateParticipant, { isLoading } ] = useParticipantRate({
    onError: () => {
      void messageApi.error('Failed to submit rating');
    },
    onSuccess: () => {
      resetForm();
      void messageApi.success('Rating submitted')
    }
  })

  const validateFormAndRateParticipant = async () => {
      const { Completes, CooperationNotes, Moves, EaseNotes, OnTime, ReliabilityNotes } = await ratingsForm.validateFields() as RatingForm;
      
      const ratingBody: RatingHistory = [ 
        { label: 'participant_moves', score: Moves*2, note:  CooperationNotes ? CooperationNotes : '' }, 
        { label: 'participant_completes', score: Completes*2, note: EaseNotes ? EaseNotes : '' }, 
        { label: 'participant_on_time', score: OnTime*2, note: ReliabilityNotes ? ReliabilityNotes : '' }
      ]; 
      
      void rateParticipant({
        organizationId,
        studyId,
        virtualUserId,
        rate: ratingBody
      })
  }

  const tooltipEase = [
    'Setup and signal took very long',
    'Took longer than expected for setup ',
    'Setup was OK',
    'Usually easy to setup and high signal',
    'Very quick setup and highest signal'
  ];
  const tooltipCooperation = [
    'Never followed instructions well', 
    'Some significant problems following instructions',
    'Followed instructions somewhat',
    'Few problems following instructions',
    'Excellent at following instructions',
  ];
  const tooltipReliability = [
    'Never responds or shows up on time', 
    'Some issues keeping appointments and reponding', 
    'OK in responding and coming on time', 
    'Usually few issues in responding or showing up on time', 
    'Always responds, does best to keep appointments, and shows up on time',
  ];

  const titlesByLabel: Record<ParticipantRatingLabel, string> = {
    participant_moves: 'Ease', 
    participant_completes: 'Cooperation',
    participant_on_time: 'Reliability',
  }

  const columnsRating: ColumnsType<RatingHistory> = [
    {
      title:
            <Space direction='horizontal'>
              Category
              <Tooltip 
                title={
                  <Space direction='vertical'>
                    <Text>
                      <strong>Ease:</strong> Setup time and high signal. 
                    </Text>
                    <Text>
                      <strong>Cooperation:</strong> How well does the participant follow instructions.
                    </Text>
                    <Text>
                      <strong>Reliability:</strong> Shows up on time, keeps their appointments, responsiveness
                    </Text>
                 </Space>
                }
              ><Button shape="circle" size="small" icon={<InfoOutlined />} /></Tooltip>
            </Space>,
      dataIndex: 'label',
      key: 'title',
      width: '100px',
      render: function Title(label: ParticipantRatingLabel) {
        return <Text>{titlesByLabel[label]}</Text>
      }
    },
    {
      title: 'Score',
      dataIndex: 'score',
      key: 'score',
      width: '100px',
      render: function Cooperation(score) {
        return <Text> {score} / 10</Text>
      }
    },
    {
      title: 'Notes',
      dataIndex: 'note',
      key: 'note',
      render: function Reliability(note) {
        return <Text>{note}</Text>
      }
    },
    {
      title: 'Date',
      dataIndex: 'created_at',
      width: '200px',
      render: function Date(created_at) {
        return <Text>{moment.unix(created_at).format('YYYY MMM DD LT')}</Text>
      }
    }
  ]

  const columnsAverageRating: ColumnsType<AverageRatingData> = [
    {
      title: 'Ease',
      dataIndex: 'participant_moves',
      key: 'participant_moves',
      width: '100px',
      render: function Ease(score) {
        return <Text className='ParticipantPaymentColumnStyle'>{score} / 10</Text>
      }
    },
    {
      title: 'Cooperation',
      dataIndex: 'participant_completes',
      key: 'participant_completes',
      width: '100px',
      render: function Cooperation(score) {
        return <Text className='ParticipantPaymentColumnStyle'>{score} / 10</Text>
      }
    },
    {
      title: 'Reliability',
      dataIndex: 'participant_on_time',
      key: 'participant_on_time',
      width: '100px',
      render: function Reliability(score) {
        return <Text className='ParticipantPaymentColumnStyle'>{score} / 10</Text>
      }
    },
  ]

  return (
    <>
      <Space direction="vertical" size="large" style={{ width: '100%' }}>
        <Title level={3} style={{ marginTop: 0 }}>Average Rating</Title>
        <Table
          pagination={{ simple: true, pageSize: 10, hideOnSinglePage: true }}
          {...(averageRatingsLoading) && {
            loading: {
              indicator: <LoadingIndicator />
            }
          }}
          dataSource={averageRatings && [ averageRatings ]}
          columns={columnsAverageRating}
        />
        <Space direction="horizontal" align='center' size="large">
          <Title level={3} style={{ margin: 0 }}>Ratings History</Title>
          <Button type="primary" loading={isLoading} onClick={() => setRatingsFormModalOpen(true)}>Rate participant</Button>
        </Space>
        <Table
          pagination={{ simple: true, pageSize: 10, hideOnSinglePage: true }}
          {...(ratingsLoading) && {
            loading: {
              indicator: <LoadingIndicator />
            }
          }}
          dataSource={ratings}
          columns={columnsRating}
        />
      </Space>
      <Modal 
        title="Rate Participant"
        open={ratingsFormModalOpen} 
        maskClosable={false}
        onCancel={() => setRatingsFormModalOpen(false)}
        width={1200}
        footer={[
          <Button onClick={validateFormAndRateParticipant} type="primary" key='submit' htmlType='submit'>
            Submit Rating
          </Button>
        ]}
      >
        <Form
          name="ratings"
          form={ratingsForm}
        > 
        <Space direction='vertical' style={{width: '100%'}}>
          <Row>
            <Col span={8}>
              <Form.Item label="Ease of setup" name='Moves' rules={[{required: true, message: "Rating required"}]} >
                <Rate allowHalf tooltips={tooltipEase} allowClear={false} />
              </Form.Item>
            </Col>
            <Col span={16}>
              <Form.Item label="Ease of setup notes" name='EaseNotes' >
                <TextArea rows={3} />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={8}>
              <Form.Item label="Cooperation" name='Completes' rules={[{required: true, message: "Rating required"}]}>
                <Rate allowHalf tooltips={tooltipCooperation} allowClear={false} />
              </Form.Item>
            </Col>
            <Col span={16}>
              <Form.Item label="Cooperation notes" name='CooperationNotes' >
                <TextArea rows={3} />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={8}>
              <Form.Item label="Reliability" name='OnTime' rules={[{required: true, message: "Rating required"}]}>
                <Rate allowHalf tooltips={tooltipReliability} allowClear={false} />
              </Form.Item>
            </Col>
            <Col span={16}>
              <Form.Item label="Reliability notes" name='ReliabilityNotes' >
                <TextArea rows={3} />
              </Form.Item>
            </Col>
          </Row>
        </Space>
        </Form>
      </Modal>
    </>
  )
} 