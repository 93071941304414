
export enum SurveyStepEvent {
    // the last time the user edited the answer for a given question
    SurveyQuestionAnswered = 'survey_question_answered'
}

// <eventId, isoString>
export type SurveyStepEventsRecord = Record<SurveyStepEvent, string>;

export type SurveyStepFormAnswerValue = string | number | null;
export type SurveyStepFormAnswer = {
    value: SurveyStepFormAnswerValue,
    events: SurveyStepEventsRecord
    prefersNotToAnswer?: boolean
}

// <questionId, answer>
export type SurveyStepForm = Record<string, SurveyStepFormAnswer>;