import React, { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react'
import { DataStreamSurveyStepQuestion, DataStreamSurveyStepQuestionType, DataStreamSurveyStepQuestionChoice, DataStreamSurveyStepQuestionRange, isMultipleQuestionType, SurveyType } from '../../api'
import { Typography, Form, Input, Space, Row, Col, Select, InputNumber, Checkbox, Tag, Button, Tooltip } from 'antd'
import { PlusCircleOutlined, MinusCircleOutlined, EditOutlined } from '@ant-design/icons'
import styles from './styles.module.css'
import { ColumnsType } from 'antd/lib/table';
import SortableTable, { DragHandle } from "../DragDropTable"
import { EditQuestion } from "../Survey/Builder/index"
import { color, token } from '../../theme'

const { Text } = Typography
const validateMessages = {
  required: "'${label}' is required"
}

type EditChoice = {
  editIndex: number,
  choice: DataStreamSurveyStepQuestionChoice
}

export const AddQuestionForm: React.FC<{ questions: DataStreamSurveyStepQuestion[], setQuestions: Dispatch<SetStateAction<DataStreamSurveyStepQuestion[]>>, editQuestion: EditQuestion | undefined, setEditQuestion: Dispatch<SetStateAction<EditQuestion | undefined>>, surveyType: SurveyType }> = ({ questions, setQuestions, editQuestion, setEditQuestion, surveyType }) => {
  const [questionForm] = Form.useForm()
  const [questionChoicesForm] = Form.useForm()
  const [questionRangeForm] = Form.useForm()
  const [questionType, setQuestionType] = useState<DataStreamSurveyStepQuestionType>();
  const [questionChoices, setQuestionChoices] = useState<DataStreamSurveyStepQuestionChoice[]>([])
  const [questionRange, setQuestionRange] = useState<DataStreamSurveyStepQuestionRange>()
  const [editChoice, setEditChoice] = useState<EditChoice | undefined>()
  const [ineligibleAnswers, setIneligibleAnswers] = useState<string[]>([])
  const [isIneligibleAnswer, setIsIneligibleAnswer] = useState<boolean>(false)

  const updateQuestionRange = (update: Record<string, string | number | number[]>) => {
    setQuestionRange(range => ({
      ...range as Partial<DataStreamSurveyStepQuestionRange>,
      ...update as Partial<DataStreamSurveyStepQuestionRange>
    } as DataStreamSurveyStepQuestionRange))
  }

  const addQuestionChoice = useCallback((choice: Record<string, string>) => {
    if (isIneligibleAnswer && !ineligibleAnswers.includes(choice.value)) setIneligibleAnswers([...ineligibleAnswers, choice.value])
    if (ineligibleAnswers.includes(choice.value) && !isIneligibleAnswer) setIneligibleAnswers(ineligibleAnswers.filter(answer => answer !== choice.value))
    if (!editChoice) {
      setQuestionChoices(choices => ([...choices, choice as DataStreamSurveyStepQuestionChoice]))
    }
    else {
      const editedChoices = [...questionChoices];
      editedChoices[editChoice.editIndex] = {
        label: choice.label,
        value: choice.value
      } as DataStreamSurveyStepQuestionChoice
      setQuestionChoices(editedChoices)
    }
    questionChoicesForm.resetFields()
    setEditChoice(undefined);
    setIsIneligibleAnswer(false)
  }, [setQuestionChoices, questionChoicesForm, editChoice, isIneligibleAnswer, ineligibleAnswers, setIsIneligibleAnswer])

  const removeChoice = (value: DataStreamSurveyStepQuestionChoice) => {
    setQuestionChoices(questionChoices.filter(choice => choice !== value))
  }

  const clearQuestion = () => {
    questionForm.resetFields()
    setQuestionType(undefined)

    questionChoicesForm.resetFields()
    setQuestionChoices([])

    questionRangeForm.resetFields()
    setQuestionRange(undefined)
  }

  const addQuestion = useCallback(async (question: Record<string, string>) => {
    // validate `Range`
    if (question.type === DataStreamSurveyStepQuestionType.Range) {
      await questionRangeForm.validateFields()
    }

    // validate `MultipleChoice`
    if (isMultipleQuestionType(question.type)) {
      if (questionChoices.length < 2) {
        questionChoicesForm.setFields([{
          name: 'add-question-choice-submit',
          errors: ['Set at least 2 choices'],
        }])
        await questionChoicesForm.validateFields();
        return Promise.reject("MultipleChoice question configuration requires at least 2 choices")
      }
    }
    if (editQuestion === undefined) {
      setQuestions(questions => ([...questions, {
        ...question,
        ...(isMultipleQuestionType(question.type)) && {
          choices: questionChoices,
          ineligible_answers: ineligibleAnswers.length > 0 ? ineligibleAnswers : undefined
        },
        ...question.type === DataStreamSurveyStepQuestionType.Range && {
          range: questionRange
        }
      } as DataStreamSurveyStepQuestion]))
    } else {
      const editedQuestions = [...questions];
      editedQuestions[editQuestion.editIndex] = {
        ...question,
        ...(isMultipleQuestionType(question.type)) && {
          choices: questionChoices,
          ineligible_answers: ineligibleAnswers.length > 0 ? ineligibleAnswers : undefined
        },
        ...question.type === DataStreamSurveyStepQuestionType.Range && {
          range: questionRange
        }
      } as DataStreamSurveyStepQuestion
      setIneligibleAnswers([])
      setQuestions(editedQuestions)
      setEditQuestion(undefined)
    }

    clearQuestion()
    setQuestionType(undefined)
  }, [setQuestions, questionChoices, questionRange, questionRangeForm, questionChoicesForm, editQuestion])

  useEffect(() => {
    if (editQuestion) {
      setQuestionType(editQuestion.question.type);
      questionForm.setFieldsValue({
        type: editQuestion.question.type,
        label: editQuestion.question.label,
        id: editQuestion.question.id,
        title: editQuestion.question.title,
      });

      if (editQuestion.question.range) {
        setQuestionRange(editQuestion.question.range)
        questionRangeForm.setFieldsValue({
          minimumLabel: editQuestion.question.range.minimumLabel,
          minimumValue: editQuestion.question.range.minimumValue,
          maximumLabel: editQuestion.question.range.maximumLabel,
          maximumValue: editQuestion.question.range.maximumValue,
          step: editQuestion.question.range.step,
          ticks: editQuestion.question.range.ticks,
        })
      } else {
        setQuestionRange(undefined)
      }

      if (editQuestion.question.choices) {
        setQuestionChoices(editQuestion.question.choices)

        if (editQuestion.question.ineligible_answers) {
          setIneligibleAnswers(editQuestion.question.ineligible_answers)
        }
      } else {
        setQuestionChoices([])
      }
    }
  }, [editQuestion])

  const columns: ColumnsType<DataStreamSurveyStepQuestionChoice> = [
    {
      title: "",
      key: "choiceInfo",
      width: "50%",
      render: function label(_, row) {
        return (
          <Space direction='horizontal' style={{width: "100%"}}>
              <DragHandle />
              <Space direction='vertical' style={{marginLeft: "5px"}}>
              { ineligibleAnswers.includes(row.value) && <Tag color={token.colorError}>Ineligible Answer</Tag> }
                <Text>{row.label}</Text>
                <Text>{row.value} </Text>
              </Space>
          </Space>
        )
      }
    },
    {
      title: "",
      key: "choiceActions",
      width: "50%",
      align: "right",
      render: function value(_, row, index) {
        return (
          <Space style={{width: "100%", display: "flex", justifyContent:'flex-end'}} direction='horizontal'>
            <EditOutlined style={{ color: color.blue }} onClick={() => { 
              questionChoicesForm.setFieldsValue({
                label: row.label,
                value: row.value
              })
              setEditChoice({editIndex: index, choice: row})
              setIsIneligibleAnswer(ineligibleAnswers.includes(row.value))
            }} />
            <MinusCircleOutlined style={{ color: color.red }} onClick={() => removeChoice(row)}/>
          </Space>
        )
      }
    }
  ];

  return (
    <Form
      className={styles.SurveyBuilder}             
      name="add-question"
      form={questionForm}
      validateMessages={validateMessages} 
      onFinish={addQuestion}
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
    >
          <Form.Item label="Type" colon={false} name="type" rules={[{ required: true}]} className={`${styles.FormItem} w-full`}>
            <Select onSelect={type => setQuestionType(type)}
              onChange={(value: DataStreamSurveyStepQuestionType) => {
                  if (!value.includes("Multiple")) {
                    setQuestionChoices([])
                    questionChoicesForm.resetFields()
                  }
                  if (value !== DataStreamSurveyStepQuestionType.Range) {
                    setQuestionRange(undefined)
                    questionRangeForm.resetFields()
                  }
              }}>
              <Select.Option value={DataStreamSurveyStepQuestionType.Instruction}>Instruction</Select.Option>
              <Select.Option value={DataStreamSurveyStepQuestionType.Input}>Input</Select.Option>
              <Select.Option value={DataStreamSurveyStepQuestionType.Number}>Number</Select.Option>
              <Select.Option value={DataStreamSurveyStepQuestionType.Range}>Range</Select.Option>
              <Select.Option value={DataStreamSurveyStepQuestionType.MultipleChoice}>Multiple Choice</Select.Option>
              <Select.Option value={DataStreamSurveyStepQuestionType.MultipleChoiceRadio}>Multiple Choice Radio</Select.Option>
              <Select.Option value={DataStreamSurveyStepQuestionType.MultipleSelect}>Multiple Select</Select.Option>
              <Select.Option value={DataStreamSurveyStepQuestionType.Time}>Time</Select.Option>
              <Select.Option value={DataStreamSurveyStepQuestionType.Day}>Day</Select.Option>
              <Select.Option value={DataStreamSurveyStepQuestionType.Month}>Month</Select.Option>
              <Select.Option value={DataStreamSurveyStepQuestionType.Year}>Year</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item label="Label" colon={false} name="label" className={`${styles.FormItem} w-full`} rules={[{ required: true}]}>
            <Input />
          </Form.Item>
          <Form.Item label="ID" colon={false} name="id" className={`${styles.FormItem} w-full`} 
          rules={[
            { required: true}, 
            { message: "Question ID must be unique.",
              validator: ( _, value) => {
                if (!editQuestion && questions.filter(question => question.id === value).length === 0) return Promise.resolve()
                else if (editQuestion && questions.filter((question, index) => question.id === value && index !== editQuestion.editIndex).length === 0) return Promise.resolve()
                return Promise.reject()
              }
            }]}>
            <Input />
          </Form.Item>
          <Form.Item label="Question" colon={false} name="title" className={`${styles.FormItem} w-full`} rules={[{ required: true}]}>
            <Input.TextArea style={{height: "148px"}} />
          </Form.Item>
          {
            questionType === DataStreamSurveyStepQuestionType.Range && (
            <Form
              name="add-question-range"
              form={questionRangeForm}
              validateMessages={validateMessages}
              onFieldsChange={changed => {
                if (changed.length > 0) {
                  const update = changed[0]! // eslint-disable-line
                  const name = update.name.toString() // eslint-disable-line
                  let value = update.value as string | number | number[]

                  if (name === "ticks" && typeof(value) === "string") {
                    value = value.replace(/\s/g, "").split(",").map(n => parseInt(n, 10)).filter(n => !isNaN(n));
                  }

                  updateQuestionRange({
                    [name]: value
                  })
                }
              }}
            >
              <Space direction='vertical' style={{width: "100%"}}>
                <Form.Item label="Minimum Label" colon={false} name="minimumLabel" className={`${styles.FormItem} w-full`} rules={[{ required: true}]}>
                  <InputNumber placeholder='1' />
                </Form.Item>
                <Form.Item label="Minimum Value" colon={false} name="minimumValue" className={`${styles.FormItem} w-full`}  rules={[{ required: true}]}>
                  <InputNumber placeholder='1' />
                </Form.Item>
                <Form.Item label="Maximum Label" colon={false} name="maximumLabel" className={`${styles.FormItem} w-full`} rules={[{ required: true}]}>
                  <InputNumber placeholder='10' />
                </Form.Item>
                <Form.Item label="Maximum Value" colon={false} name="maximumValue" className={`${styles.FormItem} w-full`} rules={[{ required: true}]}>
                  <InputNumber placeholder='10' />
                </Form.Item>
                <Form.Item label="Step" colon={false} name="step" className={`${styles.FormItem} w-full`} rules={[{ required: true}]}>
                  <InputNumber placeholder='1' />
                </Form.Item>
                <Tooltip title="Comma separated list of ticks between the min and max value. Example: 25,50,75">
                  <Form.Item label="Ticks" colon={false} name="ticks" className={`${styles.FormItem} w-full`}>
                    <Input value={(questionRange?.ticks || []).join(",")} />
                  </Form.Item>
                </Tooltip>
              </Space>
            </Form>
            )
          }
          {
            questionType && isMultipleQuestionType(questionType) && 
            <Space direction='vertical' style={{width: "100%"}}>
              <Form form={questionChoicesForm} name="add-question-choice" validateMessages={validateMessages} onFinish={addQuestionChoice}>
                <Text style={{fontWeight: 'bold'}}>Choices</Text>
                <Form.Item label="Label" colon={false} name="label" className={`${styles.FormItem} w-full`} 
                rules={[
                  { required: true}, 
                  {message: "Choice label must be unique.",
                    validator: ( _, value) => {
                      if (!editChoice && questionChoices.filter(choice => choice.label === value).length === 0) return Promise.resolve();
                      else if (editChoice && questionChoices.filter((choice, index) => choice.label === value && index !== editChoice.editIndex).length === 0) return Promise.resolve()
                      return Promise.reject();
                    }
                  }]}>
                  <Input />
                </Form.Item>
                {
                  (surveyType === SurveyType.Enrollment || surveyType === SurveyType.Consent) &&
                  <Form.Item label="Value" colon={false} name="value" className={`${styles.FormItem} w-full`} rules={[{ required: true}]}>
                    <Input />
                  </Form.Item> 
                }
                <Row gutter={[16, 16]} align='middle'>
                  <Col span={16}>
                    {
                      (surveyType !== SurveyType.Enrollment && surveyType !== SurveyType.Consent) &&
                      <Form.Item label="Value" colon={false} name="value" className={`${styles.FormItem} w-full`} rules={[{ required: true}]}>
                        <Input />
                      </Form.Item>
                    }
                    {
                      (surveyType === SurveyType.Enrollment || surveyType === SurveyType.Consent) &&
                      <Form.Item label="Ineligible Answer" name="ineligibleAnswer" colon={false}>
                        <Checkbox checked={isIneligibleAnswer} onChange={({target}) => setIsIneligibleAnswer(target.checked) } />
                      </Form.Item>
                    }
                  </Col>
                  <Col span={8} style={{height: "32px", marginTop: "18px"}}>
                    <Button className={styles.AddChoiceButton}
                      onClick={e => {
                        e.preventDefault()
                        questionChoicesForm.submit()
                      }}>
                        {!editChoice && <PlusCircleOutlined style={{fontSize: "16px", fontWeight: "bold"}} />}
                        {!editChoice ? "Add" : "Update"}
                    </Button>
                  </Col>
                </Row>
                <Form.Item style={{marginBottom: "12px"}} name="add-question-choice-submit">
                    {
                        questionChoices.length > 0 && 
                        <SortableTable data={questionChoices} setData={setQuestionChoices} columns={columns} getSortKey={i => i.label} rowKey="label" />
                    }
                </Form.Item>
              </Form>
            </Space>
          }
          <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            <Button htmlType='submit' type="primary" block>
              {editQuestion ? "Update Question" : "Add Question"}
            </Button>
          </Form.Item>
    </Form>
  )
}