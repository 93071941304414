
import { AimOutlined, CameraOutlined, CheckCircleOutlined, CloseCircleOutlined, HourglassOutlined, NumberOutlined, PauseCircleOutlined, PlayCircleOutlined, QuestionCircleOutlined, StepForwardOutlined, UserOutlined, WarningFilled } from "@ant-design/icons";
import { App, Button, ButtonProps, Card, Divider, Form, Input, Popconfirm, Select, Space, Table, Typography } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { v4 as uuidv4 } from 'uuid';
import { FlowUIMeta, useClinicNextTask, useCreateSession } from "../../api";
import { handleLog } from "../../utilities";

import { NormalizedLandmark } from "@mediapipe/tasks-vision";
import moment from "moment";
import { useHistory, useLocation } from "react-router-dom";
import { ClinicAuthTokenContext } from "../../pages/Clinic";
import { token } from "../../theme";

const { Text } = Typography
const { Option } = Select
const { TextArea } = Input
const { useForm } = Form

type OfflineStatus = {
  // `Ready` just means pipeline itself is ready, `Recording` is a client side status
  status: "Ready" | "Processing" | "Success" | "Error" | "NoOfflineData" | "Recording"
  output_filename?: string
  error?: string,
  timestamp: number
}

type SnirfStatus = Record<string, OfflineStatus>

type SnirfTableSource = Array<OfflineStatus & { session_id: string }>

export const UPLOAD_TARGET =
  process.env.REACT_APP_LOCAL_FLOW_UI_TOKEN ? "Engram" :
  process.env.REACT_APP_OFFLINE_FLOW_UI ? "LocalOffline" :
  process.env.REACT_APP_NEUROME_ENV === "production" ? "NeuromeProduction" :
  process.env.REACT_APP_NEUROME_ENV === "staging" ? "NeuromeStaging" :
  "";

export const isSynapse = !!process.env.REACT_APP_LOCAL_FLOW_UI_TOKEN;
export const isOffline = !!process.env.REACT_APP_OFFLINE_FLOW_UI;

console.log(`
  Upload target is ${UPLOAD_TARGET}
  isSynapse is ${isSynapse ? "true" : "false"}
  isOffline is ${isOffline ? "true" : "false"}
`)

const OFFLINE_FLOW_MODEL = "flow.danube"

export type ExperimentEvent = 'start_experiment' | 'end_experiment'

export type ClinicRecordNavigationState = {
  clinicToken: string,
  checklistLogStepId: string
  checklistLogId: string
  createdByClinic: string
  studyId: string
  virtualUserId: string
  meta?: {
    experiment_name?: string
    session_number?: string
    session_name?: string
  }
}

export const AreYouSure: React.FC<{
  title: string
  description: string
  bypass: boolean
  onConfirm: () => void
  buttonText: string
  buttonProps: ButtonProps
}> = ({ title, description, bypass, onConfirm, buttonText, buttonProps }) => {
  const [open, setOpen] = useState(false);

  const handleOpenChange = (newOpen: boolean) => {
    if (!newOpen) {
      setOpen(newOpen);
      return;
    }
    if (bypass) {
      onConfirm();
    } else {
      setOpen(newOpen);
    }
  };

  return (
    <Popconfirm
      title={title}
      description={description}
      icon={<WarningFilled />}
      placement="left"
      open={open}
      onOpenChange={handleOpenChange}
      onConfirm={onConfirm}
      onCancel={() => setOpen(false)}
      okText="Yes"
      cancelText="No"
      disabled={buttonProps.disabled}
    >
      <Button {...buttonProps}>{buttonText}</Button>
    </Popconfirm>
  );
}

export const TaskWarnings = {
  NOT_RECORDING_AND_TASK_STARTED: 'Task start received, but session was not recording; please start recording then start task!',
  RECORDING_AND_TASK_NOT_ENDED: 'Task end has not yet been received. Continue recording!',
  RECORDING_AND_TASK_ENDED: 'The task seems to have ended; do you want to end the recording?',
} as const;


const Record: React.FC<{
  sdkClient?: import('kernel-talk-web/kernel_talk_web').SdkClient
  dataStreamId?: string
  experimentNames: FlowUIMeta['experiment_names']
  participants: FlowUIMeta['participants']
  clearExperimentEvent: () => void
  tasksUrl?: string
  warningsExist: boolean
  writerState: boolean | undefined,
  setWriterState: (state: boolean | undefined) => void,
  hasHandshake: boolean,
  recordExperimentAlert: string | undefined,
  setRecordExperimentAlert: (alert: string | undefined) => void,
  recordingStart: number | undefined,
  setRecordingStart: (start: number | undefined) => void,
  manualCommand: boolean | undefined,
  setManualCommand: (command: boolean | undefined) => void,
  participantPhotoRequired: boolean,
  participantPhoto?: string,
  participantPhotoLandmarks?: NormalizedLandmark[],
  setBypassLaserOnPrompt: (bypass: boolean) => void,
  isClinic: boolean,
  offlineProcessingState?: string,
}> = ({
  sdkClient,
  dataStreamId,
  experimentNames,
  participants,
  clearExperimentEvent,
  tasksUrl,
  warningsExist,
  writerState,
  setWriterState,
  hasHandshake,
  recordExperimentAlert,
  setRecordExperimentAlert,
  recordingStart,
  setRecordingStart,
  manualCommand,
  setManualCommand,
  participantPhotoRequired,
  participantPhoto,
  participantPhotoLandmarks,
  setBypassLaserOnPrompt,
  isClinic,
  offlineProcessingState,
}) => {
  const location = useLocation()
  const history = useHistory()
  const clinicDefaults: ClinicRecordNavigationState | undefined = location.state as ClinicRecordNavigationState | undefined;
  const clinicAuthTokenContext = useContext(ClinicAuthTokenContext);

  const [recordingFor, setRecordingFor] = useState<number>(0)
  const [clinicNextTaskResponse, setClinicNextTaskResponse] = useState<Record<string, string>>()
  const [offlineSessions, setOfflineSessions] = useState<SnirfTableSource>([])
  const [form] = useForm();
  const { message: messageApi } = App.useApp();

  const startRecordingWarnings = (warningsExist || (recordExperimentAlert === TaskWarnings.NOT_RECORDING_AND_TASK_STARTED))
  const stopRecordingWarnings = (recordExperimentAlert === TaskWarnings.RECORDING_AND_TASK_NOT_ENDED)

  const [createSession, { isLoading: createSessionLoading }] = useCreateSession({
    onError: () => {
      void messageApi.error(`Error creating session`)
    },
  })

  useEffect(() => {
    form.resetFields()
  }, [location.state])

  const [clinicNextTask] = useClinicNextTask(
    clinicAuthTokenContext.token,
    clinicDefaults?.studyId || '',
    clinicDefaults?.checklistLogId || '',
    clinicDefaults?.checklistLogStepId || '',
  )

  useEffect(() => {
    if (manualCommand) return
    if (recordingStart && writerState === false) {
      setRecordingStart(undefined)
      handleLog("record", "stopped timer")
      void messageApi.warning("Recording stopped due to timer expiring!")
    } else if (!recordingStart && writerState === true) {
      setRecordingStart(Date.now() / 1000)
      handleLog("record", "wasRecording")
      void messageApi.warning("Already recording!")
    }
  }, [manualCommand, recordingStart, writerState])

  useEffect(() => {
    if (recordingStart) {
      const timer = setInterval(() => {
        setRecordingFor(Date.now() / 1000 - recordingStart)
      }, 1000)
      return () => clearInterval(timer)
    } else {
      setRecordingFor(0)
    }
  }, [recordingStart])

  useEffect(() => {
    const offlineState = JSON.parse(offlineProcessingState || '{}') as SnirfStatus
    
    setOfflineSessions(Object.entries(offlineState).map(([key, value]) => ({ ...value, session_id: key })).sort((a, b) => b.timestamp - a.timestamp))
  }, [offlineProcessingState])

  return (
    <Space direction="vertical" style={{ width: "100%" }}>
      <Form
        form={form}
        labelCol={{ span: 6 }}
      wrapperCol={{ span: 18 }}
        initialValues={{
          participant_id: clinicDefaults?.virtualUserId,
          experiment: clinicDefaults?.meta?.experiment_name,
          number: clinicDefaults?.meta?.session_number,
          name: clinicDefaults?.meta?.session_name,
          participantPhoto,
          participantPhotoLandmarks,
        }}
        onFinish={async (values: Record<string, string>) => {
          if (!sdkClient) return

          const { participant_id: virtualUserId, duration, participantPhoto, participantPhotoLandmarks,  ...meta } = values;
          let res: Record<string, string> | undefined = undefined
          if (!isOffline) {
            res = await createSession({
              clinicToken: clinicDefaults?.clinicToken,
              studyId: clinicDefaults?.studyId,
              virtualUserId,
              participantPhoto,
              participantPhotoLandmarks,
              meta,
              checklistLogId: clinicDefaults?.checklistLogId,
              checklistLogStepId: clinicDefaults?.checklistLogStepId,
              createdByClinic: clinicDefaults?.createdByClinic,
            })
          } else {
            // gen local session id uuid
            res = {
              id: uuidv4(),
              upload_token: uuidv4(),
            }
          }
          if (res) {
            handleLog("record", "starting")
            setManualCommand(true)
            try {
              // if not offline, dataStreamId is required
              if (!isOffline && !dataStreamId) {
                void messageApi.error("No Data Stream ID!")
                return
              }
              
              const uploadSessionInfo: Record<string, string | Record<string, string | Record<string,string>>> = {
                target: UPLOAD_TARGET,
                http_addr: process.env.REACT_APP_PORTAL_API_CONFIG_URL,
                device_id: dataStreamId || uuidv4(), // offline won't try to upload with this id
              };

              if (isOffline) {
                uploadSessionInfo["offline_meta"] = {
                  kernel_portal_version: process.env.REACT_APP_GIT_SHA,
                  session_id: res.id,
                  snirf_meta: { flow_model: OFFLINE_FLOW_MODEL },
                  subject_id: virtualUserId,   
                }
                uploadSessionInfo["target"] = "LocalOffline"
              }

              await sdkClient.send_control({
                "StartWriting": {
                  "token": res.upload_token,
                  "duration": duration ? parseInt(duration) : null,
                  "session_id": res.id,
                  "upload_session_info": uploadSessionInfo,
                }
              })
              setWriterState(undefined)
              setRecordingStart(Date.now() / 1000)

              if (isOffline) {
                const current_time = Date.now() / 1000
                const recordingSession: SnirfTableSource[number] = { status: "Recording", session_id: res.id, timestamp: current_time}
                setOfflineSessions([recordingSession, ...offlineSessions])
              }

              if (isClinic && tasksUrl && (clinicDefaults?.meta?.experiment_name || '').startsWith('flow-neuro-')) window.open(tasksUrl, '_blank')
            } catch (e) {
              handleLog("record", "error starting writing", e)
              void messageApi.error("Error Starting Recording!")
            }
            setTimeout(() => setManualCommand(false), 1000) // TODO: this could be better in leaving it true and when writerState changes, setting it to false but needs more testing
          }
        }}
      >
        {!isSynapse && (
          <>       
            <Form.Item name="participant_id" colon={false} label={<Space direction="horizontal"><UserOutlined style={{ color: token.colorPrimary }} />Participant</Space>} rules={[{ required: true, message: 'Please select participant' }]}>
              {isOffline ? <Input size="large" disabled={!!recordingStart} allowClear={true} /> : (
                <Select
                  size="large"
                  disabled={!!recordingStart || !!clinicDefaults?.virtualUserId}
                  allowClear={true}
                >
                {participants.map(participant => <Option key={participant.id} value={participant.id}>{participant.active ? <CheckCircleOutlined /> : <QuestionCircleOutlined />} {participant.participant_id}</Option>)}
                </Select>
              )}          
            </Form.Item>
            {!isOffline && (
              <>
                <Form.Item name="experiment" colon={false} label={<Space direction="horizontal"><AimOutlined style={{ color: token.colorPrimary }} />Task</Space>}>
                  <Select
                    size="large"
                    disabled={!!recordingStart || !!clinicDefaults?.meta?.experiment_name}
                    allowClear={true}
                  >
                    {Object.entries(experimentNames).map(([value, label]) => <Option key={value} value={value}>{label}</Option>)}
                  </Select>
                </Form.Item>
                <Form.Item name="number" colon={false} label={<Space direction="horizontal"><NumberOutlined style={{ color: token.colorPrimary }} />Number</Space>}>
                  <Input size="large" disabled={!!recordingStart || !!clinicDefaults?.meta?.session_number} type="number" allowClear={true} />
                </Form.Item>
                <Form.Item name="name" colon={false} label="Name">
                  <Input size="large" disabled={!!recordingStart || !!clinicDefaults?.meta?.session_name} allowClear={true} />
                </Form.Item>
                <Form.Item name="description" colon={false} label="Description">
                  <TextArea size="large" disabled={!!recordingStart} allowClear={true} />
                </Form.Item>
              </>
            )}
            <Form.Item name="duration" colon={false} label={<Space direction="horizontal"><HourglassOutlined style={{ color: token.colorPrimary }} />Duration</Space>}>
              <Input size="large" disabled={!!recordingStart} type="number" allowClear={true} placeholder="seconds" />
            </Form.Item>
            {!isOffline && (
              <>
                {participantPhotoRequired ? (
                  <Form.Item name="participantPhoto" colon={false} label={<Space direction="horizontal"><CameraOutlined style={{ color: token.colorPrimary }} />Photo</Space>}>
                    {participantPhoto ? (
                      <>
                        <CheckCircleOutlined />
                        &nbsp;
                        Photo Captured
                      </>
                    ) : (
                      <>
                        <CloseCircleOutlined />
                        &nbsp;
                        No Photo Captured
                      </>
                    )}
                  </Form.Item>
                ) : (
                  <Form.Item name="participantPhoto" hidden />
                )}
                <Form.Item name="participantPhotoLandmarks" hidden />
              </>
            )}
          </>
        )}
        <Form.Item wrapperCol={{ offset: 6, span: 18 }}>
          <Space direction="vertical" style={{ width: "100%" }}>
            {!isSynapse && (!recordingStart ? (
              <AreYouSure
                title="Warnings Exist!"
                description="Are you sure you want to Start Recording?"
                bypass={!startRecordingWarnings}
                onConfirm={() => form.submit()}
                buttonText="Start Recording"
                buttonProps={{
                  size: "large",
                  style: { width: '300px' },
                  disabled: !sdkClient || createSessionLoading || manualCommand || (!isOffline && (!dataStreamId || !hasHandshake)),
                  type: "primary",
                  icon: <PlayCircleOutlined />,
                }}
              />
            ) : (
              <Space direction="vertical" style={{ width: "100%" }}>
                <AreYouSure
                  title="Task Still Running!"
                  description="Are you sure you want to Stop Recording?"
                  bypass={!stopRecordingWarnings}
                  onConfirm={async () => {
                    if (!sdkClient) return

                    handleLog("record", "stopping")
                    setManualCommand(true)

                    try {
                      await sdkClient.send_control("StopWriting")
                      setRecordingStart(undefined)
                      clearExperimentEvent()
                      setRecordExperimentAlert(undefined)

                      if (clinicDefaults) {
                        const nextTask = await clinicNextTask()
                        if (nextTask && Object.keys(nextTask).length > 0) {
                          setClinicNextTaskResponse(nextTask)
                        }
                      }

                      void messageApi.info("Recording stopped successfully!")
                    } catch (e) {
                      handleLog("record", "error stopping writing", e)
                      void messageApi.error("Error Stopping Recording!")
                    }

                    setTimeout(() => setManualCommand(false), 1000) // TODO: this could be better in leaving it true and when writerState changes, setting it to false but needs more testing
                  }}
                  buttonText="Stop Recording"
                  buttonProps={{
                    size: "large",
                    style: { width: '300px' },
                    htmlType: "button",
                    disabled: manualCommand,
                    icon: <PauseCircleOutlined />,
                  }}
                />
                <Text>Recording for {recordingFor < 60 ? `${Math.floor(recordingFor)} sec` : `${Math.floor(recordingFor / 60)} min`}</Text>
              </Space>
            ))}
            {!isClinic && tasksUrl && (
              <AreYouSure
                title="Not Currently Recording"
                description="Are you sure you want to Launch Tasks?"
                bypass={!!recordingStart}
                onConfirm={() => window.open(tasksUrl, '_blank')}
                buttonText="Launch Tasks"
                buttonProps={{
                  size: "large",
                  style: { width: '300px' },
                  icon: <AimOutlined />,
                }}
              />
            )}
            {clinicDefaults && clinicNextTaskResponse && (
              <>
                <Divider />
                <Button
                  size="large"
                  style={{ width: '300px' }}
                  icon={<StepForwardOutlined />}
                  type="primary"
                  onClick={() => {
                    setBypassLaserOnPrompt(true)
                    // wait for the state to update, then ...
                    setTimeout(() => {
                      history.push(location.pathname, {
                        clinicToken: clinicAuthTokenContext.token,
                        checklistLogId: clinicDefaults.checklistLogId,
                        checklistLogStepId: clinicNextTaskResponse.id,
                        createdByClinic: clinicDefaults.createdByClinic,
                        studyId: clinicDefaults.studyId,
                        virtualUserId: clinicDefaults.virtualUserId,
                        meta: clinicNextTaskResponse,
                      } as ClinicRecordNavigationState)
                      void messageApi.info(`Switched to Next Task: ${clinicNextTaskResponse.experiment_display_name}`)
                      setBypassLaserOnPrompt(false)
                      setClinicNextTaskResponse(undefined)
                    }, 0)
                  }}
                >
                  Next Task: {clinicNextTaskResponse.experiment_display_name}
                </Button>
              </>
            )}
          </Space>
        </Form.Item>
      </Form>
      {isOffline && (
        <Card title="Offline Datasets">
          <Table
            size="small"
            columns={[
              {
                title: 'Dataset ID',
                dataIndex: 'session_id',
                key: 'session_id',
                render: (session_id: string) => <Text copyable={{ text: session_id }}>{session_id.slice(0, 8)}</Text>
              },
              {
                title: 'Status',
                dataIndex: 'status',
                key: 'status',
                render: (status: OfflineStatus['status'], record: OfflineStatus) => {
                  switch (status) {
                    case "Ready":
                      return <Text type="warning">Ready</Text>
                    case "Processing":
                      return <Text type="warning">Processing</Text>
                    case "Success":
                      return <Text type="success">Success</Text>
                    case "Error":
                      return <Text type="danger">Error: {record.error}</Text>
                    case "NoOfflineData":
                      return <Text type="secondary">No Offline Data</Text>
                    case "Recording":
                      return <Text>Recording</Text>
                  }
                }
              },
              {
                title: 'Output Filename',
                dataIndex: 'output_filename',
                key: 'output_filename',
                render: (output_filename: string) => {
                  if (!output_filename) return;
                  const parts = output_filename.split("/");
                  return <Text code copyable={{ text: output_filename }}>{parts[parts.length - 1]}</Text>
                }
              },
              {
                title: 'Timestamp',
                dataIndex: 'timestamp',
                key: 'timestamp',
                render: (timestamp: number) => moment(timestamp * 1000).format('YYYY MMM DD LT')
              }
            ]}
            
            dataSource={Object.values(offlineSessions)}
            rowKey="session_id"
          />
        </Card>
      )}
    </Space>
  )
}

export default Record

