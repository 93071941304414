import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as Sentry from "@sentry/react";
import { CaptureConsole } from "@sentry/integrations";
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { ReactQueryCacheProvider, ReactQueryCacheProviderProps } from 'react-query';
import { queryCache } from './api';
import Clinic from './pages/Clinic';
import { captureMessage } from '@sentry/react';
import { App as AntdApp, ConfigProvider, theme } from 'antd';
import { token } from "./theme"
import DataStreamSubscribe from './pages/DataStreamSubscribe';
import OfflineFlowUI from './pages/OfflineFlowUI';
import Survey from './pages/Survey';
import { redirectPortalToResearch } from './utilities';
import NotFound from './pages/NotFound';

const history = createBrowserHistory()

if (process.env.NODE_ENV === "production") {
  console.log('[portal.debug] Initializing Sentry...');

  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_CONFIG_DSN,
    environment: process.env.REACT_APP_SENTRY_CONFIG_ENV,
    release: process.env.REACT_APP_GIT_SHA,
    integrations: [
      new CaptureConsole({
        levels: ['error']
      }),
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
      }),
    ]
  });
} else {
  console.log('[portal.debug] Skipping initializing Sentry...');
}

if (process.env.REACT_APP_ZENDESK === '1') {
  window.zESettings = {
    webWidget: {
      contactForm: {
        fields: [{ id: 1500002272502, prefill: { '*': 'portal' } }],
      }
    }
  };

  window.zE('webWidget:on', 'userEvent', function (event) {
    if (event.action === "Contact Form Submitted") {
      captureMessage("Ticket submitted")
      window.onSubmitZendeskTicket?.()
    }
  });
}

const root = createRoot(document.getElementById('root')!); // eslint-disable-line
const ReactQueryCacheProviderTyped = ReactQueryCacheProvider as React.FC<ReactQueryCacheProviderProps & { children: React.ReactNode }>; // TODO: remove me when we're on new version of react router
root.render(
  <React.StrictMode>
    {/* Keep browser router at index level to allow `useLocation` hook usage within the App component */}
    <Router history={history}>
      <Sentry.ErrorBoundary fallback={<>An error has occured</>}>
        <ReactQueryCacheProviderTyped queryCache={queryCache}>
          <ConfigProvider
            theme={{
              algorithm: theme.darkAlgorithm,
              components: {
                Card: {
                  colorBgContainer: "#26242f",
                  colorBorderSecondary: "#121015",
                  lineWidth: 2,
                },
                Menu: {
                  activeBarHeight: 0,
                  activeBarBorderWidth: 0,
                  itemSelectedColor: '#fff',
                  horizontalItemSelectedColor: '#fff',
                  itemBg: 'transparent',
                  lineWidth: 0,
                  itemHeight: 30,
                },
              },
              token,
            }}
          >
            <AntdApp style={{ height: '100%' }}>
              {process.env.REACT_APP_OFFLINE_FLOW_UI ? (
                <OfflineFlowUI />
              ) : window.location.hostname === "localhost" ? ( // all the routes
                <Switch>
                  <Route path="/enroll/:studyId">
                    <Survey />
                  </Route>
                  <Route path="/survey/:token">
                    <Survey />
                  </Route>
                  <Route path="/clinic/:studyId">
                    <Clinic />
                  </Route>
                  <Route path="/websocket/data_stream_subscribe">
                    <DataStreamSubscribe />
                  </Route>
                  <Route>
                    <App />
                  </Route>
                </Switch>
              ) : window.location.hostname.startsWith('research.') ? (
                <Switch>
                  <Route path="/enroll/:studyId">
                    <Survey />
                  </Route>
                  <Route path="/survey/:token">
                    <Survey />
                  </Route>
                  <Route path="/clinic/:studyId">
                    <Clinic />
                  </Route>
                  <Route path='/404' exact={true}>
                    <NotFound />
                  </Route>
                  <Route>
                    <Redirect to='/404' />
                  </Route>
                </Switch>
              ) : (
                <Switch>
                  <Route path={["/enroll/:studyId", "/survey/:token", "/clinic/:studyId"]} component={() => {
                      window.location.href = redirectPortalToResearch(window.location.href);
                      return null;
                  }}/>
                  <Route path="/websocket/data_stream_subscribe">
                    <DataStreamSubscribe />
                  </Route>
                  <Route>
                    <App />
                  </Route>
                </Switch>
              )}
            </AntdApp>
          </ConfigProvider>
        </ReactQueryCacheProviderTyped>
      </Sentry.ErrorBoundary>
    </Router>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
