import React, { useContext, PropsWithChildren, FC, createContext, useCallback, useEffect } from 'react'
import { Study, studies as useStudies, useStudy } from '../api'
import { Tabs, Menu } from 'antd'
import { ArrowDownOutlined, BulbOutlined } from '@ant-design/icons'
import { generatePath, useRouteMatch, Link, NavLink } from 'react-router-dom'
import { useSelectedOrganizationContext } from './SelectedOrganization'
import createPersistedState from 'use-persisted-state'
import { Loader } from '../components/Loader'

type StudyTab = "datasets" | "flow" | "details" | "participants" | "researchers" | "tasks" | "surveys" | "analysis"

// the selected oganzation context type which can consumed with `useSelectedStudyContext`
// and provided via `<SelectedStudyContextProvider>`
type SelectedStudyContext = {
  study?: Study,
  studyTab: StudyTab
  isFetchingSelectedStudy: boolean
}

// the globally selected study. only one can be selected at once. if
// there is only one study available to the researcher, it is selected
const SelectedStudyContext = createContext<SelectedStudyContext>({
  // select the datasets tab by default
  studyTab: 'datasets',
  isFetchingSelectedStudy: false
})

// use this hook to access the global selected study state in any component underneath
// the `<SelectedStudyContextProvider>`
export const useSelectedStudyContext: () => SelectedStudyContext = () => useContext(SelectedStudyContext)

// store the selected study tab in local storage
const usePersistedSelectedStudyTabState = createPersistedState<StudyTab>('portal-study-tab')

// the selected study provider holds the selected study state and navigates appropriately
// on changes
export const SelectedStudyContextProvider: FC<PropsWithChildren<unknown>> = ({ children }) => {
  const [selectedStudyTab, setSelectedStudyTab] = usePersistedSelectedStudyTabState('datasets')
  const organizationStudyRouteMatch = useRouteMatch<{ organizationId?: string, studyId?: string, studyTab?: StudyTab }>('/organizations/:organizationId/studies/:studyId/:studyTab')
  const { organizationId: organizationStudyRouteMatchOrganizationId, studyId: organizationStudyRouteMatchStudyId, studyTab: organizationStudyRouteMatchStudyTab } = organizationStudyRouteMatch?.params ?? {}
  const { data: study, refetch: refetchStudy, isFetching: isFetchingSelectedStudy } = useStudy(organizationStudyRouteMatchOrganizationId, organizationStudyRouteMatchStudyId)

  // when organization id in route changes, refetch the organization
  useEffect(() => {
    if (organizationStudyRouteMatchStudyId) {
      void refetchStudy()
    }
  }, [organizationStudyRouteMatchStudyId])

  // when a study tab is set (from changing the URL study tab) store
  // it in persisted state
  useEffect(() => {
    if (organizationStudyRouteMatchStudyTab) {
      setSelectedStudyTab(organizationStudyRouteMatchStudyTab)
    }
  }, [organizationStudyRouteMatchStudyTab])

  return (
    <SelectedStudyContext.Provider
      value={{
        study,
        studyTab: selectedStudyTab,
        isFetchingSelectedStudy
      }}
    >
      {children}
    </SelectedStudyContext.Provider>
  )
}

// a dropdown component for setting the selected study using context
export const StudiesDropdown: FC<unknown> = () => {
  const { organization } = useSelectedOrganizationContext()
  const { data: studies, isLoading } = useStudies(organization?.id, new URLSearchParams({ only_open: 'true' }))
  const { study } = useSelectedStudyContext()

  if (!organization) return;
  if (isLoading) return <><Loader size="small" style={{ marginLeft: 5, marginRight: 5, fontSize: 12 }} /> Loading ...</>
  if (!studies || studies.length === 0) return;

  return (
    <Menu
      mode='horizontal'
      disabledOverflow={true}
      selectedKeys={study?.id ? [ study.id ] : []}
      items={[
        {
          label: <>{study?.name || "Studies"} <ArrowDownOutlined style={{ marginLeft: 5 }} /></>,
          key: 'Studies',
          icon: <BulbOutlined />,
          children: [
            {
              key: 'All Studies',
              label: <Link to={generatePath('/organizations/:organizationId/studies', { organizationId: organization.id })}>All Studies</Link>,
            },
            { type: 'divider' },
            ...studies?.map(study => ({
              key: study.id,
              label: <Link to={generatePath('/organizations/:organizationId/studies/:studyId', { organizationId: organization.id, studyId: study.id })}>{study.name}</Link>,
            })) || [],
          ]
        }
      ]}
    />
  )
}

/**
 * tabs for a study in an organization
 */
export const StudyTabs: FC<unknown> = () => {
  const { organization } = useSelectedOrganizationContext()
  const { study, studyTab } = useSelectedStudyContext()

  const generateLink = useCallback((key: string, label: string) => {
    if (!organization || !study) return { key, label };

    return {
      key,
      label: (
        <NavLink style={isActive => ({ color: isActive ? undefined : '#fff', paddingTop: 16, paddingBottom: 16 })} to={generatePath('/organizations/:organizationId/studies/:studyId/:studyTab', { organizationId: organization.id, studyId: study.id , studyTab: key as SelectedStudyContext['studyTab'] })}>
          {label}
        </NavLink>
      )
    }
  }, [organization, study])

  if (!study) return null

  return (
    <Tabs
      activeKey={studyTab}
      size="large"
      items={[
        generateLink("datasets", "Datasets"),
        generateLink("details", "Details"),
        generateLink("participants", "Participants"),
        generateLink("researchers", "Researchers"),
        generateLink("tasks", "Tasks"),
        ...(study._permissions.can_view_surveys ? [generateLink("surveys", "Surveys")] : []),
        ...(study._permissions.can_view_checklists ? [generateLink("protocols", "Protocols")] : []),
        ...(study._permissions.can_view_pipeline_statuses ? [generateLink("analysis", "Analysis")] : []),
        generateLink("flow", "Flow"),
      ]}
    />
  )
}

