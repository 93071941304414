import React, { useEffect, useRef } from "react"
import { GoogleCircleFilled } from "@ant-design/icons"
import { Button } from "antd"

type GoogleLoginProps = {
  callback: (token: string) => void
}

const GoogleLogin: React.FC<GoogleLoginProps> = ({ callback }) => {
  const buttonContainerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (!buttonContainerRef.current) return
      window.google.accounts.id.initialize({
        client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID as string,
        callback: res => callback((res as unknown as { credential: string }).credential),
      });
      window.google.accounts.id.renderButton(buttonContainerRef.current, {});
  }, [buttonContainerRef])

  return (
    <div style={{ width: "100%" }}>
      <Button size="large" block onClick={() => {
        const button = buttonContainerRef.current?.querySelector<HTMLButtonElement>("div[role=button]")
        if (button) button.click();
      }}><GoogleCircleFilled /> Google</Button>
      <div style={{ display: "none" }} ref={buttonContainerRef}></div>
    </div>
  )
}

export default GoogleLogin