
import React , { useEffect, useState } from 'react';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { MutateFunction } from 'react-query';
import { App, QRCode } from 'antd';
import { useCheckQrStatus } from '../../api';
import styles from './styles.module.css';


export type CognitoUserWithParams = CognitoUser & { challengeParam: ChallengeParameters };

type ChallengeParameters = {
    email: string;
    display_code: string;
    secret_code: string;
}

export type QrCodeProps = { 
    /**
     * Current Cognito User signing in
     */
    cognitoUser?: CognitoUserWithParams;
    /**
     * Trigger function to send code when scanned by user
     */
    emailCodeReceivedCallback: MutateFunction<CognitoUser, Error, {
        email: string;
        cognitoUser: CognitoUser | undefined;
        code: string;
    }, unknown>;
    /**
     * Handle invalid sessions, for instance by resetting the login flow and going to the splash screen
     */
    invalidSessionHandler: (args: { isSignUpFlow: boolean }) => void;
}

/**
 * Usage:
 *   User is logging in -- we get the cognito user, which has the challenge parameters we need for QR:
 *   challengeParam.display_code and challengeParam.secret_code
 * 
 *   1. Use the display code to display the code to the user using <QrCodeDisplay />
 * 
 *   2. While this is displayed, poll with the secret code to POST /auth/display_code/status with { email, status_code } from challengeParam
 *        * When this is successful, use the email_code response to confirm as usual
 *        * When this fails, rotate the secret_code for the next request
 */
export const QrCodeDisplay: React.FC<QrCodeProps> = ({ 
    cognitoUser,
    emailCodeReceivedCallback,
    invalidSessionHandler
}) => {
    const { email='', display_code='', secret_code='' } = cognitoUser?.challengeParam || {};
    const [secretCode, setSecretCode] = useState<string>(secret_code);
    const { data: qrStatus, isLoading, error } = useCheckQrStatus(email, secretCode);
    const { message: messageApi } = App.useApp();

    useEffect(() => {
        if (isLoading || error || !qrStatus) return;

        // if successful, then prefill the email code so they can sign in
        if (qrStatus.success) {
            void emailCodeReceivedCallback({
                    email: email,
                    cognitoUser: cognitoUser,
                    code: qrStatus.email_code,
                });
            return
        }

        // if not successful and the server returned a secret code, we can keep polling
        const newSecretCode = qrStatus.secret_code;
        if (newSecretCode) {
            const timer = setTimeout(
                () => setSecretCode(newSecretCode),
                1000
            );
            return () => clearTimeout(timer);
        }

        // we got some sort of error from the server,
        // it doesn't matter which error, we should force them to log in again
        void messageApi.error('Your session has expired due to inactivity. Please log in.', 4);
        return invalidSessionHandler({ isSignUpFlow: false });
    }, [qrStatus]);
    
    return (
        <div className={styles['qr-code-container']}>
            <span>Open the QR code scanner from the profile page of the Kernel app to log in with your mobile device.</span>
            <QRCode value={display_code} size={170} />
        </div>
    )
}