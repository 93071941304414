import React, { useCallback, useState } from 'react'
import { user as getUser, API_VERSION, apiVersion, useDeleteUser, useUpdateUserEmail, useVerifyUserEmail } from '../api'
import { PageHeader } from "@ant-design/pro-components"
import {
  Descriptions,
  Spin,
  Space,
  Typography,
  Tooltip,
  Button,
  Modal,
  Input, 
  Switch,
  Form,
  Card,
  App
} from 'antd'
import { DeleteOutlined, EditOutlined, LogoutOutlined } from '@ant-design/icons'
import { Auth } from '@aws-amplify/auth';
import { useHistory } from 'react-router-dom'
import { useForm } from "antd/lib/form/Form";
import { color } from '../theme'
import { captureException } from '@sentry/react'

const { Text, Title } = Typography

const DeleteAccount: React.FC = () => {
  const [showModal, setShowModal] = useState<boolean>(false)
  const [fullDelete, setFullDelete] = useState<boolean>(false)
  const { message: messageApi } = App.useApp();

  const [deleteUser, { isLoading: deleteUserLoading }] = useDeleteUser({
    onError: () => {
      void messageApi.error("Error! Your account was not deleted.");
    }
  });

  return (
    <>
      <Button loading={deleteUserLoading} disabled={deleteUserLoading} onClick={() => setShowModal(true)} danger icon={<DeleteOutlined />}>Delete Account</Button>
      <Modal title="Delete Account" width={650} open={showModal} onOk={async () => {
        setShowModal(false);
        const res = await deleteUser({ full_delete: fullDelete })
        if (res?.success) {
          void messageApi.success(`Success! Your account will be deleted and your data will be ${fullDelete ? "deleted" : "de-identified"}.`);
          void Auth.signOut()
        } else {
          void messageApi.error("Error! Your account was not deleted.");
        }
      }} onCancel={() => setShowModal(false)} okText={fullDelete ? "Delete Data" : "De-Identify Data"} okType="danger">
        <Space direction="horizontal">
          <Card title="De-Identify Data" style={{ width: 250 }} headStyle={!fullDelete ? { backgroundColor: color.blue } : {}}>
            <ul>
              <li>Delete my account</li>
              <li>De-identify my data to contribute to science</li>
            </ul>
          </Card>
          <Switch checked={fullDelete} onChange={checked => setFullDelete(checked)} />
          <Card title="Delete Data" style={{ width: 250 }} headStyle={fullDelete ? { backgroundColor: color.blue } : {}}>
            <ul>
              <li>Delete my account</li>
              <li>Delete all my data</li>
            </ul>
          </Card>
        </Space>
      </Modal>
    </>
  )
}

const SignOut: React.FC = () => {
  const history = useHistory()

  const signOut = useCallback(async () => {
    await Auth.signOut()

    // clear `/profile` so when logging back in, they land on home
    history.replace('/')
  }, [history])
 
  return (
    <Button
      onClick={signOut}
      icon={<LogoutOutlined />}
    >
      Sign Out
    </Button>
  )
}

const UserProfile: React.FC = () => {
  const { isLoading: userLoading, isError: userError, data: user } = getUser();
  const [updateUserEmailModalOpen, setUpdateUserEmailModalOpen] = useState<boolean>(false);
  const [updateEmailForm] = useForm();
  const [verifyEmailForm] = useForm();
  const [code, setCode] = useState<string>("")
  const [newEmail, setNewEmail] = useState<string>("")
  const [updateEmailMode, setUpdateEmailMode] = useState<boolean>(false);
  const history = useHistory()
  const { message: messageApi } = App.useApp();

  const signOut = useCallback(async () => {
    await Auth.signOut()

    // clear `/profile` so when logging back in, they land on home
    history.replace('/')
  }, [history])

  const [updateUserEmail] = useUpdateUserEmail({
    onError: (error) => {
      if (error?.status === 409) {
        void messageApi.error('Cannot update email to one that already exists');
        return;
      }
      else if (error?.status === 429) {
        void messageApi.error('Cannot change email at this time, please try again later');
        return;
      }
      void messageApi.error('Failed to verify user email');
    },
    onSuccess: () => {
      setUpdateEmailMode(false);
      setNewEmail("");
    }
  });

  const [verifyUserEmail] = useVerifyUserEmail({
    onError: (error) => {
      if (error?.status === 409) {
        void messageApi.error('Cannot update email to one that already exists');
        return;
      } else if (error?.status === 404) {
        void messageApi.error('Verification expired, please start over and try again');
        return;
      }
      void messageApi.error('Failed to verify user email');
    },
    onSuccess: () => {
      setUpdateUserEmailModalOpen(false);
      setUpdateEmailMode(true);
      setCode("");
      void messageApi.success('Verified new email, please log in again with your new email');
      void signOut();
    }
  })

  const handleFormSubmit = async () => {
    if (updateEmailMode) {
      try {
        await updateEmailForm.validateFields();
        void updateUserEmail({new_email: newEmail});
        updateEmailForm.resetFields();
      } catch (error) {
        captureException(error);
        void messageApi.error("Error updating email!")
      } 
    } else {
      try {
        await verifyEmailForm.validateFields();
        const res = await verifyUserEmail({email_code: code});
        if (!res?.success) {
          void messageApi.error("Wrong verification code please start over and try again")
          setUpdateEmailMode(true);
        }
        verifyEmailForm.resetFields();
      } catch (error) {
        captureException(error);
        void messageApi.error("Error verifying email!")
      }
    }
  }

  return (
    <div>
        <Modal
        maskClosable={false}
        open={updateUserEmailModalOpen}
        onCancel={() => {
          setUpdateUserEmailModalOpen(false);
          updateEmailForm.resetFields();
          verifyEmailForm.resetFields();
        }}
        onOk={() => {
           void handleFormSubmit()
        }}
        okText="Submit"
        title={updateEmailMode ? "Update Email" : "Verify Email"}
        >
        <Space direction='vertical'>
          {
            updateEmailMode && 
            <Form form={updateEmailForm}>
              <Form.Item label="New Email" name="email"
                rules={[
                  { required: true, message: 'New Email is required.' },
                  {
                    type: "email",
                    message: "Enter a valid email.",
                  }, 
                  {
                    message: "New email is the same as current email.",
                    validator: ( _, value) => {
                      if (value !== user?.attributes.email) return Promise.resolve();
                      return Promise.reject();
                    }
                  }
                ]}>
                <Input required onChange={({ target }) => setNewEmail(target.value) }/>
              </Form.Item>
            </Form>
          }
          {
            !updateEmailMode &&
            <Form form={verifyEmailForm}>
              <Text>A code as been sent to the email provided. Please enter the code to verify your new email. After your email is verified you will be signed out and required to log back in with your new email.</Text>
              <Form.Item label="Code" name="code"
                rules={[{ required: true, message: 'Code is required.' }]}>
                <Input onChange={({ target }) => setCode(target.value) }/>
              </Form.Item>
            </Form>
          }
        </Space>
      </Modal>
      {
        userLoading ? <Spin /> :
        userError ? <span style={{ color: 'red' }}>Error loading user!</span> :
        user && (
          <Descriptions
            bordered
            title="User Profile"
            size="small"
            extra={
              <Space direction='horizontal'>
                <SignOut />
                <DeleteAccount />
              </Space>
            }
            column={2}
          >
            <Descriptions.Item label="Email">{user.attributes.email}                  
             <Tooltip title="Edit Email">
                <EditOutlined style={{ color: color.blue, marginLeft: "5px" }} onClick={() => { 
                  setUpdateEmailMode(true);
                  setUpdateUserEmailModalOpen(true);
                }} />
              </Tooltip>
            </Descriptions.Item>
            <Descriptions.Item label="ID">{user.attributes.sub}</Descriptions.Item>
          </Descriptions>
        )
      }
    </div>
  )
}

const AppInfo: React.FC = () => {
  const { data: version } = apiVersion()

  return (
    <Descriptions
      bordered
      title="Application"
      size="small"
      // because Amplify sign-out is a pain: https://github.com/aws-amplify/amplify-js/issues/7039#issuecomment-747764907
      extra={<div style={{ height: 49 }} />}
      column={2}
    >
      <Descriptions.Item label="API">{process.env.REACT_APP_PORTAL_API_CONFIG_URL}</Descriptions.Item>
      <Descriptions.Item label="Client API Version">{API_VERSION}</Descriptions.Item>
      <Descriptions.Item label="Client Code Version">{process.env.REACT_APP_GIT_SHA}</Descriptions.Item>
      <Descriptions.Item label="Server API Version">{version?.version}</Descriptions.Item>
      <Descriptions.Item label="Server Code Version">{version?.code}</Descriptions.Item>
    </Descriptions>
  )
}

const Profile: React.FC = () => {
  return (
    <PageHeader title={
      <Space direction="vertical" size="small">
        <Title level={3} style={{ margin: 0 }}>Profile</Title>
      </Space>
      }
    >
      <Space direction="vertical" size="large" style={{ width: "66%" }}>
        <UserProfile />
        <AppInfo />
      </Space>
    </PageHeader>
  )
}

export default Profile
