import React, { useCallback, useState } from 'react'
import { PageHeader } from "@ant-design/pro-components"
import {
    App,
    Button,
    Form,
  Input,
  Space,
  Typography,
} from 'antd'
import { useCreateOrganization } from '../api'
import { generatePath, useHistory } from 'react-router-dom'

const { Title } = Typography

const Register: React.FC = () => {
    const history = useHistory()
    const [form] = Form.useForm()
    const { message: messageApi } = App.useApp();
    const [addOrganizationDevice] = useCreateOrganization({
      onError: (error) => {
        if (error?.status === 404 || error?.status === 400) {
          void messageApi.error(error?.message)
          return
        }
        void messageApi.error('Failed to create organization')
      }
    });
  
    const addOrganization = useCallback(async () => {
      const res = await addOrganizationDevice({
        name: form.getFieldValue('name') as string,
        activationKey: form.getFieldValue('activationKey') as string,
      })
      if (res) {
        void messageApi.success('Organization created successfully!')
        history.push(generatePath('/organizations/:organizationId/studies', { organizationId: res.id }))
      }
    }, [form, addOrganizationDevice])
  
    const [name, setName] = useState<string>()
    const [activationKey, setActivationKey] = useState<string>()

  return (
    <PageHeader title={
      <Space direction="vertical" size="small">
        <Title level={3} style={{ margin: 0 }}>Register Your Flow Headset</Title>
      </Space>
      }
    >
      <Space direction="vertical" size="large" style={{ width: "66%" }}>
        <Form
            name="add-organization"
            form={form}
            onFinish={addOrganization}
        >
            <Form.Item label="Organization Name" name="name" required={true}>
                <Input onChange={({ target }) => setName(target.value) }/>
            </Form.Item>
            <Form.Item label="Activation Key" name="activationKey" required={true}>
                <Input onChange={({ target }) => setActivationKey(target.value) }/>
            </Form.Item>
            <Form.Item>
                <Button htmlType="submit" disabled={!name || !activationKey}>Create Organization</Button>
            </Form.Item>
        </Form>
      </Space>
    </PageHeader>
  )
}

export default Register
