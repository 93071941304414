
// React packages
import { useEffect } from 'react';
import { useMutation, queryCache, MutationResultPair } from 'react-query';
import { useLocation } from 'react-router-dom';
import * as Sentry from '@sentry/react';

// AWS
import { ISignUpResult, CognitoUser, ClientMetadata } from 'amazon-cognito-identity-js';
import { Auth } from '@aws-amplify/auth';

// Custom
import { getRandomString } from '../utilities';
import { MutationHandlers } from '.';

/* 
** Sign Up / Log In hooks
*
*     Cognito Passwordless Reference:
*     https://aws.amazon.com/blogs/mobile/implementing-passwordless-email-authentication-with-amazon-cognito/
*/
export function getClientMetadata(): ClientMetadata {
    return {
        'appVersion': window.navigator.appVersion,
        'platform': window.navigator.platform,
        'userAgent': window.navigator.userAgent
    } as ClientMetadata;
}

/**
 * Sign up with "generated password", because Cognito requires it,
 *   even for passwordless sign up
 */
 export const useSignUp: (handlers?: MutationHandlers) => MutationResultPair<ISignUpResult, Error, { email: string }, unknown> = (handlers) =>
    useMutation(async ({ email }) => {
        email = email.trim()
        console.log(`[portal.api.user.signUp] Creating account for ${email}...`);
        const deviceData = getClientMetadata();
        return await Auth.signUp({
            username: email,
            password: getRandomString(30),
            clientMetadata: deviceData
        })
    }, {
    onSuccess: async () => {
        await queryCache.invalidateQueries('user')
        handlers && handlers.onSuccess && handlers.onSuccess();
    },
    onError: (error: Error) => {
        handlers && handlers.onError && handlers.onError(error);
    }
});

/**
 * Signing in step 1:
 * Initiate the authentication and start the custom flow
 * (This must be triggered after sign up to verify email)
 */
 export const useLogIn: (handlers?: MutationHandlers) => MutationResultPair<CognitoUser, Error, { email: string }, unknown> = (handlers) =>
    useMutation(async ({ email }) => {
        email = email.trim()
        console.log(`[portal.api.user.logIn] Logging into account for ${email}...`);
        const deviceData = getClientMetadata();
        return await Auth.signIn(
            email,
            undefined,    // Password
            deviceData
        ) as CognitoUser; // Track authentication flow state in return CognitoUser object
    }, {
    onSuccess: async () => {
        await queryCache.invalidateQueries('user')
        handlers && handlers.onSuccess && handlers.onSuccess();
    },
    onError: (error: Error) => {
        handlers && handlers.onError && handlers.onError(error);
    }
});


/**
 * Signing in step 2:
 * User checks their mail and retrieve the secret login code to enter
 * (This is the answer to the custom challenge)
 */
 export const useAnswerCustomChallenge: (handlers?: MutationHandlers) => MutationResultPair<CognitoUser, Error, { email: string, cognitoUser: CognitoUser | undefined, code: string }, unknown> = (handlers) =>
    useMutation(async ({ email, cognitoUser, code }) => {
        email = email.trim()
        console.log(`[portal.api.user.answerCustomChallenge] Answering custom challenge for ${email}...`);

        const deviceData = getClientMetadata();

        // Send the answer to the User Pool - This will throw an error if it’s the 3rd wrong answer
        const challengeUser = await Auth.sendCustomChallengeAnswer(cognitoUser, code, deviceData) as CognitoUser;
        
        // If 1st or 2nd wrong answer, no sign in session has been created, so throw an error
        if (!challengeUser.getSignInUserSession()) 
            throw new Error('Incorrect challenge response - user is not authenticated');

        return challengeUser;

    }, {
    onSuccess: async () => {
        await queryCache.invalidateQueries('user')
        handlers && handlers.onSuccess && handlers.onSuccess();
    },
    onError: (error) => {
        handlers && handlers.onError && handlers.onError(error);
    }
});

/**
 * Signing in step 3:
 * If we get here, the answer was sent successfully, but it might have been wrong (1st or 2nd time),
 * So we should test if the user is authenticated now
 */
 export const useVerifyUserAuthentication: (handlers?: MutationHandlers) => MutationResultPair<CognitoUser, Error, unknown, unknown> = (handlers) =>
    useMutation(async () => {
        console.log(`[portal.api.user.useVerifyUserAuthentication] Verifying user is authenticated...`);

        // This will throw an error if the user is not yet authenticated
        return await Auth.currentAuthenticatedUser() as CognitoUser; // May also use Auth.currentSession() here to get token data

    }, {
    onSuccess: async () => {
        await queryCache.invalidateQueries('user')
        handlers && handlers.onSuccess && handlers.onSuccess();
    },
    onError: (error) => {
        handlers && handlers.onError && handlers.onError(error);
    }
});


/**
 * Helpers
 */

/**
 * Handle token refresh before we reach expiry (set minutes before expiry)
 **   cognitoUser.refreshSession(...)
 **   Function implementation: https://github.com/aws-amplify/amplify-js/blob/1bd6c35c115321d77f48a3954942dd57d7cf9056/packages/amazon-cognito-identity-js/src/CognitoUser.js#L1457
 */
export const useRefreshToken = (): void => {
    const location = useLocation();
    useEffect(() => { // Runs on route change
      void refreshCognitoSession();
    }, [location]);
}

export async function refreshCognitoSession(minutesBeforeExpiry = 30): Promise<void> {

    try {
        const cognitoUser = await Auth.currentAuthenticatedUser() as CognitoUser;
        const cognitoUserSession = cognitoUser.getSignInUserSession();
        const refreshToken = cognitoUserSession?.getRefreshToken();

        // Assumes tokens are valid -- invalid tokens are handled at the app level
        const shouldRefresh = await shouldRefreshTokenByInterval(minutesBeforeExpiry);
        if (shouldRefresh && refreshToken) {
            // refreshSession() caches tokens on user session based on the result
            // https://github.com/aws-amplify/amplify-js/blob/main/packages/amazon-cognito-identity-js/src/CognitoUser.js#L1457
            cognitoUser.refreshSession(refreshToken, (err: Error) => {
                if (err) {
                    console.log(err);
                    void Auth.signOut(); // Refresh failed - sign out the user
                }
                // Refresh succeeded... do nothing
            });
        }
    } catch (error: unknown) {
        console.log(error);
        Sentry.captureException(error);
    }
 }

 /**
 * Compare token expiry to refresh time interval
 */
const shouldRefreshTokenByInterval = async (minutesBeforeExpiry: number): Promise<boolean> => {

    // Current times in milliseconds
    const nowMs: number = Math.floor(new Date().getTime());

    const session = await Auth.currentSession();
    const accessTokenExpiryMs = session.getAccessToken().getExpiration() * 1000;

    const refreshIntervalMs = minutesBeforeExpiry * 60000; // Convert min to ms
    const refreshTimeMs = accessTokenExpiryMs - refreshIntervalMs;

    // Assumes tokens are valid -- invalid tokens are handled at the app level
    return nowMs >= refreshTimeMs;
}