import React, { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react'
import { Form, Space, Tooltip, Typography, Select, Input, Button, App } from "antd";
import { PageHeader } from "@ant-design/pro-components"
import { ColumnsType } from "antd/lib/table";
import { useParams } from 'react-router-dom';
import { useChecklists, Protocol, useAddProtocol, useUpdateProtocol, ProtocolChecklist } from '../../api';
import SortableTable, { DragHandle } from '../DragDropTable';
import { EditOutlined, MinusCircleOutlined } from '@ant-design/icons'
import { v4 as uuidv4 } from 'uuid'

import styles from './styles.module.css'
import { color } from '../../theme';

const { Text } = Typography

type ProtocolBuilderProps = {
  protocol?: Protocol,
  action: 'create' | 'clone' | 'edit', //ProtocolBuilderActions
  setIsVisible: Dispatch<SetStateAction<boolean>>
}

type TableDisplay = ProtocolChecklist & { key: string }

export const ProtocolBuilder: React.FC<ProtocolBuilderProps> = ({ protocol, action, setIsVisible }) => {
  const { organizationId, studyId } = useParams<{ organizationId: string, studyId: string }>();
  const { data: checklists } = useChecklists(organizationId, studyId)
  const [protocolForm] = Form.useForm();
  const [protocolName, setProtocolName] = useState<string>(protocol?.name ?? "New Protocol");
  const [protocolChecklists, setProtocolChecklists] = useState<TableDisplay[]>([])
  const { message: messageApi } = App.useApp();

  useEffect(() => {
    // Map out unique checklists for sorting
    if (protocol?.checklists) {
      setProtocolChecklists(protocol.checklists.map(checklist => ({ ...checklist, key: uuidv4() })));
    }
  }, [])

  const removeChecklist = (key: string) => {
    setProtocolChecklists(protocolChecklists.filter(checklist => checklist.key != key))
  }

  const [addProtocol] = useAddProtocol({
    onSuccess: () => {
      protocolForm.resetFields();
      setIsVisible(false);
      void messageApi.success('Protocol was successfully added');
    },
    onError: () => {
      void messageApi.error('Failed to add protocol');
    }
  })

  const [updateProtocol] = useUpdateProtocol({
    onSuccess: () => {
      protocolForm.resetFields();
      setIsVisible(false);
      void messageApi.success('Protocol was successfully updated');
    },
    onError: () => {
      void messageApi.error('Failed to update protocol');
    }
  })

  const addChecklist = ({ id, name }: { id: string, name: string }) => {
    setProtocolChecklists(prev => [ ...prev, { id, name, key: uuidv4() } ])
  }

  //Form handlers
  const submitAndUpdateProtocol = useCallback(() => {
    if (!protocol?.id) return
    void updateProtocol({ organizationId, studyId, protocolId: protocol.id, name: protocolName, checklists: protocolChecklists.map(({ id, name }) => ({ id, name })) })
  }, [protocolName, protocolChecklists])
  
  const submitAndAddProtocol = useCallback(() => {
    void addProtocol({ organizationId, studyId, name: protocolName, checklists: protocolChecklists.map(({ id, name }) => ({ id, name })) })
  }, [protocolName, protocolChecklists])

  const columns: ColumnsType<TableDisplay> = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: "20%",
      render: function name(name) {
        return (
          <div style={{ width: "100%" }}>
            <Space direction='horizontal'>
              <DragHandle />
              <Text>{name}</Text>
            </Space>
          </div> 
        )
      }
    },
    {
      title: "Checklist",
      dataIndex: "id",
      key: "id",
      width: "60%",
      render: function id(id) {
        const checklist = checklists?.find(checklist => checklist.id === id);
        return checklist && <Text>{checklist?.name}</Text>
      }
    },
    {
      title: "Actions",
      dataIndex: "id",
      key: "id",
      width: "20%",
      align: "right",
      render: function actions(_, row) {
        return (
          <div style={{ width: "100%" }}>
            <Space>
              <Tooltip title='Remove checklist' placement="top">
                <MinusCircleOutlined style={{ color: color.red }} onClick={() => removeChecklist(row.key)} />
              </Tooltip>
            </Space>
          </div>
        )
      }
    }
  ]

  return (
    <PageHeader>
      <Space direction='horizontal' align='start' className={styles.Builder}>
        <Space direction='vertical'>
          <Text editable={{ onChange: setProtocolName, icon: <EditOutlined style={{ fontSize: "24px", color: color.blue }} /> }} style={{ fontWeight: 600, fontSize: "24px" }}>
            {protocolName}
          </Text>
          {(action === 'clone' && protocolName === protocol?.name) && <Text>Please rename protocol before saving.</Text>}
        </Space>
        <Space size={16}>
        <Button onClick={() => setIsVisible(false)} style={{ width: "111px" }}>Cancel</Button>
          <Button type="primary" style={{ width: "111px" }} disabled={action === 'clone' && protocolName === protocol?.name} onClick={() => {
            if (action === 'edit' && protocol) submitAndUpdateProtocol()
            else submitAndAddProtocol()
          }}>
            {action === 'edit' ? 'Update' : 'Submit'}
          </Button>
        </Space>
      </Space>
      <Space direction='horizontal' size='large' align='start' className={styles.ProtocolBuilderContent}>
        <Form
          className={styles.ProtocolBuilder}
          name="add-checklist"
          form={protocolForm}
          onFinish={addChecklist}
          validateMessages={{ required: 'Checklist is required' }}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
        >
          <Form.Item
            label="Name"
            name="name"
            colon={false}
            className={`${styles.FormItem} w-full`}
            rules={[
              { required: true }
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Checklist"
            name="id"
            colon={false}
            className={`${styles.FormItem} w-full`}
            rules={[
              { required: true }
            ]}>
            <Select placeholder='Select a checklist'>
              {
                checklists?.map(checklist => <Select.Option key={checklist.id} value={checklist.id}>{checklist.name}</Select.Option>)
              }
            </Select>
          </Form.Item>
          <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            <Button htmlType='submit' type="primary" block>
              Add checklist
            </Button>
          </Form.Item>
        </Form>
        <SortableTable data={protocolChecklists} setData={setProtocolChecklists} columns={columns} getSortKey={i => i.key} rowKey="key" />
      </Space>
    </PageHeader>
  )
}