import { Alert, Card, Col, ConfigProvider, Layout, Row, Space, Statistic, Tag, Typography } from "antd"
import moment from "moment"
import React from "react"
import { useLocation } from "react-router-dom";
import { KernelLogoPlain } from "../../components/Navigation";
import { ThunderboltOutlined, QuestionCircleOutlined, PlayCircleOutlined, PauseCircleOutlined, CompassOutlined, AimOutlined } from "@ant-design/icons"
import { useWebSocket } from "react-use-websocket/dist/lib/use-websocket";
import { ReadyState } from "react-use-websocket";
import { token } from "../../theme";
import { FaultInformation } from "../Flow";

const { Text, Title } = Typography

const DanubeStateMap: Record<string, string> = {
  booting: "Booting",
  sleeping: "Sleeping",
  awake: "Ready for Initialization",
  ready: "Lasers Off",
  data_gathering: "Lasers On",
  faulted: "Faulted",
};

const DataStreamSubscribe: React.FC = () => {
  const location = useLocation();
  const query = new URLSearchParams(location.search)
  const socketUrl = query.get("websocketUrl")
  const { lastJsonMessage, readyState, sendJsonMessage } = useWebSocket(socketUrl, {
    onOpen: () => {
      sendJsonMessage({ initialize: true })
    }
  })
  const lastJson = (lastJsonMessage || {}) as Record<string, string | number>
  let taskUrn = "unknown";
  if (lastJson["task_experiment_meta"]) {
    try {
      const parsed = JSON.parse(lastJson["task_experiment_meta"] as string) as Record<string, string>
      if (parsed["task_urn"]) taskUrn = parsed["task_urn"].replace("urn:kernel.com/task/", "")
    } catch {
      taskUrn = "unknown"
    } 
  }

  let faultsAt: number | undefined;
  if (lastJson["danube_faults"]) {
    const faults = lastJson["danube_faults"] as unknown as FaultInformation;
    if (faults.hub.length > 0 || Object.keys(faults.followers).length > 0 || Object.keys(faults.modules).length > 0) {
      faultsAt = lastJson["danube_faults_at"] as unknown as number;
    }
  }

  return (
    <ConfigProvider
      theme={{
        token: {
        },
      }}
    >
      <Layout className='layout'>
        <Layout.Header className='site-layout-header'>
          <Row>
            <Col span={12} style={{ display: "flex" }}>
              <Space>
                <KernelLogoPlain />
              </Space>
            </Col>
            <Col span={12} style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
              {readyState == ReadyState.OPEN ?
                <Tag icon={<ThunderboltOutlined />} color="success">Connected</Tag> :
                <Tag icon={<QuestionCircleOutlined />} color="error">Disconnected</Tag>
              }
            </Col>
          </Row>
        </Layout.Header>
        <Layout.Content style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'center' }}>
          <Space direction="vertical" size="large" style={{ margin: 40 }}>
            <Title level={3}>Remote Monitoring</Title>
            {faultsAt && <Alert type="error" showIcon message={`Device has faults: ${moment.unix(faultsAt).fromNow()}`} />}
            <Space direction="horizontal" size="large">
              <Card bordered={false}>
                {
                  lastJson["writer_state"] === "writing" ?
                  <Statistic title="Recording" prefix={<PlayCircleOutlined style={{ color: token.colorInfo }} />} value={lastJson["writer_state_at"] ? moment.unix(lastJson["writer_state_at"] as number || 0).fromNow() : "N/A"} /> :
                  <Statistic title="Not Recording" prefix={<PauseCircleOutlined style={{ color: token.colorError }} />} value={lastJson["writer_state_at"] ? moment.unix(lastJson["writer_state_at"] as number || 0).fromNow(): "N/A"} />
                }
              </Card>
              <Card bordered={false}>
                <Statistic title={`Headset State: ${DanubeStateMap[lastJson["danube_state"]] || "unknown"}`} prefix={<CompassOutlined style={{ color: token.colorInfo }} />} value={lastJson["danube_state_at"] ? moment.unix(lastJson["danube_state_at"] as number || 0).fromNow(): "N/A"} />
              </Card>
            </Space>
            <Space direction="horizontal" size="large">
              <Card bordered={false}>
                {
                  lastJson["task_experiment_state"] === "start" ?
                  <Statistic title={`Task Started: ${taskUrn}`} prefix={<AimOutlined style={{ color: token.colorInfo }} />} value={lastJson["task_experiment_state_at"] ? moment.unix(lastJson["task_experiment_state_at"] as number || 0).fromNow() : "N/A"} /> :
                  <Statistic title="Task Ended" prefix={<AimOutlined style={{ color: token.colorError }} />} value={lastJson["task_experiment_state_at"] ? moment.unix(lastJson["task_experiment_state_at"] as number || 0).fromNow(): "N/A"} />
                }
              </Card>
              <Card bordered={false}>
                <Statistic title={`Task Event`} prefix={<AimOutlined style={{ color: token.colorInfo }} />} value={lastJson["task_event_at"] ? moment.unix(lastJson["task_event_at"] as number || 0).fromNow(): "N/A"} />
              </Card>
            </Space>
            <Space direction="horizontal" size="large">
              <Card bordered={false}>
                <Statistic title={`Block Number`} prefix={<AimOutlined style={{ color: token.colorInfo }} />} value={lastJson["task_experiment_block"]} />
              </Card>
              <Card bordered={false}>
                <Statistic title={`Trial Number`} prefix={<AimOutlined style={{ color: token.colorInfo }} />} value={lastJson["task_experiment_trial"]} />
              </Card>
            </Space>
          </Space>
        </Layout.Content>
        <Layout.Footer style={{ textAlign: 'center', paddingTop: 10, paddingBottom: 10 }}><Text type="secondary">©{moment().format('YYYY')} kernel</Text></Layout.Footer>
      </Layout>
    </ConfigProvider>
  )
}


export default DataStreamSubscribe