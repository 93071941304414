import React, { FC, PropsWithChildren } from 'react'
import { SelectedOrganizationContextProvider } from './SelectedOrganization'
import { SelectedStudyContextProvider } from './SelectedStudy'

// wrap all Portal providers in the proper order for a single import/use in App.tsx
export const PortalProviders: FC<PropsWithChildren<unknown>> = ({ children }) => {
  return (
    <SelectedOrganizationContextProvider>
      <SelectedStudyContextProvider>
        {children}
      </SelectedStudyContextProvider>
    </SelectedOrganizationContextProvider>
  )
}
