import React, { useState } from 'react'
import { useStudyMembers, useStudyAvailableMembers, useAddStudyMember, useDeleteStudyMember, StudyMember, useUpdateStudyMember } from '../../api'
import { Typography, Space, List, Tooltip, App, Popconfirm, Modal, Card, Button, Alert } from 'antd'
import { useParams } from "react-router-dom";
import { PlusCircleOutlined, DeleteOutlined, ExperimentOutlined, InfoOutlined, CheckCircleFilled } from '@ant-design/icons'
import { useSelectedStudyContext } from '../../contexts/SelectedStudy'
import { Loader, LoadingIndicator } from '../Loader'
import styles from './styles.module.css'
import { color } from '../../theme';

const { Text, Title, Paragraph } = Typography

const AddStudyMemberModalHeader = () => (
  <>
    <Title>Add Researcher to Study</Title>
    <Paragraph>Adding an organization researcher to a study allows them to view study data and participants.</Paragraph>
  </>
)

const AddStudyMemberModal: React.FC<{ isOpen: boolean, setIsOpen: React.Dispatch<React.SetStateAction<boolean>> }> = ({ isOpen, setIsOpen }) => {
  const { organizationId, studyId } = useParams<{ organizationId: string, studyId: string }>();
  const { data: availableMembers, isLoading: availableMembersLoading, isError: availableMembersError } = useStudyAvailableMembers(organizationId, studyId)
  const { message: messageApi } = App.useApp();
  const [addStudyMember, { isLoading: addStudyMemberLoading }] = useAddStudyMember({
    onError: () => {
      void messageApi.error('Failed to add researcher to study')
    },
    onSuccess: () => {
      void messageApi.success('Successfully added researcher to study')
    }
  })

  return (
    <Modal
      open={isOpen}
      maskClosable={true}
      title={<AddStudyMemberModalHeader />}
      footer={null}
      onCancel={() => setIsOpen(false)}
    >
      <List
        {...availableMembersError && {
          header: <Alert type="error" showIcon message="Error loading available researchers" />,
        }}
        {...availableMembersLoading && {
          loading: {
            indicator: <LoadingIndicator />
          }
        }}
        dataSource={availableMembers}
        renderItem={({ user_id, email }) => (
          <List.Item key={user_id}>
            <Text>{email}</Text>
            {(availableMembersLoading || addStudyMemberLoading) ?
              <Loader size="small" /> :
              <PlusCircleOutlined style={{ color: color.blue }} onClick={() => addStudyMember({ organizationId, studyId, user_id })} />
            }
          </List.Item>
        )}
      />
    </Modal>
  )
}

const AddStudyMember: React.FC = () => {
  const { study } = useSelectedStudyContext()
  const [isOpen, setIsOpen] = useState<boolean>(false)
  
  if (!study?._permissions?.can_add_member) {
    return <></>;
  }

  return (
    <>
      <AddStudyMemberModal isOpen={isOpen} setIsOpen={setIsOpen} />
      <Space>
        <Tooltip title={'Add Study Researchers'} placement="left">
          <Button type="primary" onClick={setIsOpen.bind(null, !isOpen)} className={styles.AddStudyMemberButton}>Add Researcher</Button>
        </Tooltip>
      </Space>
    </>
  )
}

const RemoveMember: React.FC<StudyMember> = ({ user_id, email, _permissions: { can_remove } }) => {
  const { organizationId, studyId } = useParams<{ organizationId: string, studyId: string }>()
  const { study } = useSelectedStudyContext()
  const { message: messageApi } = App.useApp();
  const [deleteStudyMember, { isLoading: deleteStudyMemberIsLoading }] = useDeleteStudyMember({
    onError: () => {
      void messageApi.error('Failed to remove researcher from study')
    },
    onSuccess: () => {
      void messageApi.success('Successfully removed researcher from study')
    }
  })

  if (!can_remove) {
    return <></>
  }

  return (
    <>
      <Popconfirm
        title={() => <Text>Are you sure you want to remove <strong>{email}</strong> from <strong>{study?.name}</strong>?</Text>}
        onConfirm={() => void deleteStudyMember({ organizationId, studyId, memberUserId: user_id })}
        arrow={{ pointAtCenter: true }}
      >
        <Tooltip title='Remove Researcher' placement="bottomRight" arrow={{ pointAtCenter: true }}>
          {deleteStudyMemberIsLoading ? <Loader size="small" /> : <DeleteOutlined style={{ color: color.red }} />}
        </Tooltip>
      </Popconfirm>
    </>
  )
}

const UpdateMemberAdmin: React.FC<Pick<StudyMember, 'user_id' | 'is_study_admin' | 'email'> & { organizationId: string, studyId: string }> = ({ email, user_id, is_study_admin, organizationId, studyId }) => {
  const { message: messageApi } = App.useApp();
  const [updateStudyMember] = useUpdateStudyMember({
    onError: () => {
      void messageApi.error('Failed to update admin status')
    },
    onSuccess: () => {
      void messageApi.success('Successfully updated admin status')
    }
  })

  if (is_study_admin) {
    return (
      <>
        <Popconfirm
          title={() => <Text>Are you sure you want to <strong>demote {email}</strong>? They will no longer be able to perform admin actions.</Text>}
          onConfirm={() => void updateStudyMember({ organizationId, studyId, memberUserId: user_id, admin: false })}
          placement={'bottomLeft'}
          arrow={{ pointAtCenter: true }}
        >
          <Tooltip title="Demote Admin to Researcher" placement="left">
            <ExperimentOutlined style={{ color: color.gold }} />
          </Tooltip>
        </Popconfirm>
      </>
    )
  } else {
    return (
      <Popconfirm
        title={() => <Text>Are you sure you want to <strong>promote {email}</strong> to <strong>admin status</strong>? They will be able to perform admin only actions.</Text>}
        onConfirm={() => void updateStudyMember({ organizationId, studyId, memberUserId: user_id, admin: true })}
        placement={'bottomLeft'}
        arrow={{ pointAtCenter: true }}
      >
        <Tooltip title="Promote Researcher to Admin" placement="left">
          <ExperimentOutlined style={{ color: `${color.gold}40` }} />
        </Tooltip>
      </Popconfirm>
    )
  }
}

const MemberRowActions: React.FC<{ organizationId: string, studyId: string, member: StudyMember }> = ({ organizationId, studyId, member }) => {
  return (
    <Space key="admin-row-actions" size="small">
      {member.is_org_admin ? (
        <Tooltip title="Organization Admin" placement="left">
          <ExperimentOutlined style={{ color: color.yellow }} />
        </Tooltip>
      ) : (
        member._permissions.can_update_is_admin ? (
          <UpdateMemberAdmin {...member} organizationId={organizationId} studyId={studyId} />
        ) : member.is_study_admin ? (
          <Tooltip title="Study Admin" placement="left">
            <ExperimentOutlined style={{ color: color.gold }} />
          </Tooltip>
        ) : <></>
      )}
      {member?._permissions?.can_remove && <RemoveMember {...member} />}
    </Space>
  )
}

export const Members: React.FC = () => {
  const { organizationId, studyId } = useParams<{ organizationId: string, studyId: string }>();
  const { isLoading: membersLoading, isError: membersError, data: members } = useStudyMembers(organizationId, studyId);
  const { modal } = App.useApp()
  
  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <Space style={{ justifyContent: 'space-between', width: '100%', padding: '12px 0' }}>
        <Space direction="vertical">
          <Space>
            <Title level={3} style={{ margin: 0 }}>Researchers</Title>
            <Tooltip title="Help: Study Roles">
              <Button shape="circle" size="small" icon={<InfoOutlined />} onClick={() => (
                modal.info({
                  title: 'Help: Study Roles',
                  width: 800,
                  content: (
                    <table className="permissions">
                      <thead>
                        <tr>
                          <th>Permission</th>
                          <th>Organization Admin</th>
                          <th>Study Admin</th>
                          <th>Study Member</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Create Studies</td>
                          <td><CheckCircleFilled /></td>
                          <td></td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>Close Studies</td>
                          <td><CheckCircleFilled /></td>
                          <td></td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>Modify Study Details</td>
                          <td><CheckCircleFilled /></td>
                          <td><CheckCircleFilled /></td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>Manage Study Admins</td>
                          <td><CheckCircleFilled /></td>
                          <td></td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>Manage Study Members</td>
                          <td><CheckCircleFilled /></td>
                          <td></td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>Manage Participants</td>
                          <td><CheckCircleFilled /></td>
                          <td><CheckCircleFilled /></td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>Manage Surveys</td>
                          <td><CheckCircleFilled /></td>
                          <td><CheckCircleFilled /></td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>Allow Retaking Surveys</td>
                          <td><CheckCircleFilled /></td>
                          <td><CheckCircleFilled /></td>
                          <td><CheckCircleFilled /></td>
                        </tr>
                      </tbody>
                    </table>
                  ),
                })
              )} />
            </Tooltip>
          </Space>


          {membersError && <Alert type="error" showIcon message="Error loading researchers!" />}
        </Space>
        <AddStudyMember />
      </Space>

      <List
        {...membersLoading && {
          loading: {
            indicator: <LoadingIndicator />
          }
        }}
        dataSource={members}
        pagination={{ simple: true, pageSize: 9, hideOnSinglePage: true }}
        size="small"
        grid={{ gutter: 16, column: 3 }}
        renderItem={member => (
          <List.Item key={member.email} style={{ padding: 0, marginBottom: 16 }}>
            <Card>
              <Space style={{ justifyContent: "space-between", width: "100%" }}>
                <Text copyable>{member.email}</Text>
                <MemberRowActions organizationId={organizationId} studyId={studyId} member={member} />
              </Space>
            </Card>
          </List.Item>
        )}
      />
    </Space>
  )
}
