import React from 'react'

const StopClickPropagation: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <div onClick={event => event.stopPropagation()}>
      {children}
    </div>
  )
}

export default StopClickPropagation
