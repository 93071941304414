import React, { useEffect, useState } from 'react'
import { Study, useUpdateStudyImage } from '../../api'
import { Button, Space, Tooltip, Typography, Upload, Image, App } from 'antd'
import { LoadingOutlined, CameraOutlined, DeleteOutlined } from '@ant-design/icons'
import styles from './styles.module.css'
import { captureException } from '@sentry/react'

const { Text } = Typography

type CoverPhotoProps = {
  study: Study
  image: string
  organizationId: string
  studyId: string
}

const CoverPhoto: React.FC<CoverPhotoProps> = ({ image, organizationId, studyId }) => {
  const [photo, setPhoto] = useState<string>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { message: messageApi } = App.useApp();

  const [updateImage, { isLoading: imageLoading }] = useUpdateStudyImage({
    onSuccess: () => {
      void messageApi.success(`Image ${photo ? 'deleted' : 'uploaded'}.`)
      void fetchImage(image);
    },
    onError: () => {
      void messageApi.error(`Unable to ${photo ? 'delete' : 'update'} image.`)
    }
  })

  const fetchImage = async (image: string) => {
    try {
      const response = await fetch(image);
      response.ok ? setPhoto(image) : setPhoto('')
      setIsLoading(false);
    } catch (err) {
      captureException(err);
      setIsLoading(false);
      setPhoto('');
    }
  }

  useEffect(() => {
    void fetchImage(image);
  }, [])

  if (isLoading) {
    return (
      <div onClick={e => e.stopPropagation()} >
        <LoadingOutlined style={{ fontSize: 50 }} />
      </div>
    )
  }

  return (
    photo ? (
      <Space size="large">
        <Image
          src={photo}
          preview={false}
          alt='Study Cover Photo'
          onError={({ currentTarget }) => {
            currentTarget.onerror = null;
            setPhoto('');
          }}
          className={styles.coverPhoto}
        />
        <Tooltip title="Remove cover photo">
          <Button
            danger
            shape='circle'
            icon={<DeleteOutlined />}
            className={styles.deleteButton}
            onClick={(e => {
              e.stopPropagation();
              void updateImage({ organizationId, studyId, image: '' });
            })}
          />
        </Tooltip>
      </Space>
  ) : (
    <div onClick={e => e.stopPropagation()} className={imageLoading ? "" : styles.uploadPhotoButton} >
      <Upload
        showUploadList={false}
        beforeUpload={file => {
          if (file) void updateImage({ organizationId, studyId, image: file })
          return false
        }}
        accept='.png'
        listType='picture'
        className={imageLoading ? styles.uploadPhotoLoader : ""}>
        {imageLoading ? <LoadingOutlined style={{ fontSize: 50 }} /> : (
          <Space direction='vertical' align='center' className={`${styles.studyCard} ${styles.studyCardHoverable}`}>
            <CameraOutlined style={{ fontSize: 50 }} />
            <Text>Upload a cover image</Text>
          </Space>
        )}
      </Upload>
    </div>
  ))
}

export default CoverPhoto