import { Button, Modal, Typography, Space, Tabs } from "antd";
import React from "react";
import createPersistedState from 'use-persisted-state'

const { Title, Paragraph } = Typography;

const useReleaseNoteState = createPersistedState('release-notes-2024-09-01');

const Coupling: React.FC = () => (
  <Space direction="vertical" size="small">
    <Title level={5} style={{ marginTop: 10 }}>Flow UI: Coupling</Title>
    <Paragraph>When you enter the Flow UI, you will see that some of the text has been removed from the top of the screen and replaced with 4 images on the left panel. These images serve as a reminder of how to properly place the headset (white triangle centered between the eyes, bottom rim of the front resting on the eyebrows, headset not tilted).</Paragraph>
    <Paragraph>The coupling map color scale has been reduced from 10 shades of blue to 5 shades. The map might appear slightly more &quot;blue&quot; than you are used to, but the percentage score (which is now on the right) remains the same.</Paragraph>
    <Paragraph>Instead of &quot;Tune Lasers,&quot; the button has been relabeled as &quot;Continue.&quot; When you click continue, the lasers will tune.</Paragraph>
  </Space>
)

const Recording: React.FC = () => (
  <Space direction="vertical" size="small">
    <Title level={5} style={{ marginTop: 10 }}>Flow UI: Recording</Title>
    <Paragraph>You will also notice that the metadata boxes, &quot;Start Recording&quot; and &quot;Launch Task&quot; buttons have been removed from the initial screen on which you perform coupling adjustments. Instead, these are on their own screen that appears after the lasers tune. Note that you will no longer be able to see the coupling map at this point, unless you turn the lasers off and start the process over. If you need to make adjustments to the headset, please turn the lasers off and then back on again to see the coupling map once more.</Paragraph>
  </Space>
)

const ReleaseNotes : React.FC = () => {
  const [seen, setSeen] = useReleaseNoteState(false)

  return (
    <Modal open={!seen} title={<Title level={3} style={{ marginBottom: 20 }}>What&apos;s New: September 1, 2024</Title>} width={800} closable={false} footer={[
      <Button key="submit" type="primary" onClick={() => setSeen(true)}>
        Ok
      </Button>,
    ]}>
      <Tabs tabPosition="left" size="large" items={[
        { key: "coupling", label: "Flow UI: Coupling", children: <Coupling /> },
        { key: "recording", label: "Flow UI: Recording", children: <Recording /> },
      ]} />
    </Modal>
  )
}

export default ReleaseNotes