import React from 'react';
import { useParams } from 'react-router-dom';
import {
  useStudyReports,
  useCreateStudyReport,
  useDeleteStudyReport,
  useParticipant,
} from '../../api'
import {
  Alert,
  App,
  Button,
  Divider,
  Empty,
  List,
  Space,
  Spin,
  Tooltip,
  Typography,
  Upload,
} from 'antd'
import { DeleteOutlined } from '@ant-design/icons'
import { color } from '../../theme';

const {
  Title,
} = Typography

export const StudyParticipantReports: React.FC = () => {
  const { organizationId, studyId, virtualUserId } = useParams<{ organizationId: string, studyId: string, virtualUserId: string }>();
  const { data: participant } = useParticipant(organizationId, studyId, virtualUserId)
  const { data: reports, isLoading: reportsLoading, isError: reportsError } = useStudyReports(organizationId, studyId, virtualUserId);
  const { message: messageApi } = App.useApp();

  const [ createStudyReport, { isLoading: isCreateStudyReportLoading } ] = useCreateStudyReport({
    onError: () => {
      void messageApi.error('Failed to deliver report');
    },
    onSuccess: () => {
      void messageApi.success('Report delivered')
    }
  })


  const [ deleteStudyReport, { isLoading:  isDeleteStudyReportLoading } ] = useDeleteStudyReport({
    onError: () => {
      void messageApi.error('Failed to delete report');
    },
    onSuccess: () => {
      void messageApi.success('Report deleted')
    }
  })

  return (
    <Space direction="vertical">
      <Title level={3} style={{ marginTop: 0 }}>Delivered Reports</Title>
      {
        reportsLoading ? <Spin /> :
        reportsError ? <Alert type="error" showIcon message="Error loading reports" /> :
        !reports?.reports ? <Empty /> :
        <List
          bordered
          dataSource={Object.entries(reports.reports)}
          renderItem={([name, url]) => (
            <List.Item actions={participant?._permissions.can_manage_study_reports ? [
              <Tooltip key="remove" title="Remove Report" arrow={{ pointAtCenter: true }} placement="bottomRight">
                <DeleteOutlined disabled={isDeleteStudyReportLoading} style={{ color: color.red}} onClick={() => deleteStudyReport({ organizationId, studyId, virtualUserId, document: name })} />
              </Tooltip>
            ] : []}>
              <Space>
                <a href={url} target="_blank" rel="noreferrer">{name}</a>
              </Space>
            </List.Item>
          )}
        />
      }
      <Divider />
      {participant?._permissions.can_manage_study_reports && (
        <Upload
          disabled={isCreateStudyReportLoading}
          accept=".pdf,.html,.md,.png,.jpg"
          showUploadList={false}
          beforeUpload={file => {
            if (file) {
              void createStudyReport({ organizationId, studyId, virtualUserId, document: file.name, file })
            }
            return false
          }}
        >
          <Button type="primary" loading={isCreateStudyReportLoading} disabled={isCreateStudyReportLoading}>
            Deliver New Report
          </Button>
        </Upload>
      )}
    </Space>
  )
} 
