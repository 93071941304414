import React, { ReactNode, useState } from 'react'
import { DataStreamSurvey, DataStreamSurveyStepQuestionType, isMultipleQuestionType } from '../../api'
import { Typography, Input, Space, Modal, Descriptions, Slider, Button, DatePicker, Checkbox } from 'antd'
import MarkdownPreview from '@uiw/react-markdown-preview';

const { Title } = Typography

export const SurveyPreview: React.FC<{ name: string, survey: DataStreamSurvey, textButton?: boolean } > = ({ name, survey, textButton = false }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  
  return (
    <>
      <Button type={textButton ? "text" : undefined} onClick={() => setIsModalVisible(true)}>View Preview</Button>
      <Modal zIndex={1031} title={name} open={isModalVisible} style={{zIndex: 1031}} closable={false} width={600} footer={[
        <Button key="submit" onClick={() => setIsModalVisible(false)}>
          Ok
        </Button>
      ]}>
        <Descriptions column={1} bordered layout="vertical" style={{ maxHeight: 600, overflowY: 'scroll' }}>
          {survey.steps.map(step => step.questions.map(question => (
            <Descriptions.Item key={question.id} label={
              <>
                <Title level={5}>{question.label}</Title>
                {question.title && <MarkdownPreview source={question.title} />}
              </>
            }>
              <Space direction="vertical">
                {
                  question.type === DataStreamSurveyStepQuestionType.Input ? (
                    <Input />
                  ) : question.type === DataStreamSurveyStepQuestionType.MultipleSelect ? (
                    question.choices?.map(choice => (
                      <Space key={choice.value} direction="horizontal">
                        <Input type="checkbox" style={{ width: "auto" }} name={question.id} />
                        {choice.label}
                      </Space>
                    ))
                  ) : isMultipleQuestionType(question.type) ? (
                    question.choices?.map(choice => (
                      <Space key={choice.value} direction="horizontal">
                        <Input type="radio" style={{ width: "auto" }} name={question.id} />
                        {choice.label}
                      </Space>
                    ))
                  ) : question.type === DataStreamSurveyStepQuestionType.Number ? (
                    <Input type="number" />
                  ) : question.type === DataStreamSurveyStepQuestionType.Time ? (
                    <DatePicker showTime />
                  ) : question.type === DataStreamSurveyStepQuestionType.Day ? (
                    <DatePicker />
                  ) : question.type === DataStreamSurveyStepQuestionType.Month ? (
                    <DatePicker picker="month" />
                  ) : question.type === DataStreamSurveyStepQuestionType.Year ? (
                    <DatePicker picker="year" />
                  ) : question.type === DataStreamSurveyStepQuestionType.Range ? (
                    <Slider
                      min={question.range?.minimumValue}
                      max={question.range?.maximumValue}
                      step={question.range?.step}
                      marks={(question.range?.ticks || []).reduce((a, c) => {
                          if (question.range && question.range.minimumValue && question.range.maximumValue && question.range.minimumValue <= c && c <= question.range.maximumValue) {
                              a[c] = <>|</>
                          }
                          return a;
                      }, {} as Record<number, ReactNode>)}
                      style={{ width: 600 }}
                    />
                  ) :<></>
                }
                {!survey.questionsRequired && question.type !== DataStreamSurveyStepQuestionType.Instruction && (
                  <Space direction="horizontal">
                    <Checkbox
                      style={{ marginTop: 20, fontSize: 14 }}
                    >
                      Prefer Not to Answer
                    </Checkbox>
                  </Space>
                )}
              </Space>
            </Descriptions.Item>
          )))}
        </Descriptions>
      </Modal>
    </>
  )
}