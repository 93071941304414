import React from "react"
import { Button, Space } from "antd"
import { generatePath, useHistory, useParams } from "react-router-dom"
import { useKortexVersion } from "../../api"
import { UserAddOutlined, UnorderedListOutlined, ThunderboltOutlined }from "@ant-design/icons"

const Menu: React.FC = () => {
    const history = useHistory()
    const { studyId  } = useParams<{ studyId: string }>()
    const { data: kortexVersion } = useKortexVersion()

    return (
        <Space direction="vertical" size="large" style={{ minWidth: 400 }}>
            <Button size="large" block style={{ height: 100 }} icon={<UserAddOutlined />} onClick={() => history.push(generatePath('/clinic/:studyId/register', { studyId }))}>
                Register a Participant
            </Button>
            <Button size="large" block style={{ height: 100 }} icon={<UnorderedListOutlined />} onClick={() => history.push(generatePath('/clinic/:studyId/visit', { studyId }))}>
                Complete a Study Visit
            </Button>
            {kortexVersion && (
                <Button size="large" block style={{ height: 100 }} icon={<ThunderboltOutlined />} onClick={() => history.push(generatePath('/clinic/:studyId/flow', { studyId }))}>
                    Flow Start-Up
                </Button>
            )}
        </Space>
    )
}

export default Menu