import * as Sentry from "@sentry/react";
import { v4 as uuidv4 } from 'uuid';
import { Auth } from '@aws-amplify/auth'
import { Hub } from '@aws-amplify/core'

export const downloadFileUrl: (url: string) => void = url => {
  const a = document.createElement('a')
  a.href = url
  a.click()
  a.remove()
}

export function downloadJson<T>(obj: T, fileName: string): void {
  const a = document.createElement('a');
  const dataStr = "data:application/json;charset=utf-8," + encodeURIComponent(JSON.stringify(obj));
  a.href = dataStr;
  a.download = fileName + '.json'
  a.click();
  a.remove();
}

type ChaliceError = {
  Message?: string
}

const getAuthToken: () => Promise<string | undefined> = async () => {
  if (process.env.REACT_APP_PORTAL_LOCAL) {
    return process.env.REACT_APP_PORTAL_LOCAL;
  }
  try {
    const authSession = await Auth.currentSession();
    const idToken = authSession.getIdToken().getJwtToken();
    return idToken;
  } catch (error) {
    if (error !== 'No current user') {
      Sentry.captureException(error)
    }
  }
}

const extraHeaders: Record<string, string> = process.env.REACT_APP_API_HEADERS ? JSON.parse(process.env.REACT_APP_API_HEADERS) as Record<string, string> : {};

export const prepareHeaders: (headers?: Record<string, string>) => Promise<Headers> = async (headers = {}) => {
  const kernelRequestUuid = uuidv4()

  Sentry.addBreadcrumb({
    category: "fetch",
    message: `'x-kernel-request': ${kernelRequestUuid}`,
    level: "info",
  })

  const idToken = await getAuthToken();

  return new Headers({
    ...headers,
    ...extraHeaders,
    ...(idToken && { Authorization: idToken }),
    'x-kernel-request': kernelRequestUuid
  })
}

class FetchError extends Error {
  constructor(message: string) {
    super(message);
    this.name = "FetchError";
  }
}

/**
 * The replacement of the global fetch which includes 2 additional features:
 * 1. `throw` on not ok (non-2xx)
 * 2. Send exceptions to Sentry
 */
export const fetchAndRaise: (identifier: string, input: RequestInfo, init?: RequestInit) => Promise<Response> = async (identifier, input, init) => {
  const response = await fetch(input, init)

  if (!response.ok) {
    const error = await response.json() as ChaliceError;
    const errorMessage = JSON.stringify(error)
    Sentry.captureException(new FetchError(errorMessage), {
      extra: {
        identifier,
        url: response.url,
        status: response.status,
        method: init?.method,
        body: init?.body,
      }
    })

    if (response.status === 403 || response.status === 401) {
      console.log(`[portal - fetchAndRaise] Status ${response.status}. Redirecting to 404 page`)
      // Hub may generate a dispatch console warning, but the documentation allows for auth dispatch implementation
      // https://docs.amplify.aws/lib/utilities/hub/q/platform/js/
      Hub.dispatch(
        'auth', 
        { 
            event: 'insufficientPermissions', 
            data: {},
        }
      );
    }

    const portalError = new Error(error.Message || "Server Error!")
    portalError.status = response.status
    throw portalError
  }

  return response
}

const AMAZON_HEADER_KEYS = "x-amz-server-side-encryption";

export const uploadToS3 = async (response: Response, file: File): Promise<void> => {
  const data = await response.json() as { url: string, fields: Record<string, string> };

  const formData  = new FormData();
  Object.keys(data.fields).filter(k => !k.startsWith(AMAZON_HEADER_KEYS)).forEach(k => formData.append(k, data.fields[k]))
  formData.append("file", file)

  await fetchAndRaise('portal.upload-to-s3', data.url, {
    method: 'POST',
    body: formData,
    headers: {
      ...Object.keys(data.fields).filter(k => k.startsWith(AMAZON_HEADER_KEYS)).reduce((a, k) => ({ ...a, k: data.fields[k] }), {})
    }
  })
}

// copy html to clipboard while preserving formatting (paste in gmail will
// render matching format to html copied)
export const copyHtmlToClipboard = (html: string): void => {
  const listener = (event: Event) => {
    event.clipboardData.setData("text/html", html)
    event.preventDefault()
  }

  document.addEventListener("copy", listener)
  document.execCommand("copy")
  document.removeEventListener("copy", listener)
};

/**
 * Get a random string, such as to generate a password
 */
 export function getRandomString(bytes: number) : string {
  const randomValues = new Uint8Array(bytes);
  window.crypto.getRandomValues(randomValues);
  return Array.from(randomValues).map(intToHex).join('') + getRandomCapitalLetter();
}

/**
 * Convert an integer to hexidecimal
 */
export function intToHex(nr: number) : string {
  return nr.toString(16).padStart(2, '0');
}

/**
 * Get a random capital letter from A-Z
 */
export function getRandomCapitalLetter() : string {
  return String.fromCharCode(65 + Math.floor(Math.random() * 26))
}

/**
 * Log to console and sentry
 */
export const handleLog = (category: string, message: string, err?: unknown): void => {
  const log = `[${category}] ${message}`
  if (err) {
    console.error(log, err)
  } else {
    console.info(log)
  }
}

export const isEmptyValue: <T>(value: T) => boolean = value => {
  return (
    value === undefined ||
    value === null ||
    (typeof value === "number" && isNaN(value)) ||
    (typeof value === "object" && Object.keys(value).length === 0) ||
    (typeof value === "string" && value.length === 0)
  )
}

export const redirectPortalToResearch = (url: string) => url.replace('https://portal.', 'https://research.')