import { Button, Col, ConfigProvider, Empty, Layout, Row, Space, Spin, Tag, Tooltip, Typography } from "antd"
import moment from "moment"
import React, { useContext, useEffect, useState } from "react"
import { Route, Switch, useHistory, useParams, useRouteMatch, useLocation, generatePath } from "react-router-dom";
import { useClinic } from "../../api";
import { KernelLogoPlain, LogoSeparator } from "../../components/Navigation";
import NotFound from "../NotFound";
import Auth from "./Auth";
import Checklist from "./Checklist";
import Menu from "./Menu";
import Register from "./Register";
import Survey from "./Survey";
import Visit from "./Visit";
import { LockOutlined, UnorderedListOutlined } from "@ant-design/icons"
import ClinicFlow from "./ClinicFlow";
import { ClinicRecordNavigationState } from "../../components/Flow/Record";

const { Text, Title } = Typography

export const ClinicAuthTokenContext = React.createContext<{
    token: string,
    setToken: (newToken: string) => void,
    lockedToParticipant: boolean,
    lockToParticipant: () => void,
    unlockFromParticipant: (path: string) => void,
    flowUIDisableBackButton: boolean,
    setFlowUIDisableBackButton: (value: boolean) => void,
}>({
    token: "",
    setToken: () => {}, // eslint-disable-line @typescript-eslint/no-empty-function
    lockedToParticipant: false,
    lockToParticipant: () => {}, // eslint-disable-line @typescript-eslint/no-empty-function
    unlockFromParticipant: () => {}, // eslint-disable-line @typescript-eslint/no-empty-function
    flowUIDisableBackButton: false,
    setFlowUIDisableBackButton: () => {}, // eslint-disable-line @typescript-eslint/no-empty-function
})

const SecureRedirect: React.FC = () => {
    const clinicAuthTokenContext = useContext(ClinicAuthTokenContext);

    useEffect(() => {
        clinicAuthTokenContext.lockToParticipant?.()
    }, [])

    return <></>
}

const Clinic: React.FC = () => {
    const { path } = useRouteMatch()
    const { studyId } = useParams<{ studyId: string }>();
    const [ clinicAuthToken, setClinicAuthToken ] = useState<string>("")
    const [ lockedToParticipant, setLockedToParticipant ] = useState<boolean>(false)
    const [ flowUIDisableBackButton, setFlowUIDisableBackButton ] = useState<boolean>(false)
    const { data: clinicData, isLoading: clinicLoading } = useClinic(studyId)
    const history = useHistory()
    const location = useLocation()

    const isFlowUI = location.pathname.endsWith("/flow");
    const flowUIState: ClinicRecordNavigationState | undefined = location.state as ClinicRecordNavigationState | undefined;
    return (
        <ConfigProvider
            theme={{
                token: {
                    fontSize: 16,
                },
            }}
        >
            <ClinicAuthTokenContext.Provider value={{
                token: clinicAuthToken,
                setToken: setClinicAuthToken,
                lockedToParticipant,
                lockToParticipant: () => setLockedToParticipant(true),
                unlockFromParticipant: (path) => {
                    history.push(path)
                    setLockedToParticipant(false)
                },
                setFlowUIDisableBackButton,
                flowUIDisableBackButton,
            }}>
                <Layout className='layout'>
                    <Layout.Header className='site-layout-header'>
                        <Row>
                            <Col span={12} style={{ display: "flex" }}>
                                <Space>
                                    <KernelLogoPlain />
                                    <LogoSeparator />
                                    <Title level={4} style={{ margin: 0, marginLeft: 15, fontFamily: 'Museo' }}>{clinicData?.name}</Title>
                                </Space>
                            </Col>
                            <Col span={12} style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                                {lockedToParticipant && <Tag icon={<LockOutlined />} color="warning">Participant Mode</Tag>}
                                {isFlowUI && clinicAuthToken && (
                                    <Tooltip title={flowUIState?.checklistLogId && flowUIDisableBackButton ? "Please Stop Recording and Turn Lasers Off before going back to the visit" : undefined}>
                                        <Button
                                            disabled={!!flowUIState?.checklistLogId && flowUIDisableBackButton}
                                            icon={<UnorderedListOutlined />}
                                            onClick={() => history.push(
                                                flowUIState?.checklistLogId ?
                                                generatePath("/clinic/:studyId/checklist/:checklistLogId", { studyId, checklistLogId: flowUIState.checklistLogId }) :
                                                generatePath("/clinic/:studyId", { studyId })
                                            )}
                                        >
                                            {flowUIState?.checklistLogId ? 'Back to Visit' : 'Back to Menu'}
                                        </Button>
                                    </Tooltip>
                                )}
                            </Col>
                        </Row>
                    </Layout.Header>
                    <Layout.Content style={isFlowUI && clinicAuthToken ? {} : { display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        {clinicLoading ? <Spin size="large" /> :
                        !clinicData ? <Empty description="Clinic Not Found" /> :
                        !clinicAuthToken ? <Auth /> :
                        lockedToParticipant ? (
                            <Switch>
                                <Route path={`${path}/checklist/:checklistId/survey/:stepId/user`} exact={true}>
                                    <Survey />
                                </Route>
                                <Route>
                                    <NotFound />
                                </Route>
                            </Switch>
                        ) : (
                            <Switch>
                                <Route path={`${path}/register`} exact={true}>
                                    <Register />
                                </Route>
                                <Route path={`${path}/visit`} exact={true}>
                                    <Visit />
                                </Route>
                                <Route path={`${path}/flow`} exact={true}>
                                    <ConfigProvider
                                        theme={{
                                            token: {
                                                fontSize: 14,
                                            },
                                        }}
                                    >
                                        <ClinicFlow />
                                    </ConfigProvider>
                                </Route>
                                <Route path={`${path}/checklist/:checklistId`} exact={true}>
                                    <Checklist />
                                </Route>
                                <Route path={`${path}/checklist/:checklistId/survey/:stepId/user`} exact={true}>
                                    <SecureRedirect />
                                </Route>
                                <Route path={`${path}/checklist/:checklistId/survey/:stepId`} exact={true}>
                                    <Survey />
                                </Route>
                                <Route path={path} exact={true}>
                                    <Menu />
                                </Route>
                                <Route>
                                    <NotFound />
                                </Route>
                            </Switch>
                        )}
                    </Layout.Content>
                    <Layout.Footer style={{ textAlign: 'center', paddingTop: 10, paddingBottom: 10 }}><Text type="secondary">©{moment().format('YYYY')} kernel</Text></Layout.Footer>
                </Layout>
            </ClinicAuthTokenContext.Provider>
        </ConfigProvider>
    )
}


export default Clinic