import React, { useCallback, useState } from 'react'
import { useOrganizationMembers, useRemoveOrganizationMember, useAddOrganizationMember, OrganizationMember, useUpdateOrganizationMember, useOrganizationMemberInvites, useRemoveOrganizationMemberInvite } from '../../api'
import { useParams } from "react-router-dom";
import TooltipButton from '../TooltipButton'
import { Typography, Popconfirm, Tooltip, Space, Input, Form, Table, Button, Spin, Empty, List, Alert, App } from 'antd'
import { DeleteOutlined, PlusCircleOutlined, ExperimentOutlined } from '@ant-design/icons'
import { InfoOutlined, CheckCircleFilled } from '@ant-design/icons'
import { ColumnsType } from 'antd/lib/table'
import { LoadingIndicator } from '../Loader'
import { color } from '../../theme';
import { useSelectedOrganizationContext } from '../../contexts/SelectedOrganization';
 
const { Title, Text } = Typography

const MembersRowActions: React.FC<OrganizationMember> = ({ user_id, email, _permissions }) => {
  const { organizationId } = useParams<{ organizationId: string, studyId: string }>()
  const { message: messageApi } = App.useApp();

  const [removeOrganizationMember, {
    isLoading: removeMemberLoading
  }] = useRemoveOrganizationMember({
    onError: () => {
      void messageApi.error('Failed to remove researcher')
    },
    onSuccess: () => {
      void messageApi.success('Researcher removed')
    }
  });

  return (
    <Space>
      <Text copyable={{ text: user_id }} />
      {_permissions.can_remove && <Popconfirm
        disabled={removeMemberLoading}
        title={() => <Text>Are you sure you want to <strong>remove {email}</strong> from this organization?</Text>}
        onConfirm={() => removeOrganizationMember({ organizationId, memberUserId: user_id })}
        placement={'left'}
        arrow={{ pointAtCenter: true }}
      >
        <Tooltip title="Remove Researcher" arrow={{ pointAtCenter: true }} placement="bottomRight">
          <DeleteOutlined style={{ color: `${color.red}${removeMemberLoading ? '80' : ''}`}} disabled={removeMemberLoading} />
        </Tooltip>
      </Popconfirm>}
    </Space>
  )
}

const AddOrganizationMember: React.FC = () => {
  const { organizationId } = useParams<{ organizationId: string }>();
  const { organization } = useSelectedOrganizationContext()
  const [form] = Form.useForm()
  const { message: messageApi } = App.useApp();
  const [addOrganizationMember] = useAddOrganizationMember({
    onError: (error) => {
      if (error?.status === 409) {
        void messageApi.error('Researcher already exists')
        return
      }

      void messageApi.error('Failed to add researcher')
    },
    onSuccess: () => {
      void messageApi.success('Researcher added')
      form.resetFields()
    }
  });

  const addOrganizationMemberFormHandler = useCallback(() => {
    void addOrganizationMember({
      organizationId,
      email: form.getFieldValue('email') as string
    })
  }, [form, addOrganizationMember, organizationId])

  const [value, setValue] = useState<string>()

  if (!organization?._permissions.can_add_member) {
    return <></>;
  }

  return (
    <Form
      name="add-organization-member"
      layout={'inline'}
      form={form}
      onFinish={addOrganizationMemberFormHandler}
      size="small"
    >
      <Form.Item label="Add Email" name="email" required={true}>
        <Input type="email" onChange={({ target }) => setValue(target.value) }/>
      </Form.Item>
      <Form.Item style={{ marginRight: 0, width: 'unset' }}>
        <TooltipButton buttonProps={{ htmlType: "submit", disabled: !value, style: { border: 'none', background: 'none', padding: '0', width: 'unset' }, icon: <PlusCircleOutlined style={{ color: `${color.blue}${!value ? '40' : ''}` }} /> }} tooltipProps={{ title: !value ? 'You must enter an email' : 'Add Researcher', placement: 'bottomRight' }} />
      </Form.Item>
    </Form>
  )
}

// allow current user (if owner) to update admin status of member
const UpdateMemberAdmin: React.FC<Pick<OrganizationMember, 'user_id' | 'is_admin' | 'email'> & { organizationId: string }> = ({ email, user_id, is_admin, organizationId }) => {
  const { message: messageApi } = App.useApp();
  const [updateOrganizationMember] = useUpdateOrganizationMember({
    onError: () => {
      void messageApi.error('Failed to update admin status')
    },
    onSuccess: () => {
      void messageApi.success('Successfully updated admin status')
    }
  })

  if (is_admin) {
    return (
      <>
        <Popconfirm
          title={() => <Text>Are you sure you want to <strong>demote {email}</strong>? They will no longer be able to perform admin actions.</Text>}
          onConfirm={() => void updateOrganizationMember({ organizationId, memberUserId: user_id, admin: false })}
          placement={'bottomLeft'}
          arrow={{ pointAtCenter: true }}
        >
          <Tooltip title="Demote Admin to Researcher" placement="right">
            <ExperimentOutlined style={{ color: color.gold }} />
          </Tooltip>
        </Popconfirm>
      </>
    )
  } else {
    return (
      <Popconfirm
        title={() => <Text>Are you sure you want to <strong>promote {email}</strong> to <strong>admin status</strong>? They will be able to perform admin only actions.</Text>}
        onConfirm={() => void updateOrganizationMember({ organizationId, memberUserId: user_id, admin: true })}
        placement={'bottomLeft'}
        arrow={{ pointAtCenter: true }}
      >
        <Tooltip title="Promote Researcher to Admin" placement="right">
          <ExperimentOutlined style={{ color: `${color.gold}40` }} />
        </Tooltip>
      </Popconfirm>
    )
  }
}

export const Members: React.FC = () => {
  const { organizationId } = useParams<{ organizationId: string }>();
  const { organization } = useSelectedOrganizationContext()
  const { data: members, isLoading: membersLoading, error: membersError } = useOrganizationMembers(organizationId);
  const { data: memberInvites, isLoading: memberInvitesLoading, error: memberInvitesError } = useOrganizationMemberInvites(organizationId);
  const { message: messageApi, modal } = App.useApp();
  const [removeMemberInvite] = useRemoveOrganizationMemberInvite({
    onError: () => {
      void messageApi.error('Failed to remove invite')
    },
    onSuccess: () => {
      void messageApi.success('Invite removed')
    }
  });

  const columns: ColumnsType<OrganizationMember> = [
    {
      title: <Space>
        Admin
        <Tooltip title="Help: Organization Roles">
          <Button shape="circle" size="small" icon={<InfoOutlined />} onClick={() => (
             modal.info({
              title: 'Help: Organization Roles',
              width: 800,
              content: (
                <table className="permissions">
                  <thead>
                    <tr>
                      <th>Permission</th>
                      <th>Organization Owner</th>
                      <th>Organization Admin</th>
                      <th>Organization Member</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Manage Organization Admins</td>
                      <td><CheckCircleFilled /></td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr>
                      <td>Manage Organization Members</td>
                      <td><CheckCircleFilled /></td>
                      <td><CheckCircleFilled /></td>
                      <td></td>
                    </tr>
                    <tr>
                      <td>Create Studies</td>
                      <td><CheckCircleFilled /></td>
                      <td><CheckCircleFilled /></td>
                      <td></td>
                    </tr>
                    <tr>
                      <td>Close Studies</td>
                      <td><CheckCircleFilled /></td>
                      <td><CheckCircleFilled /></td>
                      <td></td>
                    </tr>
                    <tr>
                      <td>Manage Study Admins</td>
                      <td><CheckCircleFilled /></td>
                      <td><CheckCircleFilled /></td>
                      <td></td>
                    </tr>
                    <tr>
                      <td>Manage Study Members</td>
                      <td><CheckCircleFilled /></td>
                      <td><CheckCircleFilled /></td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
              ),
            })
          )} />
        </Tooltip>
      </Space>,
      dataIndex: 'is_admin',
      key: 'is_admin',
      render: function Admin(text, { email, is_owner, is_admin, user_id, _permissions }) {
        if (_permissions.can_update_is_admin) {
          return <UpdateMemberAdmin user_id={user_id} is_admin={is_admin} email={email} organizationId={organizationId} />
        }

        if (!is_admin) return null

        return (
          <Tooltip title={is_owner ? "Owner" : "Admin"} placement="right">
            <ExperimentOutlined style={{ color: is_owner ? color.yellow : color.gold }} />
          </Tooltip>
        )
      }
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      width: '100%',
      render: function Email(text) {
        return <Text style={{ width: '100%' }}>{text}</Text>
      },
      sorter: (a, b) => a.email.localeCompare(b.email)
    },
    {
      title: 'Actions',
      key: 'actions',
      render: function Actions(text, member) {
        return <MembersRowActions {...member} />
      }
    }
  ];

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <Space style={{ justifyContent: 'space-between', width: '100%', padding: '12px 0' }}>
        <Space direction="vertical">
          <Title level={5} style={{ margin: 0 }}>Researchers</Title>
          {membersError && <Alert type="error" showIcon message="Error loading researchers" />}
        </Space>
        <AddOrganizationMember />
      </Space>

      <Table
        pagination={{ simple: true, pageSize: 10 }}
        loading={membersLoading}
        {...membersLoading && {
          loading: {
            indicator: <LoadingIndicator />
          }
        }}
        dataSource={members}
        columns={columns}
        bordered={false}
        rowKey="user_id"
      />

      <Title level={5} style={{ margin: 0 }}>Invites</Title>
      {
        memberInvitesLoading ? <Spin /> :
        memberInvitesError ? <Alert type="error" showIcon message="Error loading invites" /> :
        !memberInvites ? <Empty /> :
        <List
          bordered
          dataSource={memberInvites}
          renderItem={item => (
            <List.Item actions={organization?._permissions?.can_add_member ? [
              <Tooltip key="remove" title="Remove Invite" arrow={{ pointAtCenter: true }} placement="bottomRight">
                <DeleteOutlined style={{ color: color.red }} onClick={() => removeMemberInvite({ organizationId, email: item })} />
              </Tooltip>
            ] : []}>
              {item}
            </List.Item>
          )}
        />
      }
    </Space>
  )
}
