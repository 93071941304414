import React from 'react'
import { Button, Tooltip } from 'antd'
import { ButtonProps } from 'antd/lib/button'
import { TooltipProps } from 'antd/lib/tooltip'

type TooltipButtonProps = {
  buttonProps?: ButtonProps,
  tooltipProps?: TooltipProps,
  children?: React.ReactNode,
}

const TooltipButton: React.FC<TooltipButtonProps> = ({ tooltipProps, buttonProps, children }) => {
  if (tooltipProps) {
    return (
      <Tooltip {...tooltipProps}>
        <Button {...buttonProps}>{children}</Button>
      </Tooltip>
    )
  }

  return <Button {...buttonProps}>{children}</Button>
}

export default TooltipButton
