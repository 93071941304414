import React, { useEffect, useState } from 'react'
import { App, Progress } from 'antd'
import { CloudDownloadOutlined } from '@ant-design/icons'
import { downloadFileUrl } from '../utilities'
import { useDownloadData, useCheckDownloadStatus, StudySessionsFilter } from '../api'
import { color } from '../theme'

type FileDownloadProps = {
  url: string
  filter?: StudySessionsFilter,
  disabled?: boolean,
  small?: boolean
}

const cloudDownloadOutlinedStyle = (disabled: boolean, small?: boolean) => ({
  color: `${color.blue}${disabled ? '40' : ''}`,
  ...!small && { fontSize: 20 }
})

// a click-to-download component with long-polling
const FileDownload: React.FC<FileDownloadProps> = ({ url, filter, disabled, small }) => {
  const [progress, setProgress] = useState(0);
  const { message } = App.useApp()
  const { data: download, isLoading: downloadLoading, refetch: getDownloadId } = useDownloadData(url, filter, () => void message.error('Failed to get download ID'))
  const { data: downloadUrl, refetch: pollForUrl } = useCheckDownloadStatus(
    download?.id,
    (progress) => setProgress(progress ? Math.ceil(100.0 * progress) : 0),
    () => void message.error('Failed to get download status'),
  )
  
  // start polling when we have download id
  useEffect(() => {
    if (download?.id) {
      void pollForUrl()
    }
  }, [download?.id])

  // auto-download when long-polling ends
  useEffect(() => {
    if (downloadUrl) {
      downloadFileUrl(downloadUrl)
    }
  }, [downloadUrl])

  return (
    // if have download URL, can click to download
    downloadUrl ? (
      <a {...!disabled && { href: downloadUrl, download: true }}>
        <CloudDownloadOutlined style={cloudDownloadOutlinedStyle(!!disabled, small)} size={20} />
      </a>
    ) :
    // "loading" if no url but have download id (we're long-polling) or loading download id
    (download?.id || downloadLoading) ? (
      <Progress percent={progress} type="circle" width={40} />
    // if no url and no download, allow user to kick-off
    ) : (
      <CloudDownloadOutlined style={cloudDownloadOutlinedStyle(!!disabled, small)} {...!disabled && { onClick: () => void getDownloadId() }} />
    )
  )
}

export default FileDownload
