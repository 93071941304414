import React, { useEffect, useRef, useState } from 'react';
import Amplify, { Auth } from '@aws-amplify/auth';
import { Hub } from '@aws-amplify/core'
import * as Sentry from '@sentry/react';
import moment from 'moment';
import {
  Switch,
  Route,
  Redirect,
  generatePath,
  useLocation,
  useHistory
} from 'react-router-dom';
import { API_VERSION, apiVersion, user as useUser } from './api';
import Profile from './pages/Profile';
import OAuth from './pages/OAuth';
import { Organizations } from './components/Organizations';
import { Studies } from './components/OrganizationStudies';
import FilteredSessions from './components/FilteredSessions';
import Navigation from './components/Navigation';
import { Alert, Button, Layout, Typography } from 'antd';
import './App.less';
import { PortalProviders } from './contexts'
import { StudyTabs } from './contexts/SelectedStudy'
import { Members as Researchers } from './components/StudyResearchers'
import { Tasks } from './components/StudyTasks';
import { Participants } from './components/StudyParticipants';
import { StudyDetail } from './components/StudyDetail';
import { StudySurveys } from './components/StudySurveys';
import { StudyProtocols } from "./components/StudyProtocols"
import { Loader } from './components/Loader';
import ReleaseNotes from './components/ReleaseNotes';
import { StudyFlowUI } from './pages/Flow';
import NotFound from './pages/NotFound'
import Register from './pages/Register'
import { RedoOutlined } from '@ant-design/icons'
import {
  AuthenticationState,
  PasswordlessAuthApp,
} from './PasswordlessAuthApp';
import { useRefreshToken, useVerifyUserAuthentication } from './api/account';
import AnalysisPipelineStatusType from './components/AnalysisPipelineStatus';
import OrganizationSettings from './components/OrganizationSettings';

Amplify.configure({
  Auth: {
    region: process.env.REACT_APP_AWS_AMPLIFY_CONFIG_AUTH_REGION,
    userPoolId: process.env.REACT_APP_AWS_AMPLIFY_CONFIG_AUTH_USER_POOL_ID,
    userPoolWebClientId:
      process.env.REACT_APP_AWS_AMPLIFY_CONFIG_AUTH_USER_POOL_WEB_CLIENT_ID,
  },
  Analytics: {
    disabled: true,
  },
});

const { Text } = Typography

// hide the navigation under certain routing conditions
const ConditionalNavigation = () => {
  const location = useLocation()

  if (location.pathname.startsWith("/oauth")) {
    return null
  }

  return <Navigation />
}

/**
 * authenticated App
 */  
function App() {
  const { data: version } = apiVersion();
  const { data: user } = useUser()
  useEffect(() => {
    if (user) {
      Sentry.setUser({
        sub: user.attributes.sub
      })
    }
  }, [user])

  const location = useLocation();
  useEffect(() => {
    if (process.env.REACT_APP_GOOGLE_ANALYTICS === '1' && window.gtag) {
      // https://developers.google.com/analytics/devguides/collection/gtagjs/single-page-applications
      window.gtag('set', 'page_path', location.pathname)
      window.gtag('event', 'page_view')
    }
  }, [location.pathname])
  
  const alertUserOfVersionMismatch = API_VERSION < (version?.version || 0)

  return (
    <PortalProviders>
      <Layout className='layout'>
        <Layout.Header className='site-layout-header'>
          <ConditionalNavigation />
        </Layout.Header>

        <Layout.Content style={{ padding: '0 50px' }}>
          {alertUserOfVersionMismatch && (
            <Alert
              closable
              showIcon
              type='warning'
              message='Portal has a new version available. Please refresh the page to update.'
              action={
                <Button type="default" icon={<RedoOutlined />} onClick={() => window.location.reload()}>
                  Update
                </Button>
              }
              style={{ marginBottom: 20, marginTop: 20 }}
            />
          )}
          <div className="site-layout-content">
            <Switch>
              <Route path='/oauth' exact={true}>
                <OAuth />
              </Route>

              <Route path='/organizations' exact={true}>
                <Organizations />
              </Route>

              <Route path='/register' exact={true}>
                <Register />
              </Route>

              <Route path='/organizations/:organizationId' exact={true}>
                <OrganizationSettings />
              </Route>

              <Route path='/organizations/:organizationId/studies' exact={true}>
                <Studies />
              </Route>

              <Route path='/profile' exact={true}>
                <Profile />
              </Route>

              <Route
                path='/organizations/:organizationId/studies/:studyId'
                exact={true}
                render={({ match }: { match: { path: string, params: { organizationId: string, studyId: string }} }) => (
                <Redirect to={generatePath('/organizations/:organizationId/studies/:studyId/:studyTab', { organizationId: match.params.organizationId, studyId: match.params.studyId, studyTab: 'datasets' })}/>
                )}
              />

              <Route path='/organizations/:organizationId/studies/:studyId/datasets'>
                <StudyTabs />
                <FilteredSessions />
              </Route>
              <Route path='/organizations/:organizationId/studies/:studyId/flow'>
                <StudyTabs />
                <StudyFlowUI />
              </Route>
              <Route
                path='/organizations/:organizationId/studies/:studyId/details'
                exact={true}
                render={({ match }: { match: { params: { organizationId: string, studyId: string, activeTab?: string }} }) => (
                  <Redirect to={generatePath('/organizations/:organizationId/studies/:studyId/details/:activeTab', { organizationId: match.params.organizationId, studyId: match.params.studyId, activeTab: match.params.activeTab ?? "enrollmentSettings" })}/>
                )}
              />
              <Route path='/organizations/:organizationId/studies/:studyId/details/:activeTab'>
                <StudyTabs />
                <StudyDetail />
              </Route>
              <Route path='/organizations/:organizationId/studies/:studyId/surveys' exact={true}>
                <StudyTabs />
                <StudySurveys />
              </Route>
              <Route path='/organizations/:organizationId/studies/:studyId/tasks' exact={true}>
                <StudyTabs />
                <Tasks />
              </Route>
              <Route path='/organizations/:organizationId/studies/:studyId/analysis'>
                <StudyTabs />
                <AnalysisPipelineStatusType />
              </Route>
              <Route path='/organizations/:organizationId/studies/:studyId/participants'>
                <StudyTabs />
                <Participants />
              </Route>
              <Route path='/organizations/:organizationId/studies/:studyId/researchers' exact={true}>
                <StudyTabs />
                <Researchers />
              </Route>
              <Route exact={true} path='/organizations/:organizationId/studies/:studyId/protocols'>
                <StudyTabs />
                <StudyProtocols />
              </Route>
              <Route path='/404' exact={true}>
                <NotFound />
              </Route>
              <Route path={'/'} exact={true}>
                <Redirect to='/organizations' />
              </Route>
              <Route>
                <Redirect to='/404' />
              </Route>
            </Switch>
            <ReleaseNotes />
          </div>
        </Layout.Content>

        <Layout.Footer style={{ textAlign: 'center', paddingTop: 10, paddingBottom: 10 }}><Text type="secondary">©{moment().format('YYYY')} kernel</Text></Layout.Footer>
      </Layout>
    </PortalProviders>
  );
}

/**
 * AppContainer listens to the auth state to return an unauthenticated app or authenticated app
 */

const AppContainer: React.FC = () => {
  const history = useHistory();
    
  const mountedRef = useRef(true);
  useEffect(() => {
    return () => { 
      mountedRef.current = false
    }
  }, []);

  const [authenticationState, setAuthenticationState] = useState<AuthenticationState>(AuthenticationState.Authenticating);
  
  useEffect(() => { 
    // Prevent dismount before async call is finished
    if (mountedRef.current)  {
      void verifyUserAuthentication().then(
        user => {
          if (process.env.REACT_APP_ZENDESK !== '1') return
          if (!user) return
          
          try {
            const email = user.getUsername()
            window.zE('webWidget', 'prefill', {
              email: {
                value: email,
                readOnly: true,
              }
            });
          } catch (e) {
            if (e !== 'No current user') {
              Sentry.captureException(e);
            }
          }
        }
      )
  
      // Listen to sign out event to force app out of profile (using different nav stacks)
      Hub.listen('auth', (data) => {
        switch (data.payload.event) {
          case 'signOut':
          case 'signIn_failure':
          case 'tokenRefresh_failure':
            setAuthenticationState(AuthenticationState.Unauthenticated);
            break;
          case 'insufficientPermissions':
            history.push('/404')
            break;
        }
      });
    }

    return Hub.remove('auth', () => void Auth.signOut());
  }, []);
  
  const [verifyUserAuthentication] = useVerifyUserAuthentication({
    onSuccess: () => {
      setAuthenticationState(AuthenticationState.Authenticated);
      console.log('Successful user verification!');
    },
    onError: (error) => {
      setAuthenticationState(AuthenticationState.Unauthenticated);
      console.log('Error for user verification: ', error);
    }
  });

  // Handle token refresh before we reach expiry (set minutes before expiry), on location change
  useRefreshToken();
  
  if (process.env.REACT_APP_PORTAL_LOCAL) {
    return <App />
  }
  return (
    <>
      {
        authenticationState === AuthenticationState.Authenticating 
          ? <Loader size='large' />
        : authenticationState === AuthenticationState.Unauthenticated
          ? <PasswordlessAuthApp onAuthStateChange={setAuthenticationState} />
        : <App />
      }
    </>
  )

}

export default AppContainer;
