import React, { useCallback, FC, useState } from 'react'
import { Spin, Space, Popover, Badge, Typography, Tag, Empty, App } from 'antd'
import { useHistory, generatePath } from "react-router-dom";
import { BellOutlined } from '@ant-design/icons'
import { OrganizationsDropdown, useSelectedOrganizationContext } from '../../contexts/SelectedOrganization'
import { StudiesDropdown } from '../../contexts/SelectedStudy'
import styles from './styles.module.css'
import { useNotifications, useMarkNotificationsRead, Notification, NotificationType } from '../../api';
import moment from 'moment'

const { Text } = Typography;

export const NAVIGATION_PORTAL_ID = "navigation-portal"

type NavigationProps = {
  organizationId?: string
  organizationName?: string
  studyId?: string
  studyName?: string
  virtualUserId?: string
  participantName?: string
  sessionId?: string
  sessionName?: string
  error?: string | undefined | null
  loading?: boolean
}

export const KernelLogo: FC = () => {
  const history = useHistory()
  const { organization } = useSelectedOrganizationContext()
  
  const handleLogoClick = useCallback(() => {
    if (organization) {
      history.push(generatePath('/organizations/:organizationId/studies', { organizationId: organization?.id }))
    } else {
      history.push('/organizations')
    }
  }, [organization])

  return <img src={`${process.env.PUBLIC_URL}/kernel-logo-white.png`} onClick={handleLogoClick} className={styles.KernelLogo} />
}

export const KernelLogoPlain: FC = () => <img src={`${process.env.PUBLIC_URL}/kernel-logo-white.png`} className={styles.KernelLogo} style={{ cursor: 'default' }} />

const NotificationContainer: React.FC<{ notification: Notification }> = ({ notification }) => {
  const history = useHistory()
  const notificationRedirect: Record<NotificationType, string> = {
    "user_accepted_event" : `/organizations/${notification.source.org_id}/studies/${notification.source.study_id}/participants/${notification.source.virtual_user_id}/scheduling`,
    "user_cancelled_event" : `/organizations/${notification.source.org_id}/studies/${notification.source.study_id}/participants/${notification.source.virtual_user_id}/scheduling`
  }

  return (
    <div key={notification.id} className={`${styles.Notification} ${!notification.read ? styles.Unread : ''}`} style={{display: "flex", padding: "7px"}} 
      onClick={() => notificationRedirect[notification.notification_type] && history.push(generatePath(notificationRedirect[notification.notification_type]))}>
      <Text style={{fontSize: "12px"}}>
        <Tag style={{lineHeight: "18px"}}>{notification.source.study_name}</Tag> {moment.unix(notification.created_at).format('MMM DD LT')}
      </Text>
      <Text style={{marginTop: "8px"}}>
        {notification.message}
      </Text>
    </div>
  )
}

export const LogoSeparator = (): JSX.Element => <div className={styles.LogoSeparator} />

const Navigation: React.FC<NavigationProps> = ({
  loading,
  error,
}: NavigationProps) => {
  const { organization } = useSelectedOrganizationContext()
  const { data: notifications, refetch: refetchNotifications } = useNotifications();
  const { message: messageApi } = App.useApp();
  const [markNotificationsRead] = useMarkNotificationsRead({
    onError: () => {
      void messageApi.error("Failed to mark notifications as read")
    }
  })
  const [batchNotificationRead, setBatchNotificationRead] = useState<boolean>(false);
  const handlePopover = () => {
    if (!batchNotificationRead) {
      setBatchNotificationRead(true)
      void markNotificationsRead()
    } else if (notifications && notifications?.notifications.filter(notification => !notification.read).length > 0) {
      void refetchNotifications();
    }
  }

  return (
    <Space style={{ display: 'flex', justifyContent: 'space-between', height: '54px' }}>
      <Space>
        <KernelLogo />
        <LogoSeparator />
        {organization && <StudiesDropdown />}
        {loading && <Spin size="small"/>}
        {error && <span style={{ color: 'red' }}>{error}</span>}
      </Space>

      <Space>
        <Popover overlayClassName={styles.Popover} style={{padding: 0}} trigger="click" content={
            <Space direction='vertical'>
              { (notifications?.notifications.length ?? 0) > 0 ?
                notifications?.notifications.map(notification => (
                  <NotificationContainer key={notification.id} notification={notification} />
                )) :
                <Empty />
              }
            </Space>
          }>
          <Badge count={!batchNotificationRead ? notifications?.notifications.filter(notification => !notification.read).length : 0} offset={[-10, 0]} size="small"> 
            <BellOutlined style={{ background: 'rgba(255,255,255,0.1)', padding: 10, borderRadius: 4 }} onClick={() => handlePopover()} />
          </Badge>
        </Popover>
        <OrganizationsDropdown />
      </Space>
    </Space>
  )
}

export default Navigation
