import { Table } from 'antd';
import { SortableContainer, SortableContainerProps, SortableElement, SortableHandle } from 'react-sortable-hoc';
import { DragOutlined } from '@ant-design/icons';
import React, { Dispatch, SetStateAction } from 'react';
import { ColumnsType } from 'antd/lib/table';
import styles from './styles.module.css';

export const DragHandle = SortableHandle(() => <DragOutlined style={{ cursor: 'grab', color: '#fff' }} />);

const SortableItem = SortableElement((props: JSX.IntrinsicAttributes & React.ClassAttributes<HTMLTableRowElement> & React.HTMLAttributes<HTMLTableRowElement>) => <tr {...props} />);
const SortableBody = SortableContainer((props: JSX.IntrinsicAttributes & React.ClassAttributes<HTMLTableSectionElement> & React.HTMLAttributes<HTMLTableSectionElement>) => <tbody {...props} />);

function SortableTable<T extends Record<string, unknown>>(props: { data: T[], setData: Dispatch<SetStateAction<T[]>>, columns: ColumnsType<T>, rowKey: string, getSortKey: (item: T) => string }): JSX.Element {
    type SortEndItem = {
        oldIndex: number,
        newIndex: number
    }

  const { data, setData, columns, rowKey } = props
  const onSortEnd = (sortEndItem: SortEndItem) => {
    if (sortEndItem.oldIndex !== sortEndItem.newIndex) {
      const newData = [...data]
      const [item] = newData.splice(sortEndItem.oldIndex, 1)
      newData.splice(sortEndItem.newIndex, 0, item)
      setData(newData.filter(el => !!el));
    }
  };

  const DraggableContainer = (props: JSX.IntrinsicAttributes & JSX.IntrinsicClassAttributes<React.Component<JSX.IntrinsicAttributes & React.ClassAttributes<HTMLTableSectionElement> & React.HTMLAttributes<HTMLTableSectionElement> & SortableContainerProps>> & Readonly<JSX.IntrinsicAttributes & React.ClassAttributes<HTMLTableSectionElement> & React.HTMLAttributes<HTMLTableSectionElement> & SortableContainerProps> & Readonly<{ children?: React.ReactNode; }>): JSX.Element => (
    <SortableBody
      useDragHandle
      disableAutoscroll
      helperClass={styles.rowDragging}
      onSortEnd={onSortEnd}
      {...props}
    />
  );

  type SortItem = {
      className: string,
      style: React.CSSProperties,
      "data-row-key": string 
  }

  const DraggableBodyRow = (sortItem: SortItem) => { 
    // function findIndex base on Table rowKey props and should always be a right array index
    const index: number = data.findIndex((item) => props.getSortKey(item) === sortItem['data-row-key']);
    return <SortableItem index={index} {...sortItem} />;
  };

    return (
      <Table
        pagination={false}
        dataSource={data}
        columns={columns}
        rowKey={rowKey}
        components={{
          body: {
            wrapper: DraggableContainer,
            row: DraggableBodyRow,
          },
        }}
      />
    );
  }

  export default SortableTable