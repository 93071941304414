import { Button, Form, Input, App, Space, Typography } from "antd"
import React, { useContext } from "react"
import { useParams } from "react-router-dom"
import { useClinicAuth } from "../../api"
import { ClinicAuthTokenContext } from "."

const { Title } = Typography

const Auth: React.FC = () => {
    
    const { studyId } = useParams<{ studyId: string }>()
    const clinicAuthTokenContext = useContext(ClinicAuthTokenContext)


    return (
        <Space direction="vertical" size="large">
            <Title level={1} style={{ fontFamily: 'Museo' }}>Welcome</Title>

            <AuthForm
                studyId={studyId}
                label="Enter your clinic password to proceed"
                onToken={(token => clinicAuthTokenContext.setToken?.(token))}
            />
        </Space>
    )
}

export const AuthForm: React.FC<{ studyId: string, label: string, onToken: (token: string) => void }> = ({ studyId, label, onToken }) => {
    const [form] = Form.useForm()
    const { message: messageApi } = App.useApp();

    const [clinicAuth, { isLoading: clinicAuthLoading }] = useClinicAuth({
        onError: () => {
            void messageApi.error('Error authenticating!')
        }}) 

    const submit = async () => {
        const password = form.getFieldValue("password") as string
        const res = await clinicAuth({ studyId, password })
        if (res?.token) {
            onToken(res.token)
        }
    }

    return (
        <Form form={form} autoComplete="off" layout="vertical">
            <Form.Item
            label={label}
            name="password"
            >
                <Input size="large" type="password" autoComplete="off" placeholder="Enter password ..." onPressEnter={submit} />
            </Form.Item>

            <Form.Item>
                <Button size="large" type="primary" block loading={clinicAuthLoading} onClick={submit}>Log In</Button>
            </Form.Item>
        </Form>
    )
}

export default Auth