import React, { useState, Dispatch, SetStateAction } from 'react'
import { useParams } from 'react-router-dom'
import { Space, Typography, List, Popover, Popconfirm, Collapse, Table, Card, Button, Alert, App, Upload } from 'antd'
import {
  useChecklist,
  Protocol,
  useDeleteProtocol,
  useProtocols,
  ProtocolChecklist,
  useProtocolExport,
  useProtocolsImport,
} from '../../api'
import { useSelectedStudyContext } from '../../contexts/SelectedStudy'
import { MoreOutlined } from '@ant-design/icons';
import { LoadingIndicator } from '../Loader'
import { ColumnsType } from 'antd/lib/table'
import { ProtocolBuilder } from '../ProtocolBuilder'
import { Checklists } from '../StudyChecklists'

const { Title, Text } = Typography;
const { Panel } = Collapse;

export type ProtocolBuilderActions = 'create' | 'clone' | 'edit'

export type ProtocolActionsProps = {
  protocol: Protocol,
  setBuildingProtocol: Dispatch<SetStateAction<boolean>>,
  setProtocol: Dispatch<SetStateAction<Protocol | undefined>>,
  setProtocolAction: Dispatch<SetStateAction<ProtocolBuilderActions>>
}

const ProtocolActions: React.FC<ProtocolActionsProps> = ({ protocol, setProtocol, setBuildingProtocol, setProtocolAction }) => {
  const { organizationId, studyId } = useParams<{ organizationId: string, studyId: string }>();
  const { study } = useSelectedStudyContext()
  const { message: messageApi } = App.useApp();

  const [deleteProtocol] = useDeleteProtocol({
    onError: () => {
      void messageApi.error('Failed to remove protocol from study')
    },
    onSuccess: () => {
      void messageApi.success('Successfully removed protocol from study')
    }
  })

  const [protocolExport] = useProtocolExport({
    onError: () => {
      void messageApi.error("Failed to export protocol")
    }
  })

  return (
    <Space style={{ width: "120px" }} direction='vertical'>
      {
        study?._permissions.can_manage_checklist && protocol.can_edit &&
        <Button type="text"
          onClick={() => {
            setProtocol(protocol)
            setProtocolAction('edit')
            setBuildingProtocol(true)
          }}>
          Edit
        </Button>
      }
      {
        study?._permissions.can_manage_checklist &&
        <Button type="text"
          onClick={() => {
            setProtocol(protocol)
            setProtocolAction('clone')
            setBuildingProtocol(true)
          }}>
          Clone and Edit
        </Button>
      }
      {
        study?._permissions.can_manage_checklist && protocol.can_delete &&
        <Popconfirm title="Are you sure you want to remove this protocol?" onConfirm={() => void deleteProtocol({ organizationId, studyId, protocolId: protocol.id })}>
          <Button type="text" danger>Delete</Button>
        </Popconfirm>
      }
      {
        study?._permissions.can_manage_checklist &&
        <Button type="text"
          onClick={() => void protocolExport({ organizationId, studyId, protocolId: protocol.id, protocolName: protocol.name })}>
          Export
        </Button>
      }
    </Space>
  )
}

const ChecklistRow: React.FC<{ id: string }> = ({ id }) => {
  const { organizationId, studyId } = useParams<{ organizationId: string, studyId: string }>();
  const { data: checklist } = useChecklist(organizationId, studyId, id)

  return (
    <div style={{ width: "100%" }}>
      <Space direction='horizontal'>
        <Text>{checklist?.name}</Text>
      </Space>
    </div>
  )
}

const ProtocolPreview: React.FC<{ protocol: Protocol }> = ({ protocol }) => {
  const columns: ColumnsType<ProtocolChecklist> = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Checklist",
      dataIndex: "id",
      key: "id",
      render: function checklist(_, { id }) {
        return <ChecklistRow id={id} />
      }
    }
  ]

  return (
    <Collapse>
      <Panel
        key={protocol.id}
        header={
          <Text>View Preview of {protocol.checklists.length} Checklist{protocol.checklists.length > 1 && 's'}</Text>}>
        <Table size="small" columns={columns} dataSource={protocol.checklists} pagination={false} rowKey="id" />
      </Panel>
    </Collapse>
  )
}

type ProtocolProps = {
  isBuildingProtocol: boolean
  setIsBuildingProtocol: Dispatch<SetStateAction<boolean>>
}

export const Protocols: React.FC<ProtocolProps> = ({ isBuildingProtocol, setIsBuildingProtocol }) => {
  const { organizationId, studyId } = useParams<{ organizationId: string, studyId: string }>();
  const { study } = useSelectedStudyContext()
  const { message: messageApi } = App.useApp();

  const { isLoading: protocolsLoading, isError: protocolsError, data: protocolData } = useProtocols(organizationId, studyId);
  const protocols = protocolData?.protocols

  const [protocol, setProtocol] = useState<Protocol>();
  const [protocolBuilderAction, setProtocolBuilderAction] = useState<"create" | "clone" | "edit">("create")

  const [protocolsImport, { isLoading: protocolsImportLoading }] = useProtocolsImport({
    onSuccess: () => {
      void messageApi.success("Protocol imported successfully!")
    },
    onError: () => {
      void messageApi.error("Failed to import protocol")
    },
  })

  return (
    <>
      {
        isBuildingProtocol && <ProtocolBuilder setIsVisible={setIsBuildingProtocol} protocol={protocol} action={protocolBuilderAction} />
      }
      {
        !isBuildingProtocol &&
        <Space direction="vertical" style={{ width: '100%' }} >
          <Space style={{ justifyContent: 'space-between', width: '100%' }}>
            <Space direction="vertical">
              <Title level={3} style={{ margin: 0 }}>Protocols</Title>
              {protocolsError && <Alert type="error" showIcon message="Error loading protocols" />}
            </Space>
            {study?._permissions.can_manage_checklist && (
              <Space direction="horizontal">
                <Button type="primary"
                  onClick={() => {
                    setProtocol(undefined)
                    setProtocolBuilderAction('create')
                    setIsBuildingProtocol(true)
                  }}>
                  Create Protocol
                </Button>
                <Upload
                  disabled={protocolsImportLoading}
                  accept=".json"
                  showUploadList={false}
                  beforeUpload={file => {
                    if (file) {
                      void protocolsImport({ organizationId, studyId, file });
                    }
                    return false
                  }}
                >
                    <Button type="primary" loading={protocolsImportLoading} disabled={protocolsImportLoading}>
                      Import Protocol
                    </Button>
                </Upload>
              </Space>
            )}
          </Space>

          <List
            dataSource={protocols ?? []}
            pagination={{ simple: true, pageSize: 9, hideOnSinglePage: true }}
            size="small"
            grid={{ gutter: 16, column: 3 }}
            renderItem={protocol => (
              <List.Item key={protocol.id} style={{ padding: 0, marginBottom: 16 }}>
                <Card
                  style={{ minHeight: "170px" }}
                  title={<Text>{protocol.name}</Text>}
                  extra={[
                    <Space key='protocol-actions' size='small'>
                      <Popover placement='bottom' trigger='click' content={<ProtocolActions setBuildingProtocol={setIsBuildingProtocol} setProtocol={setProtocol} setProtocolAction={setProtocolBuilderAction} protocol={protocol} />}>
                        <MoreOutlined />
                      </Popover>
                    </Space>
                  ]}
                >
                  {protocol.checklists.length > 0 && <ProtocolPreview protocol={protocol} />}
                </Card>
              </List.Item>
            )}
            {...protocolsLoading && {
              loading: {
                indicator: <LoadingIndicator />
              }
            }}
          />
        </Space>
      }
    </>
  )
}

export const StudyProtocols: React.FC = () => {
  const [isBuildingChecklist, setIsBuildingChecklist] = useState<boolean>(false);
  const [isBuildingProtocol, setIsBuildingProtocol] = useState<boolean>(false);

  return (
    <>
      {!isBuildingChecklist && <Protocols isBuildingProtocol={isBuildingProtocol} setIsBuildingProtocol={setIsBuildingProtocol} />}
      {!isBuildingProtocol && <Checklists isBuildingChecklist={isBuildingChecklist} setIsBuildingChecklist={setIsBuildingChecklist} />}
    </>
  )
}