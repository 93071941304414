import React from "react"
import { Chart } from "react-google-charts";
import { Empty, Space, Spin } from "antd";
import { useParams } from "react-router-dom";
import { useParticipantSurveyReports } from "../api";

const ParticipantReports: React.FC = () => {
  const { organizationId, studyId } = useParams<{ organizationId: string, studyId: string }>();
  const { data: reports, isLoading } = useParticipantSurveyReports(organizationId, studyId);
  const foreground = '#ffffff';

  if (isLoading || !reports) {
    return <Spin />
  }

  if (Object.keys(reports.data).length == 0) {
    return <Empty />
  }

  const chartData = [
    ["Participant", ...reports.survey_names],
    ...reports.participants.map(participant => [
      participant,
      ...reports.survey_names.map(surveyName => 
        reports.data[participant][surveyName] || 0
      )
    ])
  ]

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <Chart
        chartType="ColumnChart"
        width="100%"
        height="400px"
        data={chartData}
        options={{
          title: "Participant Surveys",
          titleTextStyle: {
            color: foreground
          },
          legend: {
            textStyle: {
              color: foreground
            },
            pagingTextStyle: {
              color: foreground
            },
            scrollArrows:{
              activeColor: foreground
            }
          },
          hAxis: {
            textStyle: {
                color: foreground
            },
            gridlineColor:foreground
          },
          vAxis: {
            textStyle: {
              color: foreground
            },
            gridlineColor: foreground
          },
          isStacked: true,
          backgroundColor: "#303037",
          colors: ["#80BCFF", "#007AFF", "#34C759", "#99E3AC", "#FF3B30", 
          "#FF9D97", "#FF9F0A", "#FFCF84", "#FFFFCC", "#006837"]
        }}
      />
    </Space>
  )
}

export default ParticipantReports