import { EditOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { Alert, App, Form, Input, Space, Table, Typography } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React, { useCallback, useState } from 'react';
import { useParams } from "react-router-dom";
import { DataStream, useAddOrganizationDevice, useDataStreamUpdateDisplayName, useOrganizationDataStreams } from '../../api';
import { useSelectedOrganizationContext } from '../../contexts/SelectedOrganization';
import { color } from '../../theme';
import { LoadingIndicator } from '../Loader';
import TooltipButton from '../TooltipButton';
 
const { Title, Text } = Typography

const DataStreamsRowActions: React.FC<DataStream> = ({ id }) => {
  return (
    <Text copyable={{ text: id, tooltips: ["Copy Data Stream ID", "Data Stream ID Copied"] }} />
  )
}

const AddOrganizationDevice: React.FC = () => {
  const { organizationId } = useParams<{ organizationId: string }>();
  const { organization } = useSelectedOrganizationContext()
  const [form] = Form.useForm()
  const { message: messageApi } = App.useApp();
  const [addOrganizationDevice] = useAddOrganizationDevice({
    onError: (error) => {
      if (error?.status === 404) {
        void messageApi.error(error?.message)
        return
      }
      void messageApi.error('Failed to add device')
    },
    onSuccess: () => {
      void messageApi.success('Device added')
      form.resetFields()
    }
  });

  const addOrganizationDeviceFormHandler = useCallback(() => {
    void addOrganizationDevice({
      organizationId,
      activationKey: form.getFieldValue('activationKey') as string
    })
  }, [form, addOrganizationDevice, organizationId])

  const [value, setValue] = useState<string>()

  if (!organization?._permissions.can_edit_data_streams) {
    return <></>;
  }

  return (
    <Form
      name="add-organization-device"
      layout={'inline'}
      form={form}
      onFinish={addOrganizationDeviceFormHandler}
      size="small"
    >
      <Form.Item label="Add Device" name="activationKey" required={true}>
        <Input placeholder="Activation Key" onChange={({ target }) => setValue(target.value) }/>
      </Form.Item>
      <Form.Item style={{ marginRight: 0, width: 'unset' }}>
        <TooltipButton buttonProps={{ htmlType: "submit", disabled: !value, style: { border: 'none', background: 'none', padding: '0', width: 'unset' }, icon: <PlusCircleOutlined style={{ color: `${color.blue}${!value ? '40' : ''}` }} /> }} tooltipProps={{ title: !value ? 'You must enter an Activation Key' : 'Add Device', placement: 'bottomRight' }} />
      </Form.Item>
    </Form>
  )
}

export const DataStreams: React.FC = () => {
  const { organizationId } = useParams<{ organizationId: string, studyId: string }>();
  const { data: dataStreams, isLoading: dataStreamsLoading, error: dataStreamsError } = useOrganizationDataStreams(organizationId);
  const { message: messageApi } = App.useApp();

  const { organization } = useSelectedOrganizationContext()
  const can_edit_data_streams = organization?._permissions.can_edit_data_streams

  const [updateDisplayName] = useDataStreamUpdateDisplayName({
    onSuccess: () => {
      void messageApi.success('Successfully updated display name')
    },
    onError: () => {
      void messageApi.error('Failed to update display name');
    }
  })

  const columns: ColumnsType<DataStream> = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: function ID(text) {
        return <Text ellipsis>{text}</Text>
      },
      sorter: (a, b) => a.name.localeCompare(b.name)
    },
    {
      title: 'Display Name',
      dataIndex: 'display_name',
      key: 'display_name',
      render: function DisplayName(text, dataStream) {
        return (
          <Text
            ellipsis
            {...can_edit_data_streams && {
              editable: {
                icon: <EditOutlined style={{ fontSize: 18, color: color.blue }} />,
                onChange: display_name => {
                  if (display_name && display_name !== text) {
                    void updateDisplayName({ id: dataStream.id, display_name })
                  }
                }
              }
            }}
          >
            {text}
          </Text>
        )
      },
      sorter: (a, b) => a.name.localeCompare(b.name)
    },
    {
      title: 'Serial Number',
      dataIndex: 'serial',
      key: 'serial',
      render: function Serial(text, dataStream) {
        return <Text>{dataStream.flow_configuration?.serial_number}</Text>
      },
      sorter: (a, b) => a.name.localeCompare(b.name)
    },
    {
      title: 'Company',
      dataIndex: 'company',
      key: 'company',
      render: function Company(text, dataStream) {
        return <Text>{dataStream.data_transform.company}</Text>
      },
      sorter: (a, b) => a.data_transform.company.localeCompare(b.data_transform.company)
    },
    {
      title: 'Actions',
      key: 'actions',
      align: "right",
      render: function Actions(text, dataStream) {
        return <DataStreamsRowActions {...dataStream} />
      }
    }
  ];

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <Space style={{ justifyContent: 'space-between', width: '100%', padding: '12px 0' }}>
        <Space direction="vertical">
          <Title level={5} style={{ margin: 0 }}>Registered Devices</Title>
          {dataStreamsError && <Alert type="error" showIcon message="Error loading data streams" />}
        </Space>
        <AddOrganizationDevice />
      </Space>
      <Table
        pagination={{ simple: true, pageSize: 10 }}
        {...dataStreamsLoading && {
          loading: {
            indicator: <LoadingIndicator />
          }
        }}
        dataSource={dataStreams}
        columns={columns}
        bordered={false}
        rowKey="id"
      />
    </Space>
  )
}
