import React, { Dispatch, SetStateAction, useState } from 'react'
import { useStudyDataStreams, useDataStreamDelete, useDataStreamToggleHidden, useDataStreamUpdateName, useDataStreamUpdateDisplayName, StudyDataStream, useDataStreamToggleAutoAssign, useDataStreamToggleAlwaysAvailable, DataStream, DataStreamSurvey } from '../../api'
import { Typography, Space, Tooltip, Popconfirm, Row, Popover, List, Col, Button, Card, Alert, App } from 'antd'
import { useParams } from "react-router-dom";
import { MoreOutlined, EditOutlined, CheckOutlined } from '@ant-design/icons'
import { useSelectedStudyContext } from '../../contexts/SelectedStudy'
import { LoadingIndicator } from '../Loader'
import styles from './styles.module.css'
import { SurveyPreview } from '../Survey/Preview';
import { downloadJson } from '../../utilities';
import { SurveyBuilder, SurveyBuilderActions } from '../Survey/Builder';
import { color } from '../../theme';

const { Text, Title } = Typography

type DataStreamsActionProps = {
  dataStream: StudyDataStream
  setDataStream: Dispatch<SetStateAction<DataStream | undefined>>
  setSurveyBuilderAction: Dispatch<SetStateAction<SurveyBuilderActions>>
  setSurveyBuilding: Dispatch<SetStateAction<boolean>>
}

const DataStreamsActions: React.FC<DataStreamsActionProps> = ({ dataStream, setSurveyBuilding, setSurveyBuilderAction, setDataStream }) => {
  const { organizationId, studyId } = useParams<{ organizationId: string, studyId: string }>()
  const { study } = useSelectedStudyContext()
  const { message: messageApi } = App.useApp();

  const [deleteDataStream] = useDataStreamDelete({
    onError: () => {
      void messageApi.error('Failed to remove data stream from study')
    },
    onSuccess: () => {
      void messageApi.success('Successfully removed data stream from study')
    }
  })
  const canModifyStudy = study && !study?.is_complete;

  const [toggleDataStreamHidden] = useDataStreamToggleHidden({
    onError: () => {
      void messageApi.error('Failed to toggle data stream hidden value')
    }
  })

  const [toggleAutoAssignSurveys] = useDataStreamToggleAutoAssign({
    onError: () => {
      void messageApi.error('Failed to toggle automatically assigning surveys to new participants');
    }
  });

  const [toggleAlwaysAvailable] = useDataStreamToggleAlwaysAvailable({
    onError: () => {
      void messageApi.error(`Failed to toggle always available`);
    }
  });

  return (
    <Space style={{width: "125px"}} direction='vertical'>
      {
        !dataStream.has_data && study?._permissions.can_add_data_stream &&
        <Button type="text" onClick={() => {
            setSurveyBuilderAction("edit")
            setDataStream(dataStream)
            setSurveyBuilding(true)
          }
        }>
          Edit
        </Button>
      }

      {study?._permissions?.can_add_data_stream && (
        <Button type="text" onClick={() => {
          setSurveyBuilderAction("clone")
          setDataStream(dataStream)
          setSurveyBuilding(true)
        }}>
          Clone and Edit
        </Button>
      )}

      <Tooltip title={!dataStream.auto_assign ? 'Allow automatically assigning surveys to new participants' : 'Remove automatically assigning surveys to new participants'} placement="left" arrow={{ pointAtCenter: true }}>
        <Button type="text" onClick={async () => {
          const res = await toggleAutoAssignSurveys({ id: dataStream.id, auto_assign: !dataStream.auto_assign })
            if (res?.id) {
              void messageApi.success(`Successfully toggled ${dataStream.auto_assign ? 'off' : 'on'} automatically assigning surveys to new participants`);
            } else {
              void messageApi.error('Failed to toggle automatically assigning surveys to new participants');
            }
          }}>
          Auto-Assign { dataStream.auto_assign && <CheckOutlined />}
        </Button>
      </Tooltip>

      {dataStream._permissions.can_update_is_hidden && (
        !dataStream.is_hidden ? (
          <Popconfirm
            title={() => <Text>Are you sure you want to unpublish <strong>{dataStream.name}</strong> in <strong>{study?.name}</strong>?  It will no longer appear in the participant&apos;s mobile app or be listed on their participant page Data Streams list in Portal.</Text>}
            onConfirm={async () => {
              const res = await toggleDataStreamHidden({ id: dataStream.id, is_hidden: true, organizationId, studyId })
              if (res?.id) {
                void messageApi.success(`Successfully upublished Data Stream`)
              } else {
                void messageApi.error('Failed to unpublish Data Stream')
              }
            }}
            arrow={{ pointAtCenter: true }}
          >
            <Tooltip title="Unpublish Survey" placement="left" arrow={{ pointAtCenter: true }}>
              <Button type="text">
                Unpublish
              </Button>
            </Tooltip>
          </Popconfirm>
        ) : (
          <Popconfirm
            title={() => <Text>Are you sure you want to publish <strong>{dataStream.name}</strong> from <strong>{study?.name}</strong>? It will appear in the participant&apos;s mobile app and be listed on their participant page Data Streams list in Portal.</Text>}
            onConfirm={async () => {
              const res = await toggleDataStreamHidden({ id: dataStream.id, is_hidden: false, organizationId, studyId })
              if (res?.id) {
                void messageApi.success(`Successfully published Data Stream`)
              } else {
                void messageApi.error('Failed to publish Data Stream')
              }
            }}
            arrow={{ pointAtCenter: true }}
          >
            <Tooltip title="Publish Data Stream" placement="left" arrow={{ pointAtCenter: true }}>
              <Button type="text">
                Publish
              </Button>
            </Tooltip>
          </Popconfirm>
        )
      )}

      {
        dataStream._permissions.can_toggle_always_ready && (
          <Tooltip title={!dataStream.always_ready ? 'Set survey to always be available' : 'Stop survey from always being available'} placement="left" arrow={{ pointAtCenter: true }}>
            {
              <Button type="text" onClick={async () => {
                const res = await toggleAlwaysAvailable({ id: dataStream.id })
                if (res?.success) {
                  void messageApi.success(`Successfully toggled ${res.always_ready ? 'on' : 'off'} always available`)
                } else {
                  void messageApi.error(`Failed to toggle always available`);
                }
              }}>
                Always Available {dataStream.always_ready && <CheckOutlined />}
              </Button>
            }
          </Tooltip> 
        )
      }
      {
        dataStream.meta?.steps && <SurveyPreview name={dataStream.name} survey={dataStream.meta as DataStreamSurvey} textButton />
      }
      {
        dataStream.meta?.steps && 
        <Tooltip title="Download JSON" placement='left'>
          <Button type="text" onClick={() => downloadJson(dataStream.meta, dataStream.name)}>
            Download
          </Button>
        </Tooltip>
      }
      {canModifyStudy && dataStream._permissions.can_delete &&
        <Popconfirm
          title={() => <Text>Are you sure you want to remove <strong>{dataStream.name}</strong> from <strong>{study?.name}</strong>?</Text>}
          onConfirm={() => void deleteDataStream({ id: dataStream.id })}
          arrow={{ pointAtCenter: true }}
        >
          <Button type="text" danger>
            Delete
          </Button>
        </Popconfirm>
      }

    </Space>
  )
}

export const StudySurveys: React.FC = () => {
  const { organizationId, studyId } = useParams<{ organizationId: string, studyId: string }>();
  const { isLoading: dataStreamsLoading, isError: dataStreamsError, data: dataStreams } = useStudyDataStreams(organizationId, studyId);
  const { study } = useSelectedStudyContext()
  const [buildingSurvey, setBuildingSurvey ]= useState<boolean>(false)
  const [surveyBuilderAction, setSurveyBuilderAction] = useState<SurveyBuilderActions>("create")
  const [dataStream, setDataStream] = useState<DataStream>()
  const { message: messageApi } = App.useApp();

  const canAddStudyDataStream = study?._permissions?.can_add_data_stream;
  const [updateSurveyName] = useDataStreamUpdateName({
    onError: () => {
      void messageApi.error('Failed to update survey name');
    },
    onSuccess: () => {
      void messageApi.success(`Successfully updated survey name`);
    }
  });

  const [updateSurveyDisplayName] = useDataStreamUpdateDisplayName({
    onError: () => {
      void messageApi.error('Failed to update survey display name');
    },
    onSuccess: () => {
      void messageApi.success(`Successfully updated survey display name`);
    }
  });

  return (
    <>
      { buildingSurvey && <SurveyBuilder dataStream={dataStream} action={surveyBuilderAction} setIsVisible={setBuildingSurvey} /> }
      { !buildingSurvey && 
         <Space direction="vertical" size="large" style={{ width: '100%' }}>
          <Space style={{ justifyContent: 'space-between', width: '100%' }}>
            <Space direction="vertical">
              <Title level={3} style={{ marginTop: 0 }}>Surveys</Title>
              {dataStreamsError && <Alert type="error" showIcon message="Error loading surveys" />}
            </Space>
            {canAddStudyDataStream && (
              <Space size="small">
                <Button type="primary" className={styles.StudySurveyButton}
                onClick={ () => {
                  setSurveyBuilderAction("create")
                  setBuildingSurvey(true)
                }}>
                Create Survey
              </Button>
              </Space>
            )}
          </Space>
   
         <List
           dataSource={dataStreams?.filter(dataStream => dataStream.data_transform.integration === 'survey')}
           pagination={{ simple: true, pageSize: 9, hideOnSinglePage: true }}
           size="small"
           grid={{ gutter: 16, column: 3 }}
           renderItem={dataStream => (
             <List.Item key={dataStream.id} style={{ padding: 0, marginBottom: 16 }}>
               <Card key={dataStream.id} style={{height: "170px"}}
                 title={<Text ellipsis className={styles.CardTitleText} editable={dataStream._permissions.can_edit_names &&  
                 {onChange: editedValue => {
                   if(editedValue !== dataStream.name) {
                     void updateSurveyName({id: dataStream.id, name: editedValue, organizationId, studyId})
                   }
                 }, 
                 icon: <EditOutlined style={{color: color.blue}} /> }} >{dataStream.name}</Text>}
                 extra={[
                   <div key="more" style={{ marginLeft: 15 }}>
                     <Popover placement='bottom' trigger="click" content={<DataStreamsActions dataStream={dataStream} setDataStream={setDataStream} setSurveyBuilderAction={setSurveyBuilderAction} setSurveyBuilding={setBuildingSurvey} />}>
                       <MoreOutlined />
                     </Popover>
                   </div>
                 ]}
               >
                 <Space direction='vertical' style={{width: "100%"}}>
                   <Row justify='space-between'>
                     <Col span={12}>
                       <Space direction='vertical'>
                         <Text className={styles.SurveyDisplayNameLabel}>Display Name</Text>
                         <Text style={{fontWeight: 'bold'}} editable={dataStream._permissions.can_edit_names && 
                         {onChange: editedValue => {
                           if (editedValue !== dataStream.display_name) {
                             void updateSurveyDisplayName({id: dataStream.id, display_name: editedValue})
                           }
                         }, 
                         icon: <EditOutlined style={{color: color.blue}} />}}>
                           {dataStream.display_name}
                         </Text>
                       </Space>
                     </Col>
                     <Col span={12} style={{paddingLeft: 20, borderLeft: "1px solid #fff"}}>
                       <Space direction='vertical'>
                         <Text>Survey Questions</Text>
                         <Text style={{fontWeight: 800}}>{dataStream.meta?.steps?.length ? dataStream.meta?.steps[0].questions.length : 0}</Text>
                       </Space>
                     </Col>
                   </Row>
                 </Space>
               </Card>
             </List.Item>
           )}
           {...dataStreamsLoading && {
             loading: {
               indicator: <LoadingIndicator />
             }
           }}
         />
       </Space>
      }
    </>
  )
}

