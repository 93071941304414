import { SeedToken } from "antd/lib/theme/internal"

export const token: Partial<SeedToken> = {
    fontFamily: 'Open Sans',
    colorPrimary: "#00bbff",
    colorBgBase: "#121015",
    colorInfo: "#00bbff",
    colorSuccess: "#00c708",
    colorWarning: "#de9901",
    colorError: "#c32121",
}

export const color = {
    gray: "#5b6670",
    blue: token.colorInfo || 'blue',
    red: token.colorError || 'red',
    yellow: "#decf01",
    gold: token.colorWarning  || 'gold',
    green: token.colorSuccess || 'green',
    darkRed: '#591010',
    darkBlue: '#11232c',
    darkGreen: '#0e4a10',
    darkYellow: '#513c0e',
    darkGray: '#1c1920',
} as const