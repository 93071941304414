import { Button, Card, Form, App, Select, Space, Typography } from "antd"
import { useForm } from "antd/lib/form/Form"
import React, { useContext, useState } from "react"
import { ClinicAuthTokenContext } from "."
import { ClinicVisitResponse, useClinicVisit, useClinicVisitInfo } from "../../api"
import { generatePath, useHistory, useParams } from "react-router-dom"
import { EnrollmentSurveyAnswersInner } from "../Participant"
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons"
import moment from "moment"

const { Title } = Typography

const Visit: React.FC = () => {
    const [form] = useForm()
    const clinicAuthTokenContext = useContext(ClinicAuthTokenContext);
    const { studyId } = useParams<{ studyId: string }>();
    const [clinicVisitResponse, setClinicVisitResponse] = useState<ClinicVisitResponse>()
    const [checklistId, setChecklistId] = useState<string>()
    const history = useHistory()
    const { data: clinicVisitInfo } = useClinicVisitInfo(clinicAuthTokenContext.token, studyId)
    const { message: messageApi } = App.useApp();

    const [clinicVisit] = useClinicVisit({
        onError: () => {
            void messageApi.error('Error finding participant!')
        }}) 

    const lookupParticipant = async () => {
        const participantId = form.getFieldValue("participant_id") as string;
        const res = await clinicVisit({ token: clinicAuthTokenContext.token, studyId, participantId })
        if (res) {
            setClinicVisitResponse(res)
        }
    }

    return (
        <Space direction="vertical" size="large">
            <Title level={1} style={{ fontFamily: 'Museo' }}>Study Visit</Title>

            {!clinicVisitResponse ? (
                <Form form={form} autoComplete="off" layout="vertical">
                    <Form.Item
                    label="Participant ID"
                    name="participant_id"
                    required={true}
                    >
                        <Select size="large" style={{ minWidth: 200 }} showSearch
                            options={clinicVisitInfo?.participants.map(participant => (
                                { value: participant.participant_id, label: participant.participant_id }
                            ))}
                            onSelect={lookupParticipant}
                        />
                    </Form.Item>
                </Form>
            ) : (
                <Space direction="vertical" size="small">
                    {clinicVisitResponse.protocol && (
                        <Card title="Cohort">
                            <Title style={{ margin: 0 }} level={3}>{clinicVisitResponse.protocol}</Title>
                        </Card>
                    )}

                    <Card title="Participant ID">
                        <Title style={{ margin: 0 }} level={3} code>{clinicVisitResponse.participant_id}</Title>
                    </Card>

                    {clinicVisitResponse.enrollment_survey_answers?.length > 0 && (
                        <EnrollmentSurveyAnswersInner
                            title="Participant Details"
                            survey={clinicVisitResponse.enrollment_survey}
                            answers={clinicVisitResponse.enrollment_survey_answers}
                        />
                    )}

                    <Card title="Visit" actions={[
                        <Button key="back" size="large" onClick={() => {
                            setClinicVisitResponse(undefined)
                        }}>
                            Back
                        </Button>,
                        <Button key="continue" size="large" type="primary" disabled={!checklistId} onClick={() => history.push(generatePath('/clinic/:studyId/checklist/:checklistId', { studyId, checklistId: checklistId || 0 }))}>
                            Continue
                        </Button>,
                    ]}>
                        <Select size="large" style={{ width: 400 }} value={checklistId} onChange={value => setChecklistId(value)}>
                            {clinicVisitResponse.checklists.map(checklist => (
                                <Select.Option key={checklist.id} value={checklist.id}>
                                    {checklist.complete ? <CheckCircleOutlined /> : <CloseCircleOutlined />}
                                    &nbsp;
                                    {checklist.name}
                                    {
                                        checklist.completed_at ?
                                        <>&nbsp;({moment.unix(checklist.completed_at).format('MMM DD LT')})</> :
                                        checklist.missed ?
                                        <>&nbsp;(missed)</> :
                                        <></>
                                    }
                                </Select.Option>
                            ))}
                        </Select>
                    </Card>
                </Space>
            )}
        </Space>
    )
}

export default Visit