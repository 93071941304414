
import React, { useRef, useState } from 'react';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { CarouselRef } from 'antd/lib/carousel';
import { Button, Carousel, Divider, Space, Spin } from 'antd';

import { useAnswerCustomChallenge, useLogIn, useSignUp, useVerifyUserAuthentication } from './api/account';
import { useGoogleLogin, user as useUser } from './api';

import { Mantra, CarouselForm, Policies, Error, CarouselHeader } from './pages/AccountForms';
import helmet from './assets/splash-helmet.jpg';
import isEmail from 'validator/lib/isEmail';
import { CognitoUserWithParams, QrCodeProps } from './pages/AccountForms/QrCodes';
import GoogleLogin from './components/GoogleLogin';
import { captureException } from '@sentry/react';

import accountStyles from './pages/AccountForms/styles.module.css'

export enum AuthenticationState {
  Authenticating,
  Authenticated,
  Unauthenticated
}

type AccountFields = {
  email: string;
  acceptsAgreements: boolean;
}

type PasswordlessAuthAppProps = {
  onAuthStateChange: (authenticationState: AuthenticationState) => void;
}

export const PasswordlessAuthApp: React.FC<PasswordlessAuthAppProps> = ({ onAuthStateChange }) => {
  
    const signUpCarousel = useRef<CarouselRef>(null);
    const logInCarousel = useRef<CarouselRef>(null);

    const { data: user } = useUser();

    const initialFormState: AccountFields = {
      email: user && user.attributes ? user.attributes.email : '',
      acceptsAgreements: false
    }
    const [accountForm, setAccountForm] = useState(initialFormState);
    const [retries, setRetries] = useState(0);
    const [code, setCode] = useState('');
  
    function resetState() {
      // TODO: Some of this state can be cleaned up
      setAccountForm(initialFormState);
      setCode('');
      setRetries(0);
      setCognitoUser(undefined);
      setLoginError('');
      setSignUpError('');
      setChallengeError('');
      setQrCodes(undefined);
    }
    
    type CurrentFlow = 'splash' | 'sign-up' | 'log-in' | 'google-sign-up' | 'google-log-in'
    const [currentFlow, setCurrentFlow] = useState<CurrentFlow>('splash')
    function resetStateAndNavigate(newFlow: CurrentFlow = 'splash') {
      resetState();
      setCurrentFlow(newFlow);
      logInCarousel.current?.goTo(0)
      signUpCarousel.current?.goTo(0)
    }
  
    const [signupError, setSignUpError] = useState('');
    const [finalizeSignUp, { isError: isSignUpError, isLoading: isSignUpLoading }] = useSignUp({
      onSuccess: () => {
        console.log("User has successfully signed up!")
      },
      onError: (error) => {
        setSignUpError(error && error.name === "UsernameExistsException"
            ? `An account with the given email already exists. Please log in or use a different email address.`
            : `We've encountered an error while creating your account. Please try again later.`
        );
        console.log("Error for user sign up: ", error);
      }
    });
    
    const [qrCodes, setQrCodes] = useState<QrCodeProps | undefined>();
    const [cognitoUser, setCognitoUser] = useState<CognitoUser>();

    async function logInAndStartFlow(params?: { nav?: () => void, shouldUseQr?: boolean }) {
      const { nav, shouldUseQr } = params || {};
      const cognitoUser = await logIn({ email: accountForm.email });
      setCognitoUser(cognitoUser);
      if (shouldUseQr) {
        setQrCodes({ 
          cognitoUser: cognitoUser as CognitoUserWithParams,
          emailCodeReceivedCallback: answerChallenge,
          invalidSessionHandler: () => resetStateAndNavigate('log-in')
        });
      }
      if (cognitoUser && nav) nav();
    }
  
    const [loginError, setLoginError] = useState('');
    const [logIn, { isError: isLogInError, isLoading: isLogInLoading }] = useLogIn({
      onSuccess: () => {
        // User has logged in - now go to confirm email form to enter code
        console.log("User has successfully logged in!");
      },
      onError: (error?: Error) => {
        setLoginError(
          error && error.name === "UserLambdaValidationException"
            ? `We've encountered an error while logging into your account. Please verify that your email address is correct.`
            : `We've encountered an error while logging into your account. Please try again later.`
          );
        console.log("Error for user log in: ", error);
      }
    });
  
    const [challengeError, setChallengeError] = useState('');
    const [answerChallenge, { isError: isChallengeError, isLoading: isChallengeLoading }] = useAnswerCustomChallenge({
      onSuccess: async () => {
        await verifyUserAuthentication();
        console.log("Successfully answered challenge!");
      },
      onError: (error) => {
        if (error?.name === "NotAuthorizedException" && error.message === "User is disabled.") {
          setChallengeError("There is an issue with accessing your account. Please contact support@kernel.com")
          return
        }

        const currRetries = retries + 1;
        setRetries(currRetries);
        setChallengeError(
          currRetries >= 3
            ? "You have exceeded the maximum number of attempts. Please try again later."
            : `We've encountered an error verifying your confirmation code. \nPlease try again.`
         );
        console.log("Error for answering challenge: ", error);
      }
    });
  
    const [verifyUserAuthentication] = useVerifyUserAuthentication({
      onSuccess: () => {
        onAuthStateChange(AuthenticationState.Authenticated);
        console.log("Successful user verification!");
      },
      onError: (error) => {
        onAuthStateChange(AuthenticationState.Unauthenticated);
        console.log("Error for user verification: ", error);
      }
    });

    const [googleLogin] = useGoogleLogin()

    return (
      <div style={{ backgroundColor: '#070707', height: '100%', width: '100%' }}>
        <img 
          style={{ height: '100%', objectFit: 'contain' }} 
          src={helmet} 
        />
        <div className='centered'>
          {currentFlow === 'splash' &&
            <div className={accountStyles.carousel}>
              <div className='flex-center'>
                <img src={`${process.env.PUBLIC_URL}/kernel-logo-white.png`} style={{ height: 50, marginTop: 30 }} />
                <Mantra />
                <Space direction="vertical" size="large" style={{ width: "100%" }}>
                  <Button size="large" block onClick={() => resetStateAndNavigate('log-in')}>Log In</Button>
                  <Button size="large" block onClick={() => resetStateAndNavigate('sign-up')}>Sign Up</Button>
                </Space>
              </div>
            </div>
          }
          {(currentFlow === 'google-log-in' || currentFlow === 'google-sign-up') &&
            <div className={accountStyles.carousel}>
              <CarouselHeader
                headerLabel={
                  currentFlow === 'google-log-in' ? "Signing in with Google ..." :
                  currentFlow === 'google-sign-up' ? "Creating account with Google ..." :
                  ''
                }
                goBack={() => resetStateAndNavigate(
                  currentFlow === 'google-log-in' ? 'log-in' :
                  currentFlow === 'google-sign-up' ? 'sign-up' :
                  'splash'
                )}
              />
              <div className='flex-center' style={{ marginTop: 20 }}>
                {signupError || loginError || challengeError ?
                  <Error>{signupError || loginError || challengeError}</Error> :
                  <Spin />                  
                }
              </div>
            </div>
          }
          {currentFlow === 'log-in' &&
            <Carousel className={accountStyles.carousel} ref={logInCarousel} autoplay={false} dots={false} effect='fade' swipeToSlide={false} accessibility={false}>
              <CarouselForm
                goBack={() => resetStateAndNavigate()}
                inputValue={accountForm.email}
                headerLabel='Log in'
                inputLabel='Email'
                onInputChange={(email) => setAccountForm({ ...accountForm, email })}
                inputValidator={isEmail}
                subtext='You will receive an email to allow you to access your account.'
                primaryButtonText='Log in'
                onPrimaryButtonClick={() => logInAndStartFlow({ nav: () => logInCarousel.current?.next(), shouldUseQr: true })}

                // Handle state on press of primary button 
                isPrimaryLoading={isLogInLoading}
                isError={isLogInError}
                error={loginError}
                extra={
                  <>
                  <Divider style={{ color: '#fff', borderTopColor: '#fff', marginTop: 30 }}>or log in with</Divider>
                  <GoogleLogin
                    callback={async token => {
                      setCurrentFlow('google-log-in')
                      const res = await googleLogin({ token })
                      if (res?.success) {
                        const cognitoUser = await logIn({ email: res.email });
                        setCognitoUser(cognitoUser);
                        if (cognitoUser) {
                          await answerChallenge({ email: res.email, cognitoUser, code: res.email_code })
                        }
                      } else {
                        setLoginError("Error logging in with Google")
                      }
                    }}
                  />
                  </>
                }
              />
              <CarouselForm
                goBack={() => logInCarousel.current?.prev()}
                headerLabel='Log in'
                inputLabel=''
                inputValue={code}
                onInputChange={(code: string) => setCode(code)}
                subtext='An email was sent to you. Copy the code and enter it above.'
                primaryButtonText='Confirm'
                secondaryButtonText='Resend'
                onPrimaryButtonClick={() => answerChallenge({ email: accountForm.email, cognitoUser, code })} 
                onSecondaryButtonClick={() => logInAndStartFlow({ shouldUseQr: true })}
                qrCodes={qrCodes}

                // Handle state on press of primary button or secondary button
                isPrimaryLoading={isChallengeLoading}
                isSecondaryLoading={isLogInLoading}
                isError={isChallengeError}
                error={challengeError}
              />
            </Carousel>
          }
          {currentFlow === 'sign-up' &&
             <Carousel className={accountStyles.carousel} ref={signUpCarousel} autoplay={false} dots={false} effect='fade' swipeToSlide={false} accessibility={false}>
                <CarouselForm
                  goBack={() => resetStateAndNavigate()}
                  headerLabel='Create account'
                  inputLabel={`What's your email?`}
                  inputValue={accountForm.email}
                  onInputChange={(email) => setAccountForm({ ...accountForm, email: email })}
                  inputValidator={isEmail}
                  subtext='You will need to confirm this email to access your account.'
                  CheckboxContent={Policies}
                  onCheckboxChange={(checked: boolean) => setAccountForm({ ...accountForm, acceptsAgreements: checked })}
                  checkboxValue={accountForm.acceptsAgreements}
                  primaryButtonText='Create'
                  onPrimaryButtonClick={async () => {
                    const res = await finalizeSignUp({ email: accountForm.email })
                    if (res) {
                      // Log in after signing up to send email code
                      await logInAndStartFlow({ nav: () => signUpCarousel.current?.next() });
                    }
                  }}
    
                  // Handle state on press of primary button 
                  isPrimaryLoading={isSignUpLoading || isLogInLoading}
                  isError={isSignUpError || isLogInError}
                  error={signupError}
                  extra={
                    <>
                      <Divider style={{ color: '#fff', borderTopColor: '#fff', marginTop: 30 }}>or create account with</Divider>
                      <GoogleLogin
                        callback={async token => {
                          try {
                            setCurrentFlow('google-sign-up')
                            const res = await googleLogin({ token })
                            if (res?.success) {
                              const signUpRes = await finalizeSignUp({ email: res.email })
                              if (signUpRes) {
                                const cognitoUser = await logIn({ email: res.email });
                                setCognitoUser(cognitoUser);
                                if (cognitoUser) {
                                  await answerChallenge({ email: res.email, cognitoUser, code: res.email_code })
                                  return
                                }
                              }
                            }
                          } catch (e) {
                            captureException(e)
                          }
                          setLoginError("Error creating account with Google")
                        }}
                      />
                      <div style={{ fontSize: 12, marginTop: 10 }}>
                        When creating an account with Google,&nbsp;
                        <Policies />
                      </div>
                    </>
                  }
                />
                <CarouselForm
                  goBack={() => signUpCarousel.current?.prev()}
                  headerLabel='Create account'
                  inputLabel='Sign In Code'
                  inputValue={code}
                  onInputChange={(code: string) => setCode(code)}
                  subtext={`An email was sent to you at ${accountForm.email}. Copy the code and enter it above.`}
                  primaryButtonText='Confirm'
                  secondaryButtonText='Resend'
                  onPrimaryButtonClick={() => answerChallenge({ email: accountForm.email, cognitoUser, code })} 
                  onSecondaryButtonClick={() => logInAndStartFlow()}

                  // Handle state on press of primary button or secondary button
                  isPrimaryLoading={isChallengeLoading}
                  isSecondaryLoading={isLogInLoading}
                  isError={isChallengeError}
                  error={challengeError}
                />
            </Carousel>
          }
        </div>
      </div>
    );
}
