import React from 'react';
import { useParams } from 'react-router-dom';
import { useParticipantFunnelReports } from 'src/api';
import { Space, Spin } from 'antd';
import Chart from 'react-google-charts';

const ParticipantFunnelReports: React.FC = () => {
  const { organizationId, studyId } = useParams<{ organizationId: string, studyId: string }>();
  const { data: reports, isLoading } = useParticipantFunnelReports(organizationId, studyId);
  const foreground = '#ffffff';
  const colors = ["#80BCFF", "#007AFF", "#34C759", "#99E3AC", "#FF3B30", "#FF9D97", "#FF9F0A", "#FFCF84", "#FFFFCC", "#006837"];
  const background = "#303037";
  if (isLoading || !reports) {
    return <Spin />
  }
  
  const allStatuses = [
    ["Week", ...reports.all_statuses.statuses],
    ...reports.all_statuses.weeks.map(week => [
      week,
      ...reports.all_statuses.statuses.map(status => 
        reports.all_statuses.data[week][status] || 0
      )
    ])
  ]

  const dailyCumulativeCounts = [
    ["Day", ...reports.daily_chart.statuses],
    ...reports.daily_chart.day.map(day => [
      day,
      ...reports.daily_chart.statuses.map(status =>
        reports.daily_chart.data[day][status])
    ])
  ]

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <Chart
        chartType="PieChart"
        width="100%"
        data={[
          ['Status', 'Count'],
          ['Ineligible', reports.screening.ineligible],
          ['Withdrew', reports.screening.withdrew],
          ['Potentially Eligible', reports.screening.potentially_eligible],
        ]}
        options={{
          title: "Screening",
          titleTextStyle: {
            color: foreground
          },
          tooltip: {
            showColorCode: true
          },
          legend: {
            textStyle: {
              color: foreground
            }
          },
          backgroundColor: background,
          colors: colors
        }}
      />
      <Chart
        chartType="PieChart"
        width="100%"
        data={[
          ['Reason', 'Count'],
          ...Object.entries(reports.ineligibility).map(([reason, count]) => [reason, count])
        ]}
        options={{
          title: "Ineligibility",
          titleTextStyle: {
            color: foreground
          },
          tooltip: {
            showColorCode: true
          },
          legend: {
            textStyle: {
              color: foreground
            }
          },
          backgroundColor: background,
          colors: colors
        }}
      />
      <Chart
        chartType="PieChart"
        width="100%"
        data={[
          ['Reason', 'Count'],
          ...Object.entries(reports.withdrawal).map(([reason, count]) => [reason, count])
        ]}
        options={{
          title: "Withdrawal",
          titleTextStyle: {
            color: foreground
          },
            tooltip: {
              showColorCode: true
            },
          legend: {
            textStyle: {
              color: foreground
            }
          },
          backgroundColor: background,
          colors: colors
        }}
      />
      <Chart
        chartType="PieChart"
        width="100%"
        data={[
          ['Status', 'Count'],
          ['Eligible', reports.enrollment.eligible],
          ['Enrolled', reports.enrollment.enrolled],
        ]}
        options={{
          title: "Enrolled",
          titleTextStyle: {
            color: foreground
          },
          tooltip: {
            showColorCode: true
          },
          legend: {
            textStyle: {
              color: foreground
            }
          },
          backgroundColor: background,
          colors: colors
        }}
      />
      {allStatuses[0].length > 1 && 
        <Chart
          chartType="ColumnChart"
          width="100%"
          height="400px"
          data={allStatuses}
          options={{
            title: "Participant Status by Week",
            titleTextStyle: {
              color: foreground
            },
            legend: {
              textStyle: {
                color: foreground
              },
              pagingTextStyle: {
                color: foreground
              },
              scrollArrows:{
                activeColor: foreground
              }
            },
            hAxis: {
              textStyle: {
                  color: foreground
              },
              gridlineColor: foreground
            },
            vAxis: {
              textStyle: {
                color: foreground
              },
              gridlineColor: foreground
            },
            isStacked: true,
            backgroundColor: background,
            colors: colors
          }}
        />
      }
      <Chart 
        chartType='LineChart'
        width="100%"
        height="400px"
        data={dailyCumulativeCounts}
        options={{
          title: "Daily Cumulative Counts",
          titleTextStyle: {
            color: foreground
          },
          legend: {
            textStyle: {
              color: foreground
            },
            pagingTextStyle: {
              color: foreground
            },
            scrollArrows:{
              activeColor: foreground
            }
          },
          hAxis: {
            textStyle: {
              color: foreground
            },
            gridlineColor: foreground
          },
          vAxis: {
            textStyle: {
              color: foreground
            },
            gridlineColor: foreground
          },
          isStacked: true,
          backgroundColor: background,
          colors: colors,
          lineWidth: 4,
        }}
      />
    </Space>
  )
}

export default ParticipantFunnelReports